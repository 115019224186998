import { Injectable } from '@angular/core';
import { ApplicantService } from '@core/services/auth-user/applicant.service';
import { ApplicantForApi, ApplicantFromSearch } from '@core/typings/applicant.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { AddOrganizationService } from '@features/add-organization/add-organization.service';
import { ApplicationViewService } from '@features/application-view/application-view.service';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { WorkflowService } from '@features/workflow/workflow.service';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { CreateEditApplicationForApi, CreateEditApplicationModalResponse, OfflineCreateModalAction, SubmitApplicationOptions } from './offline-grants.typing';
import { OfflineGrantsResources } from './offline-grants.resources';

@Injectable({ providedIn: 'root' })
export class OfflineGrantsService {

  constructor (
    private offlineGrantsResources: OfflineGrantsResources,
    private logService: LogService,
    private notifier: NotifierService,
    private i18n: I18nService,
    private applicationViewService: ApplicationViewService,
    private applicantService: ApplicantService,
    private addOrgService: AddOrganizationService,
    private formHelperService: FormHelperService,
    private formLogicService: FormLogicService,
    private formService: FormsService,
    private workflowService: WorkflowService
  ) { }

  async getApplicationForEdit (
    id: number,
    isNewApp: boolean
  ) {
    await this.applicationViewService.setApplicationViewMap(
      id,
      true,
      isNewApp
    );
  }

  async handleCreateEditApplication (
    response: CreateEditApplicationModalResponse,
    applicationId: number,
    isNomination: boolean,
    skipToastr = false
  ) {
    const isCreate = !applicationId;
    const modalAction = response.modalAction;
    const isNew = !applicationId;
    try {
      const [
        orgResponse,
        applicantId
      ] = await Promise.all([
        this.getOrganizationId(response, applicationId),
        this.getApplicantId(response.selectedApplicant, response.applicantCanReceiveEmails),
        isNew ? this.formService.prepareForms() : null
      ]);
      const payload: CreateEditApplicationForApi = {
        applicationId,
        grantProgramId: response.grantProgramId,
        grantProgramCycleId: response.cycleId,
        applicantId,
        organizationId: orgResponse.organizationId,
        applicantCanReceiveEmails: response.applicantCanReceiveEmails,
        sendEmailToApplicant: isNew &&
          modalAction === OfflineCreateModalAction.SEND_TO_APPLICANT
      };
      if (isNew) {
        // For new applications, amount requested starts out empty.
        // We then check if the amount requested component on the default form has a default value stored
        // If so, we create the application with that default value
        const defaultFormId = response.program.defaultFormId;
        const latestRevisionId = this.formService.getLatestRevisionId(defaultFormId, true);
        await this.formLogicService.getAndSetForm(defaultFormId, latestRevisionId);
        const form = this.formLogicService.getFormDetail(defaultFormId, latestRevisionId);
        payload.amountRequested = this.formHelperService.findDefaultAmountRequestedValOnForm(
          form.formDefinition,
          null
        );
      }
      const id = await this.offlineGrantsResources.createEditApplication(payload);
      if (orgResponse.needToHandleOrgAfterSave) {
        await this.handleOrgForApplication(response, id || applicationId);
      }
      if (!skipToastr) {
        const toastrText = this.getCreateEditAppToastrText(
          true,
          isCreate,
          modalAction,
          isNomination,
          id
        );
        this.notifier.success(toastrText);
      }

      return id;
    } catch (e) {
      this.logService.error(e);
      const toastrText = this.getCreateEditAppToastrText(
        false,
        isCreate,
        modalAction,
        isNomination,
        null
      );
      this.notifier.error(toastrText);

      return null;
    }
  }

  async getApplicantId (
    applicant: ApplicantFromSearch,
    notifyApplicant: boolean
  ): Promise<number> {
    if (applicant.id) {
      return applicant.id;
    }
    const adapted: ApplicantForApi = {
      ...applicant,
      ...applicant.address,
      clientId: null,
      isEmployeeOfClient: applicant.isEmployeeOfClient,
      firstName: applicant.firstName.trim(),
      lastName: applicant.lastName.trim(),
      sendEmail: notifyApplicant,
      languageCulture: applicant.languageCulture
    };

    const response = await this.applicantService.createApplicant(adapted);

    return response.applicantId;
  }

  async getOrganizationId (
    response: CreateEditApplicationModalResponse,
    applicationId: number
  ): Promise<{
    organizationId: number;
    needToHandleOrgAfterSave: boolean;
  }> {
    let orgId = null;
    let needToHandleOrgAfterSave = false;
    if (!!response.selectedOrg) {
      if (!response.selectedOrg.document.id) {
        orgId = await this.addOrgService.createPrivateOrg(response.selectedApplicant.id);
        if (!!orgId) {
          response.selectedOrg.document.id = '' + orgId;
        } else {
          response.selectedOrg.document.id = null;
        }
      } else {
        orgId = +response.selectedOrg.document.id;
      }
      // We can only call AddOrVetOrganization if application id exists,
      // so check if we need to call this function again
      needToHandleOrgAfterSave = !applicationId;
      const organizationId = await this.handleOrgForApplication(
        response,
        applicationId
      );

      return {
        organizationId,
        needToHandleOrgAfterSave
      };
    }

    return {
      organizationId: null,
      needToHandleOrgAfterSave
    };
  }

  handleOrgForApplication (
    response: CreateEditApplicationModalResponse,
    applicationId: number
  ): Promise<number> {
    return this.addOrgService.handleOrgForApplication(
      response.cycleId,
      response.cycle.isClientProcessing ?
        ProcessingTypes.Client :
        ProcessingTypes.YourCause,
      +response.selectedOrg.document.id,
      applicationId,
      response.selectedOrg.document.eligibleForGivingStatusId,
      response.vettingInfo?.latestVettingRequestStatusForOrg,
      response.program.clientId,
      response.vettingInfo?.vettingRequestContactName,
      response.vettingInfo?.vettingRequestContactEmail,
      response.vettingInfo?.vettingRequestContactWebsite
    );
  }

  getCreateEditAppToastrText (
    success: boolean,
    isCreate: boolean,
    modalAction: OfflineCreateModalAction,
    isNomination: boolean,
    id: number
  ) {
    if (success) {
      if (isCreate) {
        if (modalAction === OfflineCreateModalAction.SEND_TO_APPLICANT) {
          return this.i18n.translate(
            isNomination ?
              'common:textNomCreatedAndSent' :
              'common:textAppCreatedAndSent',
            {
              id
            },
            isNomination ?
              'Nomination __id__ created and sent to nominator.' :
              'Application __id__ created and sent to applicant.'
          );
        } else if (modalAction === OfflineCreateModalAction.SAVE_AND_EDIT) {
          return this.i18n.translate(
            isNomination ?
              'common:textNominationSavedToastr' :
              'common:textApplicationSavedToastr',
            {},
            isNomination ?
              'Nomination saved. Make changes to the default form and send to the nominator or submit on their behalf.' :
              'Application saved. Make changes to the default form and send to the applicant or submit on their behalf.'
          );
        } else {
          return this.i18n.translate(
            isNomination ?
              'common:NomSavedAsDraft' :
              'common:textAppSavedAsDraft',
            {
              id
            },
            isNomination ?
              'Nomination __id__ saved as draft.' :
              'Application __id__ saved as draft.'
          );
        }
      } else {
        return this.i18n.translate(
          isNomination ?
            'common:textSuccessfullyUpdatedTheNomination' :
            'common:textSuccessfullyUpdatedTheApplication',
          {},
          isNomination ?
            'Successfully updated the nomination.' :
            'Successfully updated the application.'
        );
      }
    } else {
      if (isCreate) {
        return this.i18n.translate(
          isNomination ?
            'common:textErrorCreatingNomination' :
            'common:textErrorCreatingApplication',
          {},
          isNomination ?
            'There was an error creating the nomination.' :
            'There was an error creating the application.'
        );
      } else {
        return this.i18n.translate(
          isNomination ?
            'common:textErrorUpdatingNomination' :
            'common:textErrorUpdatingApplication',
          {},
          isNomination ?
            'There was an error updating the nomination.' :
            'There was an error updating the application.'
        );
      }
    }
  }

  async sendDraftToApplicant (
    applicationId: number,
    isNomination = false
  ): Promise<boolean> {
    try {
      await this.offlineGrantsResources.sendDraftToApplicant(applicationId);
      this.notifier.success(this.i18n.translate(
        isNomination ?
          'common:textSuccessSendingToNominator' :
          'common:textSuccessSendingToApplicant',
        {},
        isNomination ?
          'Successfully sent to the nominator' :
          'Successfully sent to the applicant'
      ));

      return true;
    } catch (e) {
      this.logService.error(e);
      this.notifier.error(this.i18n.translate(
        isNomination ?
          'common:textErrorSendingToNominator' :
          'common:textErrorSendingToApplicant',
        {},
        isNomination ?
          'There was an error sending to the nominator' :
          'There was an error sending to the applicant'
      ));

      return false;
    }
  }

  getSubmitOptions (isNomination: boolean) {
    return [{
      label: this.i18n.translate(
        'common:textEnterTheDefaultLevel',
        {},
        'Enter the default workflow level'
      ),
      value: SubmitApplicationOptions.ENTER_DEFAULT_LEVEL
    }, {
      label: this.i18n.translate(
        isNomination ?
          'common:textSelectWflForNomToEnter' :
          'common:textSelectWflForAppToEnter',
        {},
        isNomination ?
          'Select a workflow level for the nomination to enter' :
          'Select a workflow level for the application to enter'
      ),
      value: SubmitApplicationOptions.SELECT_A_LEVEL
    }];
  }

  async resolveAppForEdit (id: number, isNewApp: boolean) {
    await this.workflowService.setMyWorkflowManagerRolesMap();
    await this.getApplicationForEdit(+id, isNewApp);
  }
}
