import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { CreateEditApplicationForApi } from './offline-grants.typing';

@Injectable({ providedIn: 'root' })
export class OfflineGrantsResources {

  constructor (
    private httpRestService: HttpRestService
  ) { }

  createEditApplication (
    payload: CreateEditApplicationForApi
  ): Promise<number> {
    const endpoint = 'api/manager/applications';

    return this.httpRestService.post(endpoint, payload);
  }

  sendDraftToApplicant (applicationId: number) {
    const endpoint = `api/manager/applications/${applicationId}/SendApplicantAddedToApplicationEmail`;

    return this.httpRestService.get(endpoint);
  }

}
