import { Address, AddressFromApi, ClassificationInfo, NonprofitSDGAPI, NonprofitSDGWithTargets, OrganizationEligibleForGivingStatus, OrganizationSearchResponse, SearchResult, SearchResultObj, SimpleGrantsAddress } from '@yourcause/common';
import { ApplicantInfo } from './applicant.typing';

export type OrgUnion = AdaptedNppSearchResult|ApplicantOrganizationForUi|OrganizationSearchResponse|BucketSearchResultObj;

export enum Affiliations {
  POLITICALLYAFFILIATED = 1,
  FRATERNAL = 2,
  VETERANS = 3,
  SECTARIAN = 4,
  SERVICE = 5,
  RELIGIOUS = 6,
  NONEOFTHEABOVE = 7
}

export interface CompanyCompliance {
  clientName: string;
  response: string;
  externalKey?: string;
}

export interface ApplicantOrganizationFromApi {
  id: number;
  name: string;
  address: string;
  address2: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  phoneNumber: string;
  charityId: number;
  orgIdentification: string;
  imageUrl: string;
  nonprofitGuid: string;
  clientId: number;
  eligibleForGivingStatus: OrganizationEligibleForGivingStatus;
  registrationAuthorityName: string;
  latestVettingRequestStatusForOrg: VettingRequestStatusId;
}

export interface ApplicantOrganizationForUi extends ApplicantOrganizationFromApi {
  isPrivateOrg: boolean;
  eligibleForGivingStatusId: OrganizationEligibleForGivingStatus;
}

export interface CreateApplicantOrganization {
  name: string;
  website: string;
  address: string;
  address2: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  phoneNumber: string;
  orgIdentification: string;
  applicantId: number;
  imageName?: string;
  imageUrl?: string;
  clientId: number;
  grantProgramCycleId: number;
}

export interface AppliedOrganization {
  id: number;
  name: string;
  address: string;
  address2: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  phoneNumber: string;
  orgIdentification: string;
  isPrivateOrg: boolean;
  applicants: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
  }[];
  totalApplications: number;
  totalPaymentValue: number;
  nonprofitGuid: string;
  vendorId: string;
}

export interface OrganizationForInfoPanel {
  id: number;
  guid: string;
  name: string;
  image: string;
  imageUrl?: string;
  address: SimpleGrantsAddress|Address;
  addressString: string;
  identification: string;
  canUpdate?: boolean;
  nameIsLink?: boolean;
  openNameInNewTab?: boolean;
  text?: string;
  document?: SearchResultObj;
  isPrivateOrg?: boolean;
  eligibleForGivingStatusId: OrganizationEligibleForGivingStatus;
  isInternational: boolean;
  parentGuid: string;
  parentName: string;
  registrationAuthorityName: string;
  phone: string;
}

export interface NonprofitActivityApi {
  clientCharityDonationsByYear: any;
  networkCharityDonationsByYear: any;
}

export interface NonprofitDataApi {
  nonprofitGuid: string;
  classificationInfo: ClassificationInfo;
  nonprofitLogoImageUrl: string;
  subsectionCode: string;
  ycCharityId: number;
  id: number;
  registrationAuthorityId: number;
  registrationId: string;
  name: string;
  address: AddressFromApi;
  nonprofitIconImageUrl: string;
  nonprofitAffiliationId: [];
  createDate: string;
  processesOwnFunds: boolean;
  dueDiligence: DueDiligence;
  disbursementAddress: AddressFromApi;
  isActive: boolean;
  isEdVetted: boolean;
  hasACH: boolean;
}

export interface NonprofitAdminApi {
  lastName: string;
  firstName: string;
  email: string;
  isDeactivated: boolean;
}

export interface YCCompliance {
  federalUSCompliance: boolean;
  nonDiscriminationCompliance: boolean;
  nonReligiousCompliance: boolean;
}

export interface DueDiligence {
  registrationAuthority: string;
  foundationType: string;
  ycCompliance: YCCompliance;
  companyCompliance?: CompanyCompliance[];
  sdg: NonprofitSDGAPI[];
}

export interface NonprofitAdditionalDataApi {
  nonprofitDetail: NonprofitDetail;
  nonprofit: NonprofitDataApi;
}

export interface NonprofitProfileData extends NonprofitAdditionalDataApi {
  csrStats: CsrConnectStats;
  gcId: string;
  summary: NonprofitProfileSummary;
  dueDiligence: DueDiligence;
  nonprofitAdmins: NonprofitAdminApi[];
}

export interface NonprofitProfileSummary {
  numberOfApplications: number;
  numberOfNominations: number;
  totalAwardAmount: number;
  awardsPaidAmount: number;
  pendingPaymentsAmount: number;
  latestVettingRequestStatusForOrg?: VettingRequestStatusId;
}

export interface NonprofitDetail {
  id: number;
  charityId?: number;
  registrationId: string;
  name: string;
  registrationAuthorityId: number;
  address: AddressFromApi;
  isPrivateOrg: boolean;
  isManualAdd?: boolean;
  isChapterAffiliate?: boolean;
  isPublished?: boolean;
  allowDonations: boolean;
  allowVolunteering: boolean;
  classificationInfo?: ClassificationInfo;
  foundationType?: string;
  displayName: string;
  displayPitch: string;
  displayNumber: string;
  displayEmail: string;
  displayAddress: AddressFromApi;
  irsAddress?: AddressFromApi;
  remittanceInfo?: {
    name: string;
    address: AddressFromApi;
    requestId: number;
    comments: string;
  };
  displayUrl: string;
  tags: string;
  nonprofitIconImageUrl: string;
  nonprofitLogoImageUrl?: string;
  nonprofitHeaderImageUrl?: string;
  isClaimed?: boolean;
  supportsSDGGive: boolean;
  supportsSDGVolunteer: boolean;
  sdGs: NonprofitSDGWithTargets[];
  ofacCheck?: string;
  subsectionCode?: string;
  processesOwnFunds?: boolean;
  nonprofitStoryModel?: NonProfitStory[];
  eligibleForGivingStatusId: OrganizationEligibleForGivingStatus;
  parentNonprofitName: string;
  parentNonprofitGuid: string;
}

export interface CompanyOrgDetail {
  address: string;
  address2: string;
  charityId: number;
  city: string;
  country: string;
  id: number;
  imageUrl: string;
  name: string;
  nonprofitGuid: string;
  orgIdentification: string;
  phoneNumber: string;
  postalCode: string;
  state: string;
  isPrivateOrg: boolean;
}

export interface CsrConnectStats {
  clientCharityDonationsByYear: DonationByYear[];
  networkCharityDonationsByYear: DonationByYear[];
}

export interface DonationByYear {
  donationYear: number;
  totalDonors: number;
  totalProcessedEmployeesDonations: number;
  totalCompanyMatches: number;
  totalVolunteerParticipants: number;
  totalHoursVolunteered: number;
}

export interface OrgForApplicant {
  applicationCount: number;
  nominationsCount: number;
  isPrivateOrg: boolean;
  id: number;
  name: string;
  address:  string;
  address2:  string;
  country:  string;
  state:  string;
  city:  string;
  postalCode:  string;
  phoneNumber:  string;
  charityId: number;
  orgIdentification:  string;
  applicantId: number;
  imageName:  string;
  imageUrl:  string;
  nonprofitGuid: string;
  clientId: number;
}

export interface NonprofitTopLevelStats {
  totalOrganizations: number;
  awardsTotal: number;
  paymentsTotal: number;
  numberOfApplications: number;
  numberOfAwards: number;
  numberOfPayments: number;
}

export interface NonprofitTop10 {
  organizationId: number;
  guid: string;
  name: string;
  requestAmount: number;
  awardsTotal: number;
  numberOfAwards: number;
  awardNumberAndTotal?: string;
}

export interface ClassificationForOrgs {
  classificationId: number;
  awardsTotal: number;
  numberOfAwards: number;
  paymentsTotal: number;
  numberOfPayments: number;
  awardNumberAndTotal?: string;
  paymentsNumberAndTotal?: string;
  name?: string;
}

export interface OrgForDash {
  applicantInfos: ApplicantInfo[];
  organizationInfo: OrganizationInfo;
  numberOfApplications: number;
  amountRequested: number;
  numberOfAwards: number;
  amountAwardedTotal: number;
  numberOfPayments: number;
  paymentsTotal: number;
  lastApplicationDate: string;
}

export interface OrganizationInfo {
  id: number;
  name: string;
  charityId: number;
  phoneNumber: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  identification: string;
  isPrivateOrg: boolean;
  imageUrl: string;
  guid: string;
}

export interface NonProfitStory {
  storyId: number;
  statusTypeId: number;
  imageURL: string;
  storyTitle: string;
  externalLink: string;
  externalLinkText: string;
  storyDescription: string;
  createdDate: string;
  createByUserId: number;
  lastModifiedDate: string;
  lastModifiedByUserId: number;
}

export enum VettingRequestStatusId {
  // SUBMITTED BUT NO APPLICATION
  REQUEST_SUBMITTED = 41,

  // HAS APPLICATION IN ANY 'OUTSTANDING' STATUS
  IN_PROGRESS = 62,

  // HAS APPLICATION IN CORRESPONDING 'COMPLETED' STATUS
  CANCELLED = 61,
  APPROVED_ED = 44,
  APPROVED_STANDARD = 45,
  DECLINED_ED = 46,
  DECLINED_STANDARD = 47,

  // PARTNER VETTING
  ROUTED_TO_VETTING_AUTHORITY = 63,
  ON_HOLD_PENDING_BILLING = 64
}

export enum VettingRejectionReason {
  Cancelled = 33,
  CancelledBecauseNPOWasDeactivated = 34,
  NotAValidOrganization = 35,
  DoesNotQualify = 36,
  NotInterestedInCompletingVettingApplication = 37,
  CancelledBecauseGrantsConnectApplicationWasDeclined = 38,
  Other = 39,
  NoResponseFromNonprofit = 80,
  InvalidNonprofitContactDetailsProvided = 81,
  VettingRequestNoLongerRelevantDueToEligibilityChange = 82,
  ClientDeclinedToPayForVetting = 83,
  CancelledOptOutOfVetting = 96,
  CancelledBecauseVettingAppWasInDraftorHoldTooLong = 98,
  EmailToNonprofitContactHardBounced = 100,
  NonprofitContactOptedOut = 101
}


export interface SearchResultNormalized {
  name: string;
  id: number;
  parentName: string;
  parentId: number;
  parentGuid: string;
  address: string;
  registrationId: string;
  iconURL: string;
  classification: string;
  eligibleForGivingStatusId: OrganizationEligibleForGivingStatus;
  isPrivateOrg: boolean;
  isBucketOrg: boolean;
}

export type AdaptedNppSearchResult = SearchResult&SearchResultNormalized;


export interface BucketSearchFilter {
  state: string;
  country: string;
}

export interface BucketSearchAPIResponse {
  totalCount: number;
  pageCount: number;
  page: number;
  pageSize: number;
  message: string;
  data: BucketSearchResultObj[];
}
export interface BucketSearchResultObj {
  id: number;
  name: string;
  imageUrl: string;
  location: {
    address: string; // Full address string
    city: string;
    state: string;
    zip: string;
    country: string;
    countryCode: string;
  };
  misstionStatement: string;
  contactPhone: string;
  siteUrl: string;
  registrationNumber: string;
  uniqueGuid: string;
}

export type SearchResultType = (OrganizationSearchResponse|BucketSearchResultObj|SearchResult|ApplicantOrganizationForUi)&SearchResultNormalized;

export interface RegistrationAuthorityFromApi {
  id: number;
  name: string;
  npoRegistrationAuthorityId: number;
  requiresGlobalAgreement: boolean;
  restrictedToPilot: boolean;
}
