import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AdminClientSelectorComponent } from '@core/components/admin-client-selector/admin-client-selector.component';
import { PlatformWrapperComponent } from '@core/components/platform-wrapper/platform-wrapper.component';
import { SSOLogoutComponent } from '@core/components/sso-logout/sso-logout.component';
import { UnitsToDollarsPipe } from '@core/pipes/units-to-dollars.pipe';
import { AttachExternalEmailModalComponent } from '@features/attach-external-email/attach-external-email-modal/attach-external-email-modal.component';
import { GetColumnClassPipe } from '@features/forms/form-renderer-components/layout-form-components/pipes/get-column-class.pipe';
import { ImpersonationLandingPageComponent } from '@features/impersonation/impersonation-landing-page/impersonation-landing-page.component';
import { TerminatedImpersonationPageComponent } from '@features/impersonation/terminated-impersonation-page/terminated-impersonation-page.component';
import { InKindItemsPopoverComponent } from '@features/in-kind/in-kind-items-popover/in-kind-items-popover.component';
import { PageHelpModalComponent } from '@features/page-help-modals/page-help-modal/page-help-modal.component';
import { ContactSupportModalComponent } from '@features/support/contact-support-modal/contact-support-modal.component';
import { YCCommonModule } from '@yourcause/common';
import { AlertModule } from '@yourcause/common/alert';
import { AnalyticsModule } from '@yourcause/common/analytics';
import { BannerModule } from '@yourcause/common/banner';
import { BlockUiModule } from '@yourcause/common/block-ui';
import { ButtonModule } from '@yourcause/common/buttons';
import { CheckboxModule } from '@yourcause/common/checkbox';
import { CoreFormsModule } from '@yourcause/common/core-forms';
import { CurrencyModule } from '@yourcause/common/currency';
import { DateModule } from '@yourcause/common/date';
import { DomModule } from '@yourcause/common/dom';
import { DownloadOptionsModule } from '@yourcause/common/download-options';
import { FeatureFlagEnabledPipe, FeatureManagementModule } from '@yourcause/common/feature-flag';
import { FilesModule } from '@yourcause/common/files';
import { FormControlValidationModule } from '@yourcause/common/form-control-validation';
import { HTTP_REST_SERVICE } from '@yourcause/common/http';
import { I18nModule } from '@yourcause/common/i18n';
import { IconsModule } from '@yourcause/common/icons';
import { InputModule } from '@yourcause/common/input';
import { LINK_SERVICE } from '@yourcause/common/links';
import { ModalsModule } from '@yourcause/common/modals';
import { PanelModule } from '@yourcause/common/panel';
import { RadioButtonsModule } from '@yourcause/common/radio-buttons';
import { RICH_TEXT_EDITOR_SERVICE, RichTextEditorModule } from '@yourcause/common/rich-text-editor';
import { SelectModule } from '@yourcause/common/select';
import { SPINNER_SERVICE } from '@yourcause/common/spinner';
import { TextAreaModule } from '@yourcause/common/textarea';
import { TypeaheadSelectModule } from '@yourcause/common/typeahead-select';
import { UtilsModule } from '@yourcause/common/utils';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AddressBlockComponent } from './components/address-block/address-block.component';
import { AddressRequestStatusDisplayComponent } from './components/address-request-status-display/address-request-status-display.component';
import { AmountCurrencyDisplayComponent } from './components/amount-currency-display/amount-currency-display.component';
import { AmountToggleComponent } from './components/amount-toggle/amount-toggle.component';
import { AppActionsDropdownComponent } from './components/app-actions-dropdown/app-actions-dropdown.component';
import { ApplicantBlockComponent } from './components/applicant-block/applicant-block.component';
import { ApplicantOrgDetailsBlockComponent } from './components/applicant-org-details-block/applicant-org-details-block.component';
import { ApplicantSearchResultComponent } from './components/applicant-search-result/applicant-search-result.component';
import { ApplicantWrapperComponent } from './components/applicant-wrapper/applicant-wrapper.component';
import { BrandingComponent } from './components/branding/branding.component';
import { CollaborationModalComponent } from './components/collaboration-modal/collaboration-modal.component';
import { ComponentLimitMetModalComponent } from './components/component-limit-met-modal/component-limit-met-modal.component';
import { CopyLinkModalComponent } from './components/copy-link-modal/copy-link-modal.component';
import { CopyLinkComponent } from './components/copy-link/copy-link.component';
import { CsrConnectBreakdownComponent } from './components/csr-connect-breakdown/csr-connect-breakdown.component';
import { CsrConnectChartsComponent } from './components/csr-connect-charts/csr-connect-charts.component';
import { CsrConnectPanelComponent } from './components/csr-connect-panel/csr-connect-panel.component';
import { DownloadFilePageComponent } from './components/download-file-page/download-file-page.component';
import { DueDiligenceModalComponent } from './components/due-diligence-modal/due-diligence-modal.component';
import { EmailDisabledAlertComponent } from './components/email-disabled-alert/email-disabled-alert.component';
import { GcCopyrightBlockComponent } from './components/gc-copyright-block/gc-copyright-block.component';
import { GrantDeadlineBlockComponent } from './components/grant-deadline-block/grant-deadline-block.component';
import { InAppAlertsModalComponent } from './components/in-app-alerts-modal/in-app-alerts-modal.component';
import { ManageCollaboratorsComponent } from './components/manage-collaborators/manage-collaborators.component';
import { ManagementWrapperComponent } from './components/management-wrapper/management-wrapper.component';
import { NominationFormHeaderComponent } from './components/nomination-form-header/nomination-form-header.component';
import { ReleaseNotesModalComponent } from './components/release-notes-modal/release-notes-modal.component';
import { SearchApplicantAutocompleteComponent } from './components/search-applicant-autocomplete/search-applicant-autocomplete.component';
import { SequenceModalComponent } from './components/sequence-modal/sequence-modal.component';
import { SpecialHandlingDisplayLineComponent } from './components/special-handling-display-line/special-handling-display-line.component';
import { SpecialHandlingModalComponent } from './components/special-handling-modal/special-handling-modal.component';
import { SSORedirectComponent } from './components/sso-redirect/sso-redirect.component';
import { TermsOfServiceModalComponent } from './components/terms-of-service/terms-of-service-modal.component';
import { TranslatePageComponent } from './components/translations/translate-page/translate-page.component';
import { TranslateRichTextModalComponent } from './components/translations/translate-rich-text-modal/translate-rich-text-modal.component';
import { UnsavedChangesModalComponent } from './components/unsaved-changes-modal/unsaved-changes-modal.component';
import { VettingRequirementsModalComponent } from './components/vetting-requirements-modal/vetting-requirements-modal.component';
import { AddStaticResourceSASPipe } from './pipes/add-static-asset-sas.pipe';
import { ApplicationViewFormStatusPipe } from './pipes/application-view-form-status.pipe';
import { CanRemoveInKindItemPipe } from './pipes/can-remove-in-kind-item.pipe';
import { CheckboxNumberValuePipe } from './pipes/checkbox-number-value.pipe';
import { DataDecommissioningDeletionDayDateFormatterPipe } from './pipes/data-decommissioning-deletion-day-date-formatter.pipe';
import { DataSetCollectionDataPipe } from './pipes/data-set-collection-data.pipe';
import { DataSetCollectionLabelPipe } from './pipes/data-set-collection-label.pipe';
import { EmailMessageFromStringPipe } from './pipes/email-messages-from-string.pipe';
import { EmailNameFromTemplateIdPipe } from './pipes/email-name.pipe';
import { FormAvailabilityPipe } from './pipes/form-availability.pipe';
import { FormDueDatePipe } from './pipes/form-due-date.pipe';
import { FormStatusDatePipe } from './pipes/form-status-date.pipe';
import { FormTypeNamePipe } from './pipes/form-type-name.pipe';
import { FormsPassRevisionRequestCriteriaPipe } from './pipes/forms-pass-revision-request-criteria.pipe';
import { GetInvalidColumnHeaders } from './pipes/get-invalid-column-headers.pipe';
import { GetOrgIconPipe } from './pipes/get-org-icon.pipe';
import { GrantsAddressPipe } from './pipes/grants-address.pipe';
import { InKindNumberOfUnitsPipe } from './pipes/in-kind-number-of-units.pipe';
import { InvalidColumnHeadersAlertMessage } from './pipes/invalid-column-headers-alert-message.pipe';
import { IsRegAuthInvalidForProcessingPipe } from './pipes/is-reg-auth-invalid-for-processing.pipe';
import { GcMoneyPipe } from './pipes/money.pipe';
import { ScheduledFormsHelpTextPipe } from './pipes/schleduled-forms-help-text.pipe';
import { SearchNppAddressPipe } from './pipes/search-npp-address.pipe';
import { ShowFormUpdateStatsPipe } from './pipes/show-form-update-stats.pipe';
import { ShowVettingAlertPipe } from './pipes/show-vetting-alert.pipe';
import { TimeInTimeZonePipe } from './pipes/time-in-timezone.pipe';
import { VettingRequestAlertTextPipe } from './pipes/vetting-request-alert-text.pipe';
import { CacheBusterInterceptor } from './services/cache-buster.service';
import { HttpRestService } from './services/http-rest.service';
import { LinkService } from './services/link.service';
import { ModalTrackerService } from './services/modal-tracker.service';
import { RichTextEditorService } from './services/rich-text-editor.service';
import { SlotRoutingInterceptor } from './services/slot-routing.service';
import { SpinnerService } from './services/spinner.service';
import { TokenInterceptor } from './services/token/token-interceptor.service';

@NgModule({
  imports: [
    CommonModule,
    YCCommonModule,
    UtilsModule,
    CoreFormsModule,
    I18nModule,
    ButtonModule,
    BsDropdownModule,
    TextAreaModule,
    RichTextEditorModule,
    RadioButtonsModule,
    CurrencyModule,
    BlockUiModule,
    InputModule,
    FilesModule,
    SelectModule,
    CheckboxModule,
    PanelModule,
    BannerModule,
    AlertModule,
    IconsModule,
    DateModule,
    PopoverModule,
    DragDropModule,
    HttpClientModule,
    RouterModule,
    TooltipModule,
    ReactiveFormsModule,
    FormsModule,
    AnalyticsModule,
    FeatureManagementModule,
    ModalsModule,
    DomModule,
    TypeaheadSelectModule,
    FormControlValidationModule,
    DownloadOptionsModule
  ],
  providers: [
    {
      provide: LINK_SERVICE,
      useClass: LinkService
    },
    {
      provide: HTTP_REST_SERVICE,
      useClass: HttpRestService
    },
    {
      provide: SPINNER_SERVICE,
      useClass: SpinnerService
    }, {
      provide: RICH_TEXT_EDITOR_SERVICE,
      useClass: RichTextEditorService
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheBusterInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SlotRoutingInterceptor,
      multi: true
    }
  ],
  declarations: [
    AddStaticResourceSASPipe,
    ApplicationViewFormStatusPipe,
    VettingRequestAlertTextPipe,
    ShowVettingAlertPipe,
    FormTypeNamePipe,
    FormStatusDatePipe,
    DownloadFilePageComponent,
    FormAvailabilityPipe,
    EmailNameFromTemplateIdPipe,
    DataSetCollectionLabelPipe,
    DataSetCollectionDataPipe,
    AdminClientSelectorComponent,
    PlatformWrapperComponent,
    FormsPassRevisionRequestCriteriaPipe,
    GrantDeadlineBlockComponent,
    ScheduledFormsHelpTextPipe,
    ShowFormUpdateStatsPipe,
    SSOLogoutComponent,
    SSORedirectComponent,
    ManageCollaboratorsComponent,
    AmountToggleComponent,
    CollaborationModalComponent,
    TranslatePageComponent,
    TranslateRichTextModalComponent,
    CsrConnectPanelComponent,
    CsrConnectChartsComponent,
    CsrConnectBreakdownComponent,
    BrandingComponent,
    ManagementWrapperComponent,
    ApplicantWrapperComponent,
    AddressBlockComponent,
    AmountCurrencyDisplayComponent,
    CanRemoveInKindItemPipe,
    EmailDisabledAlertComponent,
    SearchApplicantAutocompleteComponent,
    GetOrgIconPipe,
    CheckboxNumberValuePipe,
    AppActionsDropdownComponent,
    GcMoneyPipe,
    UnitsToDollarsPipe,
    IsRegAuthInvalidForProcessingPipe,
    TimeInTimeZonePipe,
    ApplicantBlockComponent,
    ApplicantSearchResultComponent,
    TermsOfServiceModalComponent,
    VettingRequirementsModalComponent,
    DueDiligenceModalComponent,
    InKindNumberOfUnitsPipe,
    InKindItemsPopoverComponent,
    ImpersonationLandingPageComponent,
    TerminatedImpersonationPageComponent,
    FormDueDatePipe,
    DataDecommissioningDeletionDayDateFormatterPipe,
    SpecialHandlingModalComponent,
    SpecialHandlingDisplayLineComponent,
    AddressRequestStatusDisplayComponent,
    ApplicantOrgDetailsBlockComponent,
    EmailMessageFromStringPipe,
    GrantsAddressPipe,
    NominationFormHeaderComponent,
    ComponentLimitMetModalComponent,
    SearchNppAddressPipe,
    UnsavedChangesModalComponent,
    GetInvalidColumnHeaders,
    InvalidColumnHeadersAlertMessage,
    AttachExternalEmailModalComponent,
    CopyLinkModalComponent,
    CopyLinkComponent,
    SequenceModalComponent,
    ContactSupportModalComponent,
    ReleaseNotesModalComponent,
    InAppAlertsModalComponent,
    PageHelpModalComponent,
    GetColumnClassPipe,
    GcCopyrightBlockComponent
  ],
  exports: [
    I18nModule,
    ButtonModule,
    RadioButtonsModule,
    TextAreaModule,
    RichTextEditorModule,
    CurrencyModule,
    PanelModule,
    BsDropdownModule,
    InputModule,
    FilesModule,
    SelectModule,
    CheckboxModule,
    BlockUiModule,
    BannerModule,
    AlertModule,
    IconsModule,
    DateModule,
    CoreFormsModule,
    AddStaticResourceSASPipe,
    DragDropModule,
    YCCommonModule,
    CommonModule,
    RouterModule,
    AnalyticsModule,
    FormsModule,
    PopoverModule,
    TooltipModule,
    ReactiveFormsModule,
    UtilsModule,
    ApplicationViewFormStatusPipe,
    VettingRequestAlertTextPipe,
    ShowVettingAlertPipe,
    FormStatusDatePipe,
    FormTypeNamePipe,
    CanRemoveInKindItemPipe,
    FormAvailabilityPipe,
    EmailNameFromTemplateIdPipe,
    DataSetCollectionLabelPipe,
    DataSetCollectionDataPipe,
    ScheduledFormsHelpTextPipe,
    ShowFormUpdateStatsPipe,
    UnitsToDollarsPipe,
    IsRegAuthInvalidForProcessingPipe,
    TimeInTimeZonePipe,
    GetOrgIconPipe,
    GcMoneyPipe,
    InKindNumberOfUnitsPipe,
    FormDueDatePipe,
    DataDecommissioningDeletionDayDateFormatterPipe,
    GrantsAddressPipe,
    SearchNppAddressPipe,
    FormsPassRevisionRequestCriteriaPipe,
    DownloadFilePageComponent,
    AdminClientSelectorComponent,
    CheckboxNumberValuePipe,
    PlatformWrapperComponent,
    SSORedirectComponent,
    SSOLogoutComponent,
    AppActionsDropdownComponent,
    ManageCollaboratorsComponent,
    AmountToggleComponent,
    CsrConnectPanelComponent,
    CsrConnectChartsComponent,
    CsrConnectBreakdownComponent,
    EmailDisabledAlertComponent,
    GrantDeadlineBlockComponent,
    AmountCurrencyDisplayComponent,
    AddressBlockComponent,
    SearchApplicantAutocompleteComponent,
    ApplicantBlockComponent,
    ApplicantSearchResultComponent,
    TranslatePageComponent,
    TranslateRichTextModalComponent,
    TermsOfServiceModalComponent,
    DueDiligenceModalComponent,
    InKindItemsPopoverComponent,
    ImpersonationLandingPageComponent,
    TerminatedImpersonationPageComponent,
    SpecialHandlingModalComponent,
    SpecialHandlingDisplayLineComponent,
    AddressRequestStatusDisplayComponent,
    EmailMessageFromStringPipe,
    ApplicantOrgDetailsBlockComponent,
    NominationFormHeaderComponent,
    ComponentLimitMetModalComponent,
    UnsavedChangesModalComponent,
    GetInvalidColumnHeaders,
    InvalidColumnHeadersAlertMessage,
    AttachExternalEmailModalComponent,
    FeatureFlagEnabledPipe,
    CopyLinkModalComponent,
    CopyLinkComponent,
    ModalsModule,
    DomModule,
    TypeaheadSelectModule,
    DownloadOptionsModule,
    FormControlValidationModule,
    SequenceModalComponent,
    ContactSupportModalComponent,
    ReleaseNotesModalComponent,
    InAppAlertsModalComponent,
    PageHelpModalComponent,
    GetColumnClassPipe,
    GcCopyrightBlockComponent
  ]
})
export class GCCoreModule {
  constructor (
    modalTracker: ModalTrackerService
  ) {
    modalTracker.startTracking();
  }
}
