import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, Validators } from '@angular/forms';
import { SpinnerService } from '@core/services/spinner.service';
import { SimpleUser, UserAccessTypes } from '@core/typings/client-user.typing';
import { ClientSettingsService } from '@features/client-settings/client-settings.service';
import { UserService } from '@features/users/user.service';
import { SimpleStringMap, TopLevelFilter } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { ComponentDisplayPageModalComponent, ModalFactory } from '@yourcause/common/modals';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { isEqual, uniq } from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { AudienceService } from '../audience.service';
import { Audience, AudienceDetail, AudienceMember, AudiencePermissionAttrs, AudienceWithPermission } from '../audience.typing';
import { AudiencesPageComponent } from '../audiences-page/audiences-page.component';
import { TypeSafeFormBuilder, TypeSafeFormGroup, TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { EmailExtensionValidator } from '@yourcause/common/form-control-validation';
interface AudienceFormGroup {
  userSelectEmail: string;
}
interface ExternalUsersGroup {
  externalName: string;
  externalEmail: string;
}

@Component({
  selector: 'gc-manage-audience-table',
  templateUrl: './manage-audience-table.component.html',
  styleUrls: ['./manage-audience-table.component.scss']
})
export class ManageAudienceTableComponent<K extends keyof AudiencePermissionAttrs> implements OnInit, OnChanges, OnDestroy {
  @Input() isEditMode = true;
  @Input() selectedUsers: AudienceMember[] = [];
  @Input() tableKey: string;
  @Input() allowExternal = true;
  @Input() audiences: AudienceWithPermission[] = [];
  @Input() supportsAudienceSelector = false;
  @Input() supportsAudiencePermissionSelector = false;
  @Input() supportsMultipleAudiences = false;
  @Input() audienceHelp = '';
  @Input() audienceSupportsExternalMembers = true;
  @Input() includePermissionSelector = false;
  @Input() userIdAttribute: 'id'|'userId' = 'userId';
  @Input() userSelectLabel = this.i18n.translate(
    'CONFIG:textAddAdditionalAudienceMembers',
    {},
    'Add additional audience members'
  );
  @Input() permissionColumnHeaderKey = 'common:hdrReportPermission';
  @Input() permissionProp: keyof AudiencePermissionAttrs = 'canManage';
  @Input() permissionDefaultValue: AudiencePermissionAttrs[K] = false;
  @Input() permissionOptions: TypeaheadSelectOption[] = [{
    label: this.i18n.translate(
      'APPLY:textManage',
      {},
      'Manage'
    ),
    value: true
  }, {
    label: this.i18n.translate(
      'GLOBAL:textViewOnly',
      {},
      'View only'
    ),
    value: false
  }];
  @Input() filterOutUserId: number; // owner of report to filter out
  @Input() filterOutReviewerOnlyUsers = false;
  @Input() userManageMap: SimpleStringMap<boolean>;
  @Input() externalAddAllLogic = false;
  @Output() onSelectedUsersChange = new EventEmitter<AudienceMember[]>();
  @Output() onAddingExternalUserChange = new EventEmitter<boolean>();
  @Output() onAddAll = new EventEmitter();
  @Output() onAudiencesChange = new EventEmitter<AudienceWithPermission[]>();
  @Output() onModalToggle = new EventEmitter<boolean>();

  audienceMap: Record<number, AudienceWithPermission> = {};
  topLevelFilters: TopLevelFilter[] = [];
  formGroup: TypeSafeFormGroup<AudienceFormGroup>;
  externalUserFormGroup: TypeSafeFormGroup<ExternalUsersGroup>;
  selectedAudienceMap: Record<number, AudienceDetail> = {};
  audienceIds: number[];
  allAudiences: Audience[];
  hasEmptyAudienceSelect = false;
  audienceOptions: TypeaheadSelectOption[] = []; // Used for standard audience flow
  audienceOptionsMap: Record<number, TypeaheadSelectOption[]> = {}; // Used for audience flow with stored permissions
  userOptions: TypeaheadSelectOption<string>[] = [];
  allUsersSelected = false;
  companyName = this.clientSettingsService.clientBranding.name;
  addingExternalUser = false;
  allUsers: SimpleUser[];
  allUsersPopover: PopoverDirective;
  userSelectHelp = this.i18n.translate(
    'common:textUsersNotShownIfTheyveBeenAddedAudience',
    {},
    `Users aren't shown if they've been added as an audience member`
  );
  sub = new Subscription();

  constructor (
    private formBuilder: TypeSafeFormBuilder,
    private i18n: I18nService,
    private clientSettingsService: ClientSettingsService,
    private userService: UserService,
    private arrayHelper: ArrayHelpersService,
    private audienceService: AudienceService,
    private spinnerService: SpinnerService,
    private modalFactory: ModalFactory
  ) {
    this.sub.add(this.audienceService.changesTo$('audienceDetailMap').subscribe(() => {
      // If this map changes through the Manage Audience action,
      // Make sure we update our selectedAudiencesMap
      Object.keys(this.selectedAudienceMap).forEach((audienceId) => {
        const existingAudience = this.selectedAudienceMap[+audienceId];
        const updatedAudience = this.audienceService.audienceDetailMap[+audienceId];
        if (!!updatedAudience) {
          if (!isEqual(existingAudience, updatedAudience)) {
            this.selectedAudienceMap[+audienceId] = updatedAudience;
          }
        }
      });
    }));
  }

  async ngOnInit () {
    this.spinnerService.startSpinner();
    this.topLevelFilters = this.audienceService.getTopLevelFiltersForUserAudienceTable(
      this.allowExternal,
      this.permissionProp,
      this.permissionOptions
    );
    let fetchAudiences = this.supportsAudienceSelector;
    let fetchUsers = this.isEditMode;
    await Promise.all([
      fetchAudiences ? this.audienceService.setAllAudiences() : undefined,
      fetchUsers ? this.userService.setAllUsers() : undefined
    ]);
    await this.handleAudienceSetup();
    if (this.isEditMode) {
      this.allUsers = this.userService.allUsers.filter((user) => {
        const passesInitial = !user.isDeactivated &&
          (this.filterOutUserId ?
            user.id !== this.filterOutUserId :
            true);
        const passesReviewerOnly = !this.filterOutReviewerOnlyUsers ||
          user.accessType === UserAccessTypes.Default;

        return passesInitial && passesReviewerOnly;
      });
      this.setUserOptions();
      this.formGroup = this.formBuilder.group<AudienceFormGroup>({
        userSelectEmail: null
      });
      this.setSelectedUsers(this.selectedUsers);
    }
    this.spinnerService.stopSpinner();
  }

  ngOnChanges () {
    if (this.formGroup) {
      this.setUserOptions();
    }
  }

  setUserOptions () {
    this.userOptions = this.arrayHelper.sort(
      this.allUsers.filter((user) => {
        const existingUsers = this.selectedUsers.map((u) => u.email);

        return !existingUsers.includes(user.email);
      }).map((user) => {
        return {
          label: user.fullName,
          value: user.email,
          option: `<div class="d-flex justify-content-between">
            <div>${user.fullName}</div>
            <div>${user.email}</div>
          </div>`
        };
      }),
      'label'
    );
    this.allUsersSelected = this.userOptions.length === 0;
  }

  setSelectedUsers (selectedUsers: AudienceMember[]) {
    let selectedUsersMap: Record<string, AudienceMember[]> = {};
    // Adapt the users to get audience info
    // And then filter out the duplicates
    selectedUsers.forEach((user) => {
      let audienceNames: string[] = [];
      let audienceIds: number[] = [];
      if (this.supportsAudienceSelector) {
        const res = this.getAudienceDetailsForUser(user);
        audienceNames = res.audienceNames;
        audienceIds = res.audienceIds;
      }
      const isAudienceMember = audienceIds.length > 0;
      const adaptedUser: AudienceMember = {
        ...user,
        audienceNames: audienceNames.join(', '),
        audienceIds,
        isAudienceMember
      };
      const existing = selectedUsersMap[user.email];
      if (!!existing) {
        selectedUsersMap[user.email] = [
          ...existing,
          adaptedUser
        ];
      } else {
        selectedUsersMap[user.email] = [adaptedUser];
      }
    });
    this.selectedUsers = this.arrayHelper.sort(Object.keys(selectedUsersMap).map((email) => {
      const users = selectedUsersMap[email];
      if (users.length > 1) {
        const audienceUsers = users.filter((user) => user.isAudienceMember);
        if (audienceUsers.length > 0) {
          const baseAudienceUser = audienceUsers[0];
          const audiencePermissions = uniq(this.audiences.filter((audience) => {
            return baseAudienceUser.audienceIds.includes(audience.audienceId);
          }).map((audience) => audience[this.permissionProp]));

          return {
            ...baseAudienceUser,
            // Could be mix of true/false. If so, assign more permissive (true)
            [this.permissionProp]: audiencePermissions.length > 1 ? true : audiencePermissions[0]
          };
        }
      }

      return users[0];
    }), 'name');
    this.onSelectedUsersChange.emit(this.selectedUsers);
  }

  getAudienceDetailsForUser (user: AudienceMember) {
    const audienceIds = this.audiences.map((audience) => {
      return audience.audienceId;
    }).filter((audienceId) => !!audienceId);
    let audienceNames: string[] = [];
    let userAudienceIds: number[] = [];
    audienceIds.forEach((audienceId) => {
      const detail = this.audienceService.audienceDetailMap[audienceId];
      const includesUser = detail.members.some((member) => {
        return member.email === user.email;
      });
      if (includesUser) {
        audienceNames.push(detail.name);
        userAudienceIds.push(audienceId);
      }
    });

    return {
      audienceNames,
      audienceIds: userAudienceIds
    };
  }

  findUser (email: string) {    
    return this.userService.allUsersMap[email];
  }

  onUserChange (email: string) {
    const found = this.findUser(email);
    if (!!found) {
      const newUser: AudienceMember = {
        id: found[this.userIdAttribute],
        email: found.email,
        external: false,
        name: found.fullName
      };
      newUser[this.permissionProp] = this.permissionDefaultValue;
      if (!this.addingExternalUser) {
        this.setSelectedUsers([
          newUser,
          ...this.selectedUsers
        ]);
      } else {
        // Place the selected user below the external user being added
        this.setSelectedUsers([
          ...this.selectedUsers.slice(0, 1),
          newUser,
          ...this.selectedUsers.slice(1)
        ]);
      }
      setTimeout(() => {
        const control = this.formGroup.get('userSelectEmail');
        control.setValue(null);
      });
      this.setUserOptions();
    }
  }

  async manageAudiences () {
    this.onModalToggle.emit(true);
    await this.modalFactory.open(
      ComponentDisplayPageModalComponent,
      {
        modalHeader: this.i18n.translate('CONFIG:hdrManageAudiences', {}, 'Manage Audiences'),
        ComponentClass: AudiencesPageComponent
      },
      { class: 'modal-full-size' }
    );
    this.onModalToggle.emit(false);
    this.setAllAudiences();
    if (!this.supportsAudiencePermissionSelector) {
      this.setAudienceOptions();
    }

    this.handleAudienceChange();
  }

  async handleAudienceSetup () {
    if (this.supportsAudienceSelector) {
      this.setAudiences(this.audiences);
      this.setAllAudiences();
      this.audienceIds = (this.audiences || []).map((audience) => audience.audienceId);
      if (!this.supportsAudiencePermissionSelector) {
        this.setAudienceOptions();
      } else {
        if ((!this.audiences || this.audiences.length === 0)) {
          // In this scenario we need to set a default row for our ngFor
          const audiences: AudienceWithPermission[] = [{
            audienceId: null,
            [this.permissionProp]: this.permissionDefaultValue
          }];
          this.setAudiences(audiences);
          this.hasEmptyAudienceSelect = true;
        }
        this.setAudienceOptionsMapForPermissionsFlow();
      }
      await this.setSelectedAudiences(this.audiences);
    }
  }

  setAllAudiences () {
    this.allAudiences = this.audienceService.allAudiences;
  }

  setAudienceOptions () {
    this.audienceOptions = this.audienceService.allAudiences.map((audience) => {
      return {
        label: audience.name,
        value: audience.id
      };
    });
  }

  setAudienceOptionsMapForPermissionsFlow () {
    this.audienceOptionsMap = this.audiences.reduce((acc, selectedAudience, index) => ({
      ...acc,
      [index]: this.audienceService.allAudiences.filter((audience) => {
        const isSelected = audience.id === selectedAudience.audienceId;
        const selectedOnAnotherRow = this.audiences.some(_audience => {
          return _audience.audienceId === audience.id;
        });

        return isSelected || !selectedOnAnotherRow;
      }).map<TypeaheadSelectOption>((item) => {
        return {
          value: item.id,
          label: item.name
        };
      })
    }), {});
  }

  async setSelectedAudiences (audiences: AudienceWithPermission[]) {
    let selectedAudienceMap: Record<number, AudienceDetail> = {};
    const audienceIds = audiences.map((audience) => audience.audienceId);
    const filteredAudiences = this.audienceService.allAudiences.filter((item) => {
      return audienceIds.includes(item.id);
    });
    this.spinnerService.startSpinner();
    await Promise.all(filteredAudiences.map(async (audience) => {
      await this.audienceService.setAudienceDetail(audience.id);
      selectedAudienceMap[audience.id] = this.audienceService.audienceDetailMap[audience.id];
    }));
    this.spinnerService.stopSpinner();
    this.selectedAudienceMap = selectedAudienceMap;
  }

  setAudiences (audiences: AudienceWithPermission[]) {
    this.audiences = audiences;
    this.audienceMap = this.audiences.reduce((acc, audience) => {
      return {
        ...acc,
        [audience.audienceId]: audience
      };
    }, {} as Record<number, AudienceWithPermission>);
    this.hasEmptyAudienceSelect = this.audiences.some((audience) => !audience.audienceId);
  }

  addAudience () {
    const audiences = [
      ...this.audiences,
      {
        audienceId: null,
        [this.permissionProp]: this.permissionDefaultValue
      }
    ];
    this.setAudiences(audiences);
    this.handleAudienceChange();
  }

  removeAudience (index: number) {
    const audiences = [
      ...this.audiences.slice(0, index),
      ...this.audiences.slice(index + 1)
    ];
    this.setAudiences(audiences);
    this.handleAudienceChange();
  }

  async handleAudienceChangeNoPermissions () {
    const audiences = this.audienceIds.map((audienceId) => {
      return {
        audienceId
      };
    });
    this.setAudiences(audiences);
    this.handleAudienceChange();
  }

  async handleAudienceChangeWithPermissions () {
    const missingPermissions = this.audiences.filter((audience) => {
      return !!audience.audienceId && !audience[this.permissionProp];
    });
    if (missingPermissions) {
      const audiences = this.audiences.map((audience) => {
        return {
          ...audience,
          [this.permissionProp]: audience[this.permissionProp] ?? this.permissionDefaultValue
        };
      });
      this.setAudiences(audiences);
    }
    this.handleAudienceChange();
  }

  async handleAudienceChange () {
    this.onAudiencesChange.emit(this.audiences);
    await this.setSelectedAudiences(this.audiences);
    const nonAudienceMembers = this.selectedUsers.filter((user) => {
      return !user.isAudienceMember;
    });
    const audienceMembers = Object.keys(this.selectedAudienceMap).reduce((acc, audienceId) => {
      return [
        ...acc,
        ...this.selectedAudienceMap[+audienceId].members.filter((user) => {
          if (!this.audienceSupportsExternalMembers && user.external) {
            return false;
          }

          return true
        }).map((user) => {
          return {
            ...user,
            [this.permissionProp]: this.audienceMap[+audienceId][this.permissionProp] ?? user[this.permissionProp]
          };
        })
      ];
    }, [] as AudienceMember[]);
    this.setSelectedUsers([
      ...audienceMembers,
      ...nonAudienceMembers
    ]);
    
    if (this.supportsAudiencePermissionSelector) {
      this.setAudienceOptionsMapForPermissionsFlow();
    }
  }

  saveExternalUser () {
    const formValue = this.externalUserFormGroup.value;
    const selectedUsers = [
      ...this.selectedUsers.slice(0, 0),
      {
        name: formValue.externalName,
        email: formValue.externalEmail,
        external: true,
        id: null
      },
      ...this.selectedUsers.slice(1)
    ];
    this.setSelectedUsers(selectedUsers);
    this.addingExternalUser = false;
    this.onAddingExternalUserChange.emit(this.addingExternalUser);
  }

  addExternalUser () {
    this.externalUserFormGroup = this.formBuilder.group<ExternalUsersGroup>({
      externalName: ['', Validators.required],
      externalEmail: ['', [
        Validators.required,
        EmailExtensionValidator,
        this.duplicateEmailValidator()
      ]]
    });
    this.addingExternalUser = true;
    this.onAddingExternalUserChange.emit(this.addingExternalUser);
    this.setSelectedUsers([
      {
        name: '',
        email: '',
        external: true,
        id: null
      },
      ...this.selectedUsers
    ]);
  }


  removeUser (row: AudienceMember) {
    const index = this.selectedUsers.findIndex((user) => {
      // find by email because external users might not have ids
      return row.email === user.email;
    });
    this.setSelectedUsers([
      ...this.selectedUsers.slice(0, index),
      ...this.selectedUsers.slice(index + 1)
    ]);
    this.setUserOptions();
    if (this.addingExternalUser) {
      this.addingExternalUser = false;
      this.onAddingExternalUserChange.emit(this.addingExternalUser);
    }
  }

  addAllUsers () {
    this.allUsersPopover.hide()
    if (!this.externalAddAllLogic) {
      const externalUsers = this.selectedUsers.filter((user, i) => {
        if (this.addingExternalUser) {
          return (i !== 0) && user.external;
        }

        return user.external;
      });
      const internalUsers = this.allUsers.map((user) => {
        const foundSelected = this.selectedUsers.find((_user) => {
          return _user.id === user[this.userIdAttribute];
        });

        return {
          name: user.fullName,
          email: user.email,
          external: false,
          id: user[this.userIdAttribute],
          [this.permissionProp]: foundSelected ?
            foundSelected[this.permissionProp] :
            this.permissionDefaultValue
        };
      });
      const allUsers = this.arrayHelper.sort([
        ...externalUsers,
        ...internalUsers
      ], 'name');
      let selectedUsers: AudienceMember[] = [];
      if (this.addingExternalUser) {
        selectedUsers = [
          ...this.selectedUsers.slice(0, 0),
          ...allUsers
        ];
      } else {
        selectedUsers = [
          ...allUsers
        ];
      }
      this.setSelectedUsers(selectedUsers);
      this.allUsersSelected = true;
      this.setUserOptions();
    } else {
      this.onAddAll.emit();
    }
  }

  duplicateEmailValidator () {
    return (control: AbstractControl) => {
      const existing = this.selectedUsers.map((user) => user.email);
      const invalid = existing.includes(control.value);
      if (invalid) {
        return {
          duplicateEmail: {
            i18nKey: 'common:textEmailAlreadyExists',
            defaultValue: 'Email already exists'
          }
        };
      }

      return null;
    };
  }

  onPopoverReady (popover: PopoverDirective) {
    this.allUsersPopover = popover;
  }

  ngOnDestroy (): void {
    this.sub.unsubscribe();
  }
}
