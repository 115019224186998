import { FilterModalTypes } from '@yourcause/common';
import { BaseLogicState, BaseLogicStateRecord } from './logic-state.typing';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';

// TODO: upgrade to ts 4.1 (ng 11.1) and /leverage template literal types so that we don't have to manually type out 1, 2, and 3 layers of depth and just have a string e.g. 'prop1.prop2.prop3' versus ['prop1', 'prop2', 'prop3']
export type PropColumn<T, K extends keyof T = keyof T> = [K];
export type NestedPropColumn<T, K1 extends keyof T = keyof T, K2 extends keyof T[K1] = keyof T[K1]> = [K1, K2];
export type NestedNestedPropColumn<T, K1 extends keyof T = keyof T, K2 extends keyof T[K1] = keyof T[K1], K3 extends keyof T[K1][K2] = keyof T[K1][K2]> = [K1, K2, K3];

// we assume that the objects we are evaluating are only ever 3 layers deep
// due to this assumption, we can type our columns as an array of property(K1), subProperty(K2), and subSubProperty(K3)
// with the typing either being [K1] [K1, K2] or [K1, K2, K3]
// ex.
// we have an object:
/*
{
  prop1: {
    prop2: {
      prop3: 'value'
    }
  }
}
*/
// to get to 'value', we would set a column as ['prop1', 'prop2', 'prop3']

export type LogicColumn<T> = PropColumn<T>|NestedPropColumn<T>|NestedNestedPropColumn<T>;

export type LogicColumnValue<T, C extends LogicColumn<T>> = C extends NestedNestedPropColumn<T, infer K1, infer K2, infer K3> ? T[K1][K2][K3] :
    C extends NestedPropColumn<T, infer K1, infer K2> ? T[K1][K2] :
    T extends PropColumn<T, infer K1> ? T[K1] :
    unknown;

type Comparison<T> = T extends boolean ?
  FilterModalTypes.equals|FilterModalTypes.notEqual :
  FilterModalTypes;

export interface BaseLogicCondition<
  T, K extends LogicColumn<T>
> {
  /* tuple(array) for property being evaluated [property(K1), subProperty(K2)?, subSubProperty(K3)?] */
  sourceColumn: K;
  /* require this result to be true for the next result */
  useAnd: boolean;
  comparison: FilterModalTypes;
  identifier: string;
  resultType?: ConditionalLogicResultType;
  relativeDateCalcConfig?: RelativeDateCalculationConfig;
}

export interface BaseValueLogicCondition<
  T, K extends LogicColumn<T>
> extends BaseLogicCondition<T, K> {
  /* standard operator for two value conditions e.g. equals, greater than, etc. */
  comparison: Exclude<Comparison<LogicColumnValue<T, K>>, FilterModalTypes.isBlank|FilterModalTypes.notBlank|FilterModalTypes.contains|FilterModalTypes.multiValueIncludes>;
}

export interface ValueLogicCondition<
  T, K extends LogicColumn<T>
> extends BaseValueLogicCondition<T, K> {
  /* value (must match type of column) */
  value: LogicColumnValue<T, K>;
}


export interface RelatedLogicCondition<
  T, K extends LogicColumn<T>, R extends LogicColumn<T>
> extends BaseValueLogicCondition<T, K> {
  /* tuple(array) for related property being evaluated [property(K1), subProperty(K2)?, subSubProperty(K3)?] (must match type of sourceColumn) */
  relatedColumn: R;
}

export interface RelatedLogicValueCondition<
  T, K extends LogicColumn<T>, R extends LogicColumn<T>
> extends RelatedLogicCondition<T, K, R> {
  value: LogicColumnValue<T, K>;
  relativeDateCalcConfig?: RelativeDateCalculationConfig;
}

export interface OneOfLogicCondition<T, K extends LogicColumn<T>> extends BaseLogicCondition<T, K> {
  comparison: FilterModalTypes.multiValueIncludes;
  /* for 'one of' conditions, must be an array of values */
  value: LogicColumnValue<T, K>[];
}

export interface ContainsLogicCondition<T, K extends LogicColumn<T>> extends BaseLogicCondition<T, K> {
  comparison: FilterModalTypes.contains;
  /* 'contains' only applies to string columns */
  value: LogicColumnValue<T, K> extends string ? string : unknown;
}

export interface NonValueLogicCondition<T, K extends LogicColumn<T>> extends BaseLogicCondition<T, K> {
  /* 'blank' and 'not blank' do not need a value */
  comparison: FilterModalTypes.isBlank|FilterModalTypes.notBlank;
}

export type LogicCondition<T, K extends LogicColumn<T>> =
  ValueLogicCondition<T, K>|
  RelatedLogicCondition<T, K, LogicColumn<T>>|
  RelatedLogicValueCondition<T, K, LogicColumn<T>>|
  OneOfLogicCondition<T, K>|
  NonValueLogicCondition<T, K>|
  ContainsLogicCondition<T, K>;

export type ConditionType<T> = (LogicCondition<T, LogicColumn<T>>|LogicGroup<T>);

export type LogicGroupType<T, V> = LogicGroup<T>|GlobalLogicGroup<T>|GlobalValueLogicGroup<T, V>;

// TODO: support operations e.g. Add A and B
export interface LogicGroup<T> {
  /* array of groups or conditions to be evaluated */
  conditions: ConditionType<T>[];
  /* require this result to be true for the next result */
  useAnd: boolean;
  identifier: string;
}

export enum EvaluationType {
  ConditionallyTrue,
  ConditionallyFalse,
  AlwaysTrue,
  AlwaysFalse
}

export enum ConditionalLogicResultType {
  STATIC_VALUE,
  OTHER_COLUMN,
  VALIDATION_MESSAGE,
  RELATIVE_DATE
}

export interface GlobalLogicGroup<T> extends LogicGroup<T> {
  evaluationType: EvaluationType;
}

export interface GlobalValueLogicGroup<T, V> extends GlobalLogicGroup<T> {
  result: V; // For set value logic, this is the value to set if the conditions pass
  resultType: ConditionalLogicResultType;
  resultConfig?: RelativeDateCalculationConfig;
}

export interface BaseRunResult<T, R> extends BaseLogicStateRecord<R> {
  dependencies: T[];
}

export type LogicRunResult<T, V> = BaseRunResult<LogicColumn<T>, V>;

export interface LogicResult<V> {
  visible: boolean;
  value: V;
}

export interface LogicForColumn<T, V> extends LogicRunResult<T, V> {
  group: GlobalLogicGroup<T>;
  column: LogicColumn<T>;
}

export interface ListLogicForColumn<T, V> extends LogicRunResult<T, V> {
  groups: GlobalValueLogicGroup<T, V>[];
  column: LogicColumn<T>;
}

export type LogicState<T, R> = BaseLogicState<LogicForColumn<T, R>>;
export type ListLogicState<T, R> = BaseLogicState<ListLogicForColumn<T, R>>;


export interface BaseLogicColumnDisplay<T> {
  label: string;
  column: LogicColumn<T>;
  otherColumnOptions: TypeaheadSelectOption<LogicColumn<T>>[];
}

export interface StandardLogicColumnDisplay<T> extends BaseLogicColumnDisplay<T> {
  type: Exclude<LogicFilterTypes, 'multiValueList'|'multi-list'|'multiListFuzzyText'|'list'>;
}


export interface SelectLogicColumnDisplay<T> extends BaseLogicColumnDisplay<T> {
  type: 'multiValueList'|'multi-list'|'multiListFuzzyText'|'list'|LogicFilterTypes;
  filterOptions: TypeaheadSelectOption<any>[];
}

export type LogicColumnDisplay<T> = SelectLogicColumnDisplay<T>|StandardLogicColumnDisplay<T>;

export interface LogicComparisonDisplay {
  label: string;
  comparison: FilterModalTypes;
}

export interface LogicInverseDisplay<T extends EvaluationType> extends TypeaheadSelectOption<T> {
  label: string;
  value: T;
}

export type LogicEvaluationTypeDisplayOptionsConditional = [
  LogicInverseDisplay<EvaluationType.AlwaysTrue>,
  LogicInverseDisplay<EvaluationType.AlwaysFalse>,
  LogicInverseDisplay<EvaluationType.ConditionallyTrue>,
  LogicInverseDisplay<EvaluationType.ConditionallyFalse>
];


export type LogicEvaluationTypeDisplayOptionsValidity = [
  LogicInverseDisplay<EvaluationType.AlwaysTrue>,
  LogicInverseDisplay<EvaluationType.ConditionallyTrue>,
  LogicInverseDisplay<EvaluationType.ConditionallyFalse>
];

export type LogicFilterTypes = 'text'
|'number'
|'multi-list'
|'multiListFuzzyText'
|'multiValueList'
|'multiValueText'
|'boolean'
|'currency'
|'date'
|'list';


export type LogicValueFormatType = 'text'
|'date'
|'number'
|'currency'
|'checkbox'
|'select';

export interface RelativeDateCalculationConfig {
  operator: 'plus'|'minus';
  constant: number;
  constantUnits: 'days'|'weeks'|'years';
}

export enum LogicStateTypes {
  Conditional_Visibility = 1,
  Set_Value = 2,
  Validity = 3,
  Formula = 4
}
