<yc-modal *ngIf="formGroup"
  [formGroup]="formGroup"
  [modalHeader]="modalHeader"
  [primaryButtonText]="'common:btnSave' | ycI18n: 'Save'"
  [primaryDisabled]="formGroup.invalid"
  [cancelButtonText]="'common:btnCancel' | ycI18n: 'Cancel'"
  (onCancel)="onCancel()"
  (onPrimaryClick)="onSubmit()">


  <yc-alert *ngIf="!!alertText"
    alertClass="info"
    [message]="alertText">
  </yc-alert>

  <!-- Name -->
  <div class="row">
    <div class="col-md-6">
      <yc-input
        name="firstName"
        [disabled]="noEditsAllowed"
        [label]="'common:lblFirstName' | ycI18n: 'First name'">
      </yc-input>
    </div>
    <div class="col-md-6">
      <yc-input
        name="lastName"
        [disabled]="noEditsAllowed"
        [label]="'common:lblLastName' | ycI18n: 'Last name'">
      </yc-input>
    </div>
  </div>

  <!-- Job Title / Email -->
  <div class="row">
    <div class="col-md-6">
      <yc-input
        name="jobTitle"
        [label]="'GLOBAL:textJobTitle' | ycI18n: 'Job title'">
      </yc-input>
    </div>

    <div class="col-md-6">
      <yc-input
        name="email"
        [disabled]="noEditsAllowed"
        [label]="'common:textEmail' | ycI18n: 'Email'">
      </yc-input>
    </div>
  </div>

  <!-- Access Type -->
  <div class="row">
    <div *ngIf="allowEditAccessType" 
      class="col-md-6">
      <yc-typeahead-select
        name="accessType"
        [items]="accessTypeOptions"
        [showClear]="false"
        [help]="formGroup.value.accessType === UserAccessTypes.Reviewer ?
          ('common:textReviewerOnlyHelp2' | ycI18n: 'This user will only have access to My Workspace and cannot manage workflows or access payments and reports. They will be removed from any roles they are in.') :
          ''"
        [label]="'common:textAccessType' | ycI18n: 'Access type'"
        (onChange)="accessTypeChanged()">
      </yc-typeahead-select>
    </div>

    <div class="col-md-6">
      <yc-typeahead-select
        name="isSSO"
        [items]="ssoOptions"
        [showClear]="false"
        [label]="'GLOBAL:textSSOUser' | ycI18n: 'SSO user'"
        (onChange)="setHelpers()">
      </yc-typeahead-select>
    </div>
  </div>

  <div *ngIf="allowEditAccessType &&
    existingUser?.accessType === UserAccessTypes.Default &&
    formGroup.value.accessType === UserAccessTypes.Reviewer"
    class="mt-4">
    <gc-report-ownership-transfer
      [userId]="existingUser.userId"
      [showNoReportsAvailableMessage]="false"
      [customDescription]="'common:textReportOwnershipAccessTypeDesc' | ycI18n: 'Below is a list of reports this user owns. Users with reviewer only access cannot manage or view reports. Optionally select another user to take over ownership and manage these going forward.'"
      [dontIncludeDashboards]="true"
      (onReportReassignChange)="onReportReassignChange($event)">
    </gc-report-ownership-transfer>
  </div>

</yc-modal>