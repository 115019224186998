import { APILanguageTranslation } from '@core/typings/api/language-translation.typing';
import { LoginBehaviors } from '@core/typings/login-behaviors.typing';
import { ProcessingTypes } from '@core/typings/payment.typing';
import { ProgramApplicantType, ProgramDeadline, ProgramTypes } from '@core/typings/program.typing';
import { FormDefinitionForUi, FormDefinitionWithLogic, FormType, WorkflowLevelFormApi } from '@features/configure-forms/form.typing';
import { PicklistDataType } from '@features/custom-data-tables/custom-data-tables.typing';
import { DataDecommissionStatus } from '@features/platform-admin/data-decommissioning/data-decommissioning.typing';
import { YcFile } from '@yourcause/common/files';
import { BrandColors } from '../branding.typing';
import { BudgetFundingSource, FundingSourceTypes } from '../budget.typing';
import { AddEditUser } from '../client-user.typing';

export namespace APIAdminClient {
  export interface Client {
    clientId: number;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    logo: string;
    brandPrimary: string;
    brandSecondary: string;
    brandUtility: string;
    defaultLanguage: string;
    defaultTimezone: string;
    defaultCurrency: string;
    affiliateId: number;
    csrClientName: string;
    subDomain: string;
    previousSubDomain: string;
    loginBehavior: LoginBehaviors;
    clientProcessingType: ProcessingTypes;
    overrideEmails: string;
    rootUser: AddEditUser;
    hasImportedFromUAT: boolean;
    adminUserIds?: number[];
    isRootClient: boolean;
    siteId: number;
    activityAlertsSnoozed: boolean;
    snoozedTillDate: string;
    applicantTypesSupported: SupportedApplicantTypes;
    dataDecommissionStatus?: DataDecommissionStatus;
    clientFeatures: ClientFeatureTypes[];
    partnerId: number;
    supportsHistoricalImports: boolean;
  }

  export interface ClientForUi extends Client {
    adaptedName: string;
  }

  export enum SupportedApplicantTypes {
    EmployeesAndNonprofits = 1,
    EmployeeOnly = 2,
    NonprofitOnly = 3
  }

  export interface ClientFeatureInfo {
    description: string;
    name: string;
    title: string;
    disabled?: boolean;
    disabledTooltip?: string;
  }

  export enum ClientFeatureTypes {
    HasNominations = 1,
    AllowApplicantPaymentDesignations = 2,
    AllowApplicantSpecialHandling = 3,
    ApEnabled = 4,
    AllowExpeditedPayments = 5,
    HasInternational = 6,
    AllowBudgetOverages = 7,
    CanConfigureWebservices = 8,
    ReserveFunds = 9,
    AllowExternalApis = 10,
    AllowExternalEmails = 11,
    IsTestClient = 12,
    HideFundingSources = 13,
    ViewRevisionRequestUpdates = 14,
    BypassAttestation = 15,
    ReviewerExperience = 16
  }

  export interface SaveClient {
    clientId: number;
    name: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    overrideEmails: string;
    rootUser: {
      firstName: string;
      lastName: string;
      jobTitle: string;
      email: string;
    };
    subDomain: string;
    loginBehavior: LoginBehaviors;
    clientProcessingType: ProcessingTypes;
    defaultCurrency: string;
    defaultLanguage: string;
    affiliateId: number;
    defaultTimezone: string;
    applicantTypesSupported: SupportedApplicantTypes;
    clientFeatures: ClientFeatureTypes[];
  }

  export interface CsrClientNotInGc {
    id: number;
    name: string;
    corpName: string;
    supportEmail: string;
  }

  export interface ProvisionedClient {
    name: string;
    siteId: number;
    csrAffiliateId: number;
  }

  export interface GrantProgramSummaryResponse {
    grantProgramId: number;
    grantProgramGuid: string;
    grantProgramName: string;
    grantProgramDescription: string;
    startDate: string;
    endDate: string;
    active: boolean;
    isDraft: boolean;
    programApplicantType: ProgramApplicantType;
    allowCollaboration: boolean;
    sendEmailsToCollaborators: boolean;
    notifyNominators: boolean;
    allowAddOrg: boolean;
    programType: ProgramTypes;
    isTiedToNominationPrograms: boolean;
  }

  export interface GrantProgramFormBase {
    formId: number;
    formName: string;
    formDescription: string;
    formRevisionId: number;
    formType: FormType;
    isDefault: boolean;
  }

  export interface GrantProgramForm extends GrantProgramFormBase {
    formDefinition: FormDefinitionForUi[];

  }

  export interface GrantProgramFormFromApi extends GrantProgramFormBase {
    formDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[];
  }

  export interface GrantProgram {
    id: number;
    name: string;
    description: string;
    imageName: string;
    endDate: string;
    startDate: string;
    maxAwardsAllowed: number | null;
    maxValueForSingleAward: number | null;
    defaultFormId: number;
    additionalForms: number[];
    budgets: number[];
    workflowId: number;
    defaultWorkflowLevelId: number;
    deadlines: ProgramDeadline[];
    clientId: number;
    programApplicantType: ProgramApplicantType;
    allowCollaboration: boolean;
    sendEmailsToCollaborators: boolean;
    hasApplications: boolean;
    charityBucketId: string;
    programType: ProgramTypes;
    grantProgramsForNominationProgram: number[];
    eligibilityPassMessages: APILanguageTranslation.LanguageTranslation[];
    eligibilityFailMessages: APILanguageTranslation.LanguageTranslation[];

    workflowLevelForms: WorkflowLevelFormApi[];
    notifyNominators: boolean;
    allowAddOrg: boolean;

    charityBucketDescriptions: APILanguageTranslation.LanguageTranslation[];
  }

  export interface ClientBudget {
    id: number;
    clientId: number;
    name: string;
    description: string;
    active: boolean;
    canExceedAmount: boolean;
    exceededMultiplier: number;
    budgetFundingSources: BudgetFundingSource[];
    overageAmount: number;
    hasClientProcessingType: boolean;
    hasYcProcessingType: boolean;
    isClosed: boolean;
    accountNumber: string;
  }

  export interface ClientFundingSource {
    id: number;
    clientId: number;
    name: string;
    type: FundingSourceTypes;
    totalAmount: number;
    totalUnits: number;
    unitCost: number;
    unitValue: number;
    active: boolean;
    processingTypeId: ProcessingTypes;
    amountRemaining: number;
    unitsRemaining: number;
    isOverAllocationSource: boolean;
    isClosed: boolean;
  }

  export interface BudgetsAndFundingSources {
    budgets: ClientBudget[];
    fundingSources: ClientFundingSource[];
  }

  export interface PicklistWithOptionsModel {
    picklistOptions: PicklistOption[];
    id: number;
    name: string;
    guid: string;
    createdDate: string;
    updatedDate: string;
    createdBy: string;
    updatedBy: string;
    impersonatedBy: string;
    createImpersonatedBy: string;
    isSystem: boolean;
    hasOptions: boolean;
    defaultLanguageId: string;
    parentPicklistId: number;
    dataType: PicklistDataType;
  }

  export interface PicklistOption {
    id: number;
    key: string;
    values: Value[];
    inUse: boolean;
    sortOrder: number;
    createdDate: string;
    updatedDate: string;
    picklistOptionDependentPicklists: PicklistOptionDependentPicklist[];
  }

  interface PicklistOptionDependentPicklist {
    parentPicklistId: number;
    parentPicklistOptionId: number;
    parentPicklistOptionKey: string;
    parentPicklistGuid: string;
    dependentPicklistId: number;
    dependentPicklistOptionId: number;
    dependentPicklistOptionKey: string;
    dependentPicklistGuid: string;
  }

  interface Value {
    languageId: string;
    text: string;
  }

  export interface ClientFeaturesFormGroup {
    [APIAdminClient.ClientFeatureTypes.ReserveFunds]: boolean;
    [APIAdminClient.ClientFeatureTypes.AllowExternalApis]: boolean;
    [APIAdminClient.ClientFeatureTypes.AllowApplicantPaymentDesignations]: boolean;
    [APIAdminClient.ClientFeatureTypes.AllowApplicantSpecialHandling]: boolean;
    [APIAdminClient.ClientFeatureTypes.AllowExpeditedPayments]: boolean;
    [APIAdminClient.ClientFeatureTypes.AllowBudgetOverages]: boolean;
    [APIAdminClient.ClientFeatureTypes.ApEnabled]: boolean;
    [APIAdminClient.ClientFeatureTypes.IsTestClient]: boolean;
    [APIAdminClient.ClientFeatureTypes.CanConfigureWebservices]: boolean;
    [APIAdminClient.ClientFeatureTypes.HasNominations]: boolean;
    [APIAdminClient.ClientFeatureTypes.HasInternational]: boolean;
    [APIAdminClient.ClientFeatureTypes.AllowExternalEmails]: boolean;
    [APIAdminClient.ClientFeatureTypes.HideFundingSources]: boolean;
    [APIAdminClient.ClientFeatureTypes.ViewRevisionRequestUpdates]: boolean;
    [APIAdminClient.ClientFeatureTypes.BypassAttestation]: boolean;
    [APIAdminClient.ClientFeatureTypes.ReviewerExperience]: boolean;
  }

  export interface ClientSelectOption {
    name: string;
    clientId: number;
  }

  export enum ActivityAlertStatusOption {
    Active = 1,
    Snooze1Month = 2,
    Snooze2Months = 3,
    Snooze3Months = 4
  }

  export interface ClientSettingsFormGroup {
    name: string;
    siteId: number;
    csrClient: string | number;
    rootFirstName: string;
    rootLastName: string;
    rootJobTitle: string;
    rootEmail: string;
    subDomain: string;
    loginBehavior: LoginBehaviors;
    clientProcessingType: ProcessingTypes;
    defaultCurrency: string;
    defaultLanguage: string;
    defaultTimezone: string;
    overrideEmails: string;
    applicantTypesSupported: SupportedApplicantTypes;
  }
}

export interface ClientSettingsFromAPI {
  hasSSO: boolean;
  country: string;
  defaultTimezone: string;
  defaultLanguage: string;
  isRootClient: boolean;
  clientProcessingType: ProcessingTypes;
  applicantTypesSupported: APIAdminClient.SupportedApplicantTypes;
  affiliateId?: number;
  clientFeatures: APIAdminClient.ClientFeatureTypes[];
}

export interface ClientSettings extends ClientSettingsFromAPI {
  clientId: number;
}

export interface ConfigureSettingsMap {
  colors?: BrandColors;
  logoUrl?: string;
  file?: YcFile;
  logoChanged?: boolean;
  languages?: string[];
  currencies?: string[];
  fontSize?: string;
  fontColor?: string;
  fontFamily?: string;
  lineHeight?: number;
  
}

export interface Currency {
  code: string;
  displayName: string;
}

export interface CurrencySetting extends Currency {
  default: boolean;
}

export interface ClientIntAuthorities {
  foreignRegistrationAuthorities: SimpleRegAuthority[];
  domesticRegistrationAuthorities: SimpleRegAuthority[];
}

export interface SimpleRegAuthority {
  name: string;
}

export interface SelectedLangsFromApi {
  culture: string;
  displayName: string;
  default: boolean;
}

export interface ObjectDataCsv {
  id: number;
  name: string;
  parentId: number;
  objectType: string;
}

export enum ClientSettingsScenario {
  CREATE = 1,
  EDIT = 2,
  VIEW = 3
}

export enum CreateClientResponse {
  SUCCESS = 1,
  FAIL = 2,
  EMAIL_EXISTS = 3
}
