import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { FormAudience, FormData, FormDefinitionComponent, FormDefinitionForUi } from '@features/configure-forms/form.typing';
import { CustomDataTablesService } from '@features/custom-data-tables/services/custom-data-table.service';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { UserService } from '@features/users/user.service';
import { APIResult, Base64, PaginationOptions } from '@yourcause/common';
import { DateService, TIMESTAMP_FORMAT } from '@yourcause/common/date';
import { FileService } from '@yourcause/common/files';
import { RegexUI } from '@yourcause/common/form-control-validation';
import { HeapService, TrackingEventNames, TrackingPropertyNames } from '@yourcause/common/heap';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { FormFieldResources } from '../resources/form-field.resources';
import { FormFieldCategoryService } from './form-field-category.service';
import { FormFieldHelperService } from './form-field-helper.service';
import { FormFieldTableAndSubsetService } from './form-field-table-and-subset.service';

@Injectable({ providedIn: 'root' })
export class FormFieldService {

   constructor (
    private logger: LogService,
    private i18n: I18nService,
    private formFieldResources: FormFieldResources,
    private customDataTablesService: CustomDataTablesService,
    private userService: UserService,
    private notifier: NotifierService,
    private fileService: FileService,
    private arrayHelper: ArrayHelpersService,
    private translationService: TranslationService,
    private componentHelper: ComponentHelperService,
    private formFieldCategoryService: FormFieldCategoryService,
    private formFieldTableAndSubsetService: FormFieldTableAndSubsetService,
    private formFieldHelperService: FormFieldHelperService,
    private dateService: DateService,
    private confirmAndTakeActionService: ConfirmAndTakeActionService,
    private heapService: HeapService
  ) { }

  get dataTables () {
    return this.customDataTablesService.customDataTableOptionsMap;
  }

  get allReferenceFields () {
    return this.formFieldHelperService.allReferenceFields;
  }

  get referenceFieldMap () {
    return this.formFieldHelperService.referenceFieldMap;
  }

  async resolve () {
    await Promise.all([
      this.formFieldCategoryService.fetchAllCategories(),
      this.fetchAllFields()
    ]);
  }

  async resetFieldsAndCategories () {
    await Promise.all([
      this.resetFields(),
      this.formFieldCategoryService.resetCategories()
    ]);
  }

  async resetFields () {
    this.setAllFieldOnState(undefined);
    await this.fetchAllFields();
    this.formFieldHelperService.resetRefFieldRepo();
  }

  async getReferenceFieldResponses (
    appId: number,
    applicationFormId: number,
    formDefinition: FormDefinitionForUi[], /* used to filter out field responses that no longer exist on this form */
    tableAndSubsetIds: number[],
    revisionId: number, /* Pass revisionId if appFormId not created yet (Offline) */
    skipAddTablesToState = false /* This is only necessary when editing a form */,
    onlyFetchTableResponses = false /* For after import of table rows */,
    fetchedResponses?: ReferenceFieldAPI.ApplicationRefFieldResponse[],
    formData?: FormData // only used for eligibility forms. We need to add these answers to reference fields responses so it populates
  ): Promise<ReferenceFieldsUI.RefResponseMap> {
    const shouldFetch = !onlyFetchTableResponses && !fetchedResponses;
    let responses = fetchedResponses ?? [];
    const [
      tableResponses,
      _responses
    ] = await Promise.all([
      this.formFieldTableAndSubsetService.getTableResponses(
        appId,
        applicationFormId,
        tableAndSubsetIds
      ),
      shouldFetch ? 
        this.formFieldResources.getReferenceFieldResponses(
          appId,
          applicationFormId
        ) :
        responses
    ]);
    responses = _responses;
    let mapped: ReferenceFieldsUI.RefResponseMapForAdapting = {};
    if (!onlyFetchTableResponses) {
      if (formData) {
        const map = this.componentHelper.findFormDataToAddToResponses(formData, formDefinition);
        Object.keys(map).forEach((referenceFieldKey) => {
          const field = this.referenceFieldMap[referenceFieldKey];
          if (field) {
            responses.push({
              referenceFieldKey,
              referenceFieldId: field.referenceFieldId,
              value: map[referenceFieldKey],
              numericValue: null,
              dateValue: '',
              currencyValue: '',
              addressValue: null,
              file: null,
              files: [],
              applicationFormId,
              applicationId: appId
            });
          }
        });
      }

      mapped = this.getMappedResponsesForFormatting(responses);
      this.formFieldHelperService.filterOutResponsesNoLongerOnForm(
        formDefinition,
        mapped
      );
    }
    this.formFieldTableAndSubsetService.mapTableResponsesForUi(
      tableResponses,
      mapped,
      appId,
      applicationFormId
    );

    this.formFieldHelperService.formatReferenceFieldResponses(mapped, appId, applicationFormId);
    const mapToReturn: ReferenceFieldsUI.RefResponseMap = {};
    Object.keys(mapped).forEach((key) => {
      const field = this.referenceFieldMap[key];
      mapToReturn[field.key] = mapped[key].value;
    });

    if (!skipAddTablesToState) {
      this.formFieldTableAndSubsetService.setApplicationFormTableRowsMap(
        applicationFormId || revisionId,
        mapToReturn
      );
    }

    return mapToReturn;
  }

  getMappedResponsesForFormatting (
    responses: ReferenceFieldAPI.ApplicationRefFieldResponse[]
  ) {
    return responses.reduce((acc, response) => {
      const field = this.referenceFieldMap[response.referenceFieldKey];
      response.value = this.formFieldHelperService.prepareValueForMapping(response, field);

      return {
        ...acc,
        [response.referenceFieldKey]: response
      };
    }, {});
  }

  async copyField (
    field: ReferenceFieldAPI.ReferenceFieldDisplayModel,
    skipToaster = false
  ) {
    const copyText = this.i18n.translate(
      'common:textCopy',
      {},
      'Copy'
    );
    const fieldToSave: ReferenceFieldAPI.ReferenceFieldDisplayModel = {
      ...field,
      name: this.formFieldHelperService.guessBasedOnExisting(
        `${field.name} ${copyText}`,
        'name'
      ),
      key: this.formFieldHelperService.guessBasedOnExisting(
        `copy_${field.key}`,
        'key'
      )
    };
    const tableFields = await this.formFieldTableAndSubsetService.getTableFields(field.referenceFieldId);
    const newField = await this.handleCreateOrUpdateField(
      null,
      fieldToSave,
      tableFields,
      true,
      true
    );
    if (!!newField && !skipToaster) {
      this.notifier.success(this.i18n.translate(
        'FORMS:notificationSuccessCopyFormField',
        {},
        'Successfully copied your form field'
      ));
    }

    return newField;
  }

  async handleCopyComponents (
    componentsToCopy: FormDefinitionComponent[]
  ): Promise<Record<string, string>> {
    try {
      const oldKeyToNewKeyMap: Record<string, string> = {};
      const newKeyToOldKeyMap: Record<string, string> = {};
      const copyText = this.i18n.translate(
        'common:textCopy',
        {},
        'Copy'
      );
      const additionalGeneratedKeys: string[] = [];
      const tableRefIds = this.formFieldTableAndSubsetService.getTableAndSubsetIds(componentsToCopy);
      await this.formFieldTableAndSubsetService.setAllTableAndSubsetColumnsOnForm(tableRefIds);
      const newFields = componentsToCopy.map<ReferenceFieldAPI.BulkCreateReferenceField>((component) => {
        const oldKey = this.componentHelper.getRefFieldKeyFromCompType(component.type);
        const field = this.formFieldHelperService.getReferenceFieldByKey(oldKey);
        const newKey = this.formFieldHelperService.guessBasedOnExisting(
          `copy_${field.key}`,
          'key',
          0,
          additionalGeneratedKeys
        );
        additionalGeneratedKeys.push(newKey);
        oldKeyToNewKeyMap[oldKey] = newKey;
        newKeyToOldKeyMap[newKey] = oldKey;

        let dataTableId: number = null;
        if (field.customDataTableGuid) {
          dataTableId = this.customDataTablesService.getCDTIdFromGuid(
            field.customDataTableGuid
          );
        }
        let parentReferenceFieldKey: string = null;
        if (field.parentReferenceFieldId) {
          parentReferenceFieldKey = this.formFieldHelperService.referenceFieldMapById[
            field.parentReferenceFieldId
          ]?.key ?? null;
        }
        const formAudience = field.formAudience;

        const returnVal: ReferenceFieldAPI.BulkCreateReferenceField = {
          id: undefined,
          name: this.formFieldHelperService.guessBasedOnExisting(
            `${field.name} ${copyText}`,
            'name'
          ),
          defaultLabel: field.defaultLabel,
          defaultMin: field.defaultMin,
          defaultMax: field.defaultMax,
          description: field.description,
          type: field.type,
          key: newKey,
          picklistId: dataTableId,
          parentReferenceFieldKey,
          supportsMultiple: field.supportsMultiple,
          category: !field.categoryId ?
            null :
            {
              id: field.categoryId,
              name: this.formFieldCategoryService.categoryNameMap[field.categoryId]
            },
          formAudience,
          aggregateType: field.aggregateType,
          isEncrypted: field.isEncrypted,
          isMasked: field.isMasked,
          formatType: field.formatType,
          isTableField: field.isTableField,
          singleResponse: formAudience === FormAudience.APPLICANT ?
            true :
            field.isSingleResponse ?? false,
          subsetCollectionType: field.subsetCollectionType,
          captureExtendedAddressInfo: field.captureExtendedAddressInfo,
          aggregateTableReferenceFieldId: field.aggregateTableReferenceFieldId,
          tableInfo: this.formFieldTableAndSubsetService.adaptTableInfoForSave(
            field.tableAllowsImport,
            this.formFieldTableAndSubsetService.getColumnsForTableOrSubset(field.referenceFieldId)
          ),
          isRichText: field.isRichText
        };

        return returnVal;
      });
      this.formFieldHelperService.updateCopiedParentKeys(newFields, newKeyToOldKeyMap);

      await this.formFieldResources.bulkCreateReferenceFields(newFields);
      await this.resetFieldsAndCategories();

      return oldKeyToNewKeyMap;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'FORMS:textErrorCopyingFields',
        {},
        'There was an error copying the fields'
      ));

      return null;
    }
  }

  async handleCreateOrUpdateField (
    referenceFieldId: number,
    field: ReferenceFieldAPI.ReferenceFieldBaseModel,
    tableFields: ReferenceFieldsUI.TableFieldForUi[] = [],
    skipToaster = false,
    isCopy = false
  ): Promise<ReferenceFieldAPI.ReferenceFieldDisplayModel> {
    const tableInfo = this.formFieldTableAndSubsetService.adaptTableInfoForSave(
      field.tableAllowsImport,
      tableFields
    );
    const payload: ReferenceFieldAPI.CreateUpdateReferenceField = {
      referenceFieldId,
      customDataTableGuid: field.customDataTableGuid,
      tableInfo,
      name: field.name,
      defaultLabel: field.defaultLabel,
      defaultMin: field.defaultMin,
      defaultMax: field.defaultMax,
      description: field.description,
      type: field.type,
      key: field.key,
      supportsMultiple: field.supportsMultiple,
      categoryId: field.categoryId || null,
      formAudience: field.formAudience,
      parentReferenceFieldId: field.parentReferenceFieldId,
      aggregateType: field.aggregateType,
      isSingleResponse: field.isSingleResponse,
      isEncrypted: field.isEncrypted,
      isMasked: field.isMasked,
      isTableField: field.isTableField,
      formatType: field.formatType,
      aggregateTableReferenceFieldId: field.aggregateTableReferenceFieldId,
      subsetCollectionType: field.subsetCollectionType,
      captureExtendedAddressInfo: field.captureExtendedAddressInfo,
      isRichText: field.isRichText
    };
    const createdField = await this.createOrUpdateField(
      referenceFieldId,
      payload,
      skipToaster,
      isCopy
    );
    if (createdField) {
      const aggregateInfo = this.formFieldTableAndSubsetService.getAggregateFieldChanges(
        field.name,
        createdField.referenceFieldId,
        tableFields
      );
      await Promise.all(aggregateInfo.fieldsToCreateOrUpdate.map((aggregateField) => {
        return this.createOrUpdateField(
          aggregateField.referenceFieldId,
          aggregateField,
          true,
          isCopy
        );
      }));
      await Promise.all(aggregateInfo.fieldsToRemove.map((idToRemove) => {
        return this.formFieldResources.removeReferenceField(
          idToRemove
        );
      }));
      if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table) {
        this.formFieldTableAndSubsetService.resetTableColumnsMap(createdField.referenceFieldId);
      } else if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset) {
         this.formFieldTableAndSubsetService.resetDataPointsMap(createdField.referenceFieldId);
      }
    }

    return createdField;
  }

  async createOrUpdateField (
    referenceFieldId: number,
    payload: ReferenceFieldAPI.CreateUpdateReferenceField,
    skipToaster = false,
    isCopy = false
  ) {
    try {
      const response = await this.formFieldResources.createOrUpdateField(
        referenceFieldId,
        payload
      );
      await this.resetFieldsAndCategories();
      if (!skipToaster) {
        this.notifier.success(this.i18n.translate(
          'FORMS:notificationSuccessSavedFormField',
          {},
          'Successfully saved your form field'
        ));
      }

      return response;
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        isCopy ?
          'FORMS:notificationErrorCopyFormField' :
          'FORMS:notificationErrorSavingFormField',
        {},
        isCopy ?
          'There was an error copying your form field' :
          'There was an error saving your form field'
      ));
      this.logger.error(e);

      return null;
    }
  }

  setAllFieldOnState (fields: ReferenceFieldAPI.ReferenceFieldDisplayModel[]) {
    this.formFieldHelperService.setAllFieldsOnState(fields);
  }

  async getReferenceFieldsPaginated (
    paginationOptions: PaginationOptions<ReferenceFieldAPI.ReferenceFieldPaginatedModel>
  ): Promise<APIResult<ReferenceFieldAPI.ReferenceFieldPaginatedModelForUi>> {
    const formattedResponse = this.formFieldHelperService.formatPaginationOptions(paginationOptions);
    const response = await this.formFieldResources.searchReferenceFields(
      formattedResponse.paginationOptions,
      formattedResponse.formIds
    );
    const records = this.formFieldHelperService.adaptReferenceFields(response.records, true);

    return {
      success: true,
      data: {
        recordCount: response.recordCount,
        records
      }
    };
  }

  async fetchAllFields () {
    if (!this.allReferenceFields) {
      const records = await this.formFieldResources.getAllReferenceFields();
      this.formFieldHelperService.setAllReferenceFields(records);
    }
  }

  async fetchFieldsByFormRevisionId (
    formRevisionId: number
  ): Promise<ReferenceFieldAPI.ReferenceFieldDisplayModel[]> {
    if (formRevisionId) {
      return this.formFieldResources.getReferenceFieldsByFormRevisionId(formRevisionId);
    }

    return [];
  }

  /**
   * Delete Given Form Fields
   *
   * @param fieldIds: Field Ids to Delete
   */
  async doRemoveFields (fieldIds: number[]) {
    const isSingle = fieldIds.length === 1;
    if (isSingle) {
      await this.formFieldResources.removeReferenceField(fieldIds[0]);
    } else {
      await this.formFieldResources.bulkRemoveReferenceFields(fieldIds);
    }
    await this.resetFieldsAndCategories();
  }

  /**
   * Handles deleting the fields
   *
   * @param fieldIds: Field IDs to remove
   */
  async handleDeleteFormFields (fieldIds: number[]) {
    const isSingle = fieldIds.length === 1;
    await this.confirmAndTakeActionService.genericTakeAction(
      () => this.doRemoveFields(fieldIds),
      this.i18n.translate(
        isSingle ?
          'FORMS:notificationSuccessDeletingFormField' :
          'FORMS:textSuccessDeleteFormFields',
        {},
        isSingle ?
          'Successfully deleted your form field' :
          'Successfully deleted the form fields'
      ),
      this.i18n.translate(
        isSingle ?
          'FORMS:notificationErrorDeletingFormField' :
          'FORMS:textErrorDeletingFormFields',
        {},
        isSingle ?
          'There was an error deleting your form field' :
          'There was an error deleting the form fields'
      )
    );
  }

  async exportReferenceFields (fields: number[]) {
    try {
      const exportedForms = await this.formFieldResources.exportReferenceFields(fields);

      this.fileService.downloadRaw(
        Base64.encode(JSON.stringify(exportedForms)),
        `ref_fields_export_${this.dateService.formatDate(new Date(), TIMESTAMP_FORMAT)}.bin`
      );
      this.notifier.success(this.i18n.translate(
        'FORMS:textSuccessfullyExportedSelectedFormFields',
        {},
        'Successfully exported the selected form fields'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'FORMS:textErrorExportingSelectedFormFields',
        {},
        'There was an error exporting the selected form fields'
      ));
    }
  }

  async importReferenceFields (fieldExport: string) {
    let fields: ReferenceFieldAPI.ExportReferenceField[];
    try {
      fields = JSON.parse(Base64.decode(fieldExport));
      await this.handleBulkCreateReferenceFields(fields, true);
    } catch (e) {
      this.logger.error(e);
      this.notifier.warning(this.i18n.translate(
        'FORMS:notificationInvalidFileType',
        {},
        'The provided file was invalid, please obtain a valid file and try again.'
      ));

      return;
    }
  }

  async handleBulkCreateReferenceFields (
    fieldsToImport: ReferenceFieldAPI.ExportReferenceField[]|ReferenceFieldAPI.BulkCreateReferenceField[],
    isImport = false
  ) {
    try {
      if (isImport) {
        await this.formFieldResources.importReferenceFields(
          fieldsToImport as ReferenceFieldAPI.ExportReferenceField[]
        );
      } else {
        await this.formFieldResources.bulkCreateReferenceFields(
          fieldsToImport as ReferenceFieldAPI.BulkCreateReferenceField[]
        );
      }
      await this.resetFieldsAndCategories();
      this.notifier.success(this.i18n.translate(
        'FORMS:notificationSuccessFormFields',
        {},
        'Successfully imported your form fields'
      ));
      
      // track event
      this.heapService.track(
        TrackingEventNames.BulkCreateReferenceFields,
        {
          [TrackingPropertyNames.IsImport]: String(isImport),
          [TrackingPropertyNames.RecordCount]: fieldsToImport?.length,
          [TrackingPropertyNames.Success]: String(true)
        });
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'FORMS:notificationErrorImportingFormFields',
        {},
        'There was an error importing your form fields'
      ));

      // track event
      this.heapService.track(
        TrackingEventNames.BulkCreateReferenceFields,
        {
          [TrackingPropertyNames.IsImport]: String(isImport),
          [TrackingPropertyNames.RecordCount]: fieldsToImport?.length,
          [TrackingPropertyNames.Success]: String(false)
        });
    }
  }

  async handleMergeFields (
    payload: ReferenceFieldAPI.MergeFormFieldsApi
  ) {
    try {
      await this.formFieldResources.mergeFields(payload);
      await this.resetFieldsAndCategories();
      this.notifier.success(this.i18n.translate(
        'GLOBAL:textSuccessfullyMergedFields',
        {},
        'Successfully merged the form fields'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorMergingFields',
        {},
        'There was an error merging the form fields'
      ));
    }
  }

  async formatCreateEditFieldModalReturn (
    field: ReferenceFieldAPI.ReferenceFieldBaseModel,
    tableFields: ReferenceFieldsUI.TableFieldForUi[],
    addingCategory = false,
    isSecondarySave = false
  ): Promise<ReferenceFieldsUI.ModalReturn> {
    field = await this.handleFormatAndAddCategory(
      field,
      addingCategory
    );

    return {
      field: {
        ...field
      },
      tableFields,
      isSecondarySave
    };
  }

  async formatMergeModalReturn (
    field: ReferenceFieldAPI.ReferenceFieldBaseModel,
    referenceFieldId1: number,
    referenceFieldId2: number,
    priorityReferenceFieldId: number,
    addingCategory = false
  ): Promise<ReferenceFieldAPI.MergeFormFieldsApi> {
    field = await this.handleFormatAndAddCategory(
      field,
      addingCategory
    );

    return {
      ...field,
      referenceFieldId1,
      referenceFieldId2,
      priorityReferenceFieldId
    };
  }

  async handleFormatAndAddCategory (
    field: ReferenceFieldAPI.ReferenceFieldBaseModel,
    addingCategory = false
  ) {
    let supportsMultiple = field.supportsMultiple;
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.SelectBoxes) {
      // checkboxes can only support multiple
      supportsMultiple = true;
    }
    const canSupportMulti = this.formFieldHelperService.doesTypeSupportMulti(field.type);
    if (!canSupportMulti) {
      supportsMultiple = false;
    }
    let categoryId = field.categoryId;
    if (addingCategory && field.categoryId) {
      // When adding category, categoryId form control stores the name.
      // Create the category to get the ID
      categoryId = await this.formFieldCategoryService.handleCreateOrEditCategory(
        undefined,
        field.categoryId as unknown as string
      );
    }
    if (field.formatType === RegexUI.RegexFormattingType.NONE) {
      field.formatType = null;
    }
    // if we are saving a field as an aggregate
    if (field.type === ReferenceFieldsUI.ReferenceFieldTypes.Aggregate) {
      // convert it to a number
      field.type = ReferenceFieldsUI.ReferenceFieldTypes.Number;
      field.formAudience = FormAudience.MANAGER;
      field.isSingleResponse = true;
    } else {
      // otherwise clear out the aggregate setting
      field.aggregateType = null;
      field.isSingleResponse = field.formAudience === FormAudience.APPLICANT ?
        true :
        field.isSingleResponse ?? false;
    }

    return {
      ...field,
      categoryId: categoryId || null,
      supportsMultiple,
      formatType: field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextField ?
        field.formatType :
        null,
      subsetCollectionType: field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset ?
        field.subsetCollectionType :
        null,
      isRichText: field.type === ReferenceFieldsUI.ReferenceFieldTypes.TextArea ?
        field.isRichText :
        false,
      tableAllowsImport: field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table ?
        field.tableAllowsImport :
        false
    };
  }

  getDetailForField (referenceFieldId: number) {
    if (referenceFieldId) {
      return this.formFieldResources.getReferenceFieldDetail(referenceFieldId);
    }

    return null;
  }

  getDetailForFields (
    fieldsToMerge: ReferenceFieldAPI.ReferenceFieldDisplayModel[]
  ) {
    const translations = this.translationService.viewTranslations.FormTranslation;

    return Promise.all(fieldsToMerge.map(async (field) => {
      const detail = await this.formFieldResources.getReferenceFieldDetail(
        field.referenceFieldId
      );
      detail.forms = detail.forms || [];
      detail.forms.forEach((form) => {
        form.name = translations[form.formId]?.Name || form.name;
      });
      detail.forms = this.arrayHelper.sort(detail.forms, 'name');

      return detail;
    }));
  }

  async getApplicationResponsesForMerge (
    referenceFieldId1: number,
    referenceFieldId2: number
  ): Promise<ReferenceFieldAPI.ApplicationResponse[]> {
    const responses = await this.formFieldResources.getApplicationResponsesForMerge(
      referenceFieldId1,
      referenceFieldId2
    );
    const field1 = this.formFieldHelperService.referenceFieldMapById[referenceFieldId1];
    if (field1.customDataTableGuid) {
      await this.customDataTablesService.setCustomDataTableOptionsFromGuid(
        field1.customDataTableGuid,
        this.userService.getCurrentUserCulture()
      );
      const options = this.dataTables[field1.customDataTableGuid];
      responses.forEach((response) => {
        const found1 = options.find((opt) => {
          return opt.key === response.referenceField1Response;
        });
        response.referenceField1Response = found1?.value ??
          response.referenceField1Response;
        const found2 = options.find((opt) => {
          return opt.key === response.referenceField2Response;
        });
        response.referenceField2Response = found2?.value ??
          response.referenceField2Response;
      });
    }

    return responses;
  }

  adaptFormChangesForSave (
    changeMap: ReferenceFieldsUI.RefResponseMap,
    applicationFormId: number,
    applicationId: number,
    revisionId: number,
    isNew: boolean /* If new (offline only), we track off revisionId */
    /* because applicationFormId is not defined yet */,
    isManagerForm: boolean
  ): ReferenceFieldsUI.AdaptRefChangesResponse {
    const nonTableChangeMap: ReferenceFieldsUI.RefResponseMap = {};
    const tableChangeMap: ReferenceFieldsUI.RefResponseMap = {};
    Object.keys(changeMap).forEach((refFieldKey) => {
      const field = this.formFieldHelperService.getReferenceFieldByKey(refFieldKey);
      const isTable = field.type === ReferenceFieldsUI.ReferenceFieldTypes.Table ||
        field.type === ReferenceFieldsUI.ReferenceFieldTypes.Subset;
      if (isTable) {
        tableChangeMap[refFieldKey] = changeMap[refFieldKey];
      } else {
        nonTableChangeMap[refFieldKey] = changeMap[refFieldKey];
      }
    });
    let standardChangeValues: ReferenceFieldAPI.ApplicationRefFieldResponseForApi[] = [];
    let tableChangeValues: ReferenceFieldAPI.TableChangeResponse = {
      updates: [],
      deletions: []
    };
    if (Object.keys(nonTableChangeMap).length > 0) {
      standardChangeValues = this.formFieldHelperService.mapReferenceFieldResponsesForAPI(
        nonTableChangeMap,
        isManagerForm,
        this.referenceFieldMap
      );
    }
    if (Object.keys(tableChangeMap).length > 0) {
      tableChangeValues = this.formFieldTableAndSubsetService.mapTableResponsesForAPI(
        tableChangeMap,
        applicationFormId,
        applicationId,
        revisionId,
        isNew,
        isManagerForm
      );
    }

    return {
      standardChangeValues,
      tableChangeValues,
      tableChangeMap
    };
  }

  getCanUpdateToSingleResponse (referenceFieldId: number) {
    return this.formFieldResources.getCanUpdateRefFieldToSingleResponse(
      referenceFieldId
    );
  }

  async checkMergeForConflicts (
    standardProductReferenceFieldId: number,
    idsToMerge: number[]
  ): Promise<{
    hasConflicts: boolean;
    hasOtherError: boolean;
  }> {
    try {
      await this.formFieldResources.checkMergeForConflicts(
        standardProductReferenceFieldId,
        idsToMerge
      );

      return {
        hasConflicts: false,
        hasOtherError: false
      };
    } catch (err) {
      const e = err as HttpErrorResponse;
      this.logger.error(e);
      if (
        e?.error?.message === 'There are data conflicts among these reference fields. One or more reference fields exist on an application. Merge would result in data loss.'
      ) {
        this.notifier.error(this.i18n.translate(
          'common:textCantMergeWithStandardConflict2',
          {},
          `These fields both contain information related to the same application(s) so cannot be merged.`
        ));

        return {
          hasConflicts: true,
          hasOtherError: false
        };
      } else {
        this.notifier.error(this.i18n.translate(
          'common:textErrorMergeStandardProductField',
          {},
          'There was an error merging with the standard product field'
        ));

        return {
          hasConflicts: false,
          hasOtherError: true
        };
      }
    }
  }

  async handleMergeWithStandardField (
    standardFieldId: number,
    fieldIdsToMerge: number[]
  ) {
    try {
      await this.formFieldResources.mergeWithStandardProductField(
        standardFieldId,
        fieldIdsToMerge
      );
      await this.resetFieldsAndCategories();
      this.notifier.success(this.i18n.translate(
        'common:textSuccessMergeStandardProductField',
        {},
        'Successfully merged with the standard product field'
      ));
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'common:textErrorMergeStandardProductField',
        {},
        'There was an error merging with the standard product field'
      ));
    }
  }

  async handleFieldTypeConversion (
    referenceFieldId: number,
    conversionType: ReferenceFieldsUI.RefFieldConversionTypes,
    conversionCurrency?: string
  ) {
    try {
      switch (conversionType) {
        case ReferenceFieldsUI.RefFieldConversionTypes.NUMBER_TO_TEXT:
          await this.formFieldResources.convertNumberFieldToText(referenceFieldId);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.TEXT_TO_NUMBER:
          await this.formFieldResources.convertTextFieldToNumber(referenceFieldId);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.DATE_TO_TEXT:
          await this.formFieldResources.convertDateFieldToText(referenceFieldId);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.TEXT_TO_DATE:
          await this.formFieldResources.convertTextFieldToDate(referenceFieldId);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.MULTI_TO_SINGLE:
          await this.formFieldResources.convertMultiResponseFieldToSingleResponse(referenceFieldId);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.CURRENCY_TO_NUMBER:
          await this.formFieldResources.convertCurrencyFieldToNumber(referenceFieldId);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.NUMBER_TO_CURRENCY:
          await this.formFieldResources.convertNumberFieldToCurrency(referenceFieldId, conversionCurrency);
          break;
        case ReferenceFieldsUI.RefFieldConversionTypes.TO_ENCRYPTED:
          await this.formFieldResources.encryptFieldRetroactively(referenceFieldId);
          break;
      }
      await this.resetFieldsAndCategories();
      this.notifier.success(this.i18n.translate(
        'FORMS:textSuccessfullyConvertedFormField',
        {},
        'Successfully converted form field')
      );
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(
        this.i18n.translate(
          'FORMS:textThereWasAnErrorConvertingTheFieldType',
          {},
          'There was an error converting the field type'
        )
      );
    }
  }

  async validateFieldTypeConversion (
    referenceFieldId: number,
    conversionType: ReferenceFieldsUI.RefFieldConversionTypes
  ): Promise<ReferenceFieldAPI.ConversionValidityInfo> {
    let response;
    switch (conversionType) {
      case ReferenceFieldsUI.RefFieldConversionTypes.NUMBER_TO_TEXT:
        response = await this.formFieldResources.validateNumberToTextFieldConversion(referenceFieldId);
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.TEXT_TO_NUMBER:
        response = await this.formFieldResources.validateTextToNumberFieldConversion(referenceFieldId);
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.DATE_TO_TEXT:
        response = await this.formFieldResources.validateDateToTextFieldConversion(referenceFieldId);
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.TEXT_TO_DATE:
        response = await this.formFieldResources.validateTextToDateFieldConversion(referenceFieldId);
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.MULTI_TO_SINGLE:
        const multiToSingleResponse = await this.formFieldResources.validateMultiResponseToSingleResponseFieldConversion(referenceFieldId);
        response = {
          ...multiToSingleResponse,
          affectedAdHocFilters: [],
          affectedWflAutomationRules: []
        };
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.CURRENCY_TO_NUMBER:
        response = await this.formFieldResources.validateCurrencyToNumberFieldConversion();
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.NUMBER_TO_CURRENCY:
        const numberToCurrencyResponse = await this.formFieldResources.validateNumberToCurrencyFieldConversion(referenceFieldId);
        response = {
          ...numberToCurrencyResponse,
          affectedAdHocFilters: [],
          affectedWflAutomationRules: []
        };
        break;
      case ReferenceFieldsUI.RefFieldConversionTypes.TO_ENCRYPTED:
        const encryptedResponse = await this.formFieldResources.validateFieldEncryption(referenceFieldId);
        // Can always convert for encrypted
        response = {
          ...encryptedResponse,
          canConvert: true
        };
        break;
    }

    return response;
  }
}
