import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';

@Injectable({ providedIn: 'root' })
export class SupportResources {

  constructor (
    private http: HttpRestService
  ) { }

  sendSupportEmail (
    emailBody: string
  ) {
    return this.http.post(`api/support/sendEmail`,
      { emailBody }
    );
  }
}

