import { Injectable } from '@angular/core';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { Automation } from '@core/typings/ui/automation.typing';
import { Column } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { EmployeeSSOFieldsResources } from './employee-sso-fields.resources';
import { EmployeeSSOFieldsState } from './employee-sso-fields.state';
import { EmployeeSSOField, EmployeeSSOFields, EmployeeSSOFieldsData } from './employee-sso-fields.typing';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';


@AttachYCState(EmployeeSSOFieldsState)
@Injectable({ providedIn: 'root' })
export class EmployeeSSOFieldsService extends BaseYCService<EmployeeSSOFieldsState> {

   constructor (
    private logger: LogService,
    private employeeSSOFieldsResources: EmployeeSSOFieldsResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
  }

  get employeeSSOFields () {
    return this.get('employeeSSOFields');
  }

  get employeeSSOFieldData () {
    return this.get('employeeSSOFieldData');
  }

  get dependentSsoFilteringOptions () {
    return this.get('dependentSsoFilteringOptions');
  }

  get applicantColumnsForReporting () {
    return this.get('applicantColumnsForReporting');
  }

  async getEmployeeSSOFieldsForApp (appId: number) {
    const employeeInfo = await this.employeeSSOFieldsResources.getEmployeeSSOFieldsForApp(appId);
    Object.keys(employeeInfo)
      .map(key => key as keyof EmployeeSSOFieldsData)
      .forEach((key: keyof EmployeeSSOFieldsData) => {
        if (!employeeInfo[key]) {
          employeeInfo[key] = '';
        }
      });

    return employeeInfo;
  }

  async resetEmployeeSSOFields () {
    this.set('employeeSSOFields', undefined);
    await this.setEmployeeSSOFields();
  }

  async setEmployeeSSOFields () {
    if (!this.employeeSSOFields) {
      const columns = await this.employeeSSOFieldsResources.getEmployeeSSOFields();
      columns.forEach((column) => {
        this.setColumnNameAndAttr(column);
      });
      this.set(
        'employeeSSOFields',
        this.arrayHelper.sort(columns, 'order')
      );
      this.setDependentSsoFilteringOptions();
      this.setApplicantColumnsForReporting();
    }
  }

  async getEmployeeSsoFieldsBulk (
    applicationIds: number[]
  ) {
    const data = await this.employeeSSOFieldsResources.getEmployeeSSOFieldsBulk(applicationIds);
    data.forEach((employeeInfo) => {
      Object.keys(employeeInfo)
        .map(key => key as keyof EmployeeSSOFieldsData)
        .forEach((key: keyof EmployeeSSOFieldsData) => {
          if (!employeeInfo[key]) {
            employeeInfo[key] = '';
          }
        });
    });

    return data;
  }

  setDependentSsoFilteringOptions () {
    const options = this.employeeSSOFields.map<TypeaheadSelectOption<Column>>((field) => {
      const label = field.name || field.columnName;

      return {
        label,
        value: {
          label,
          visible: true,
          type: 'text',
          labelOnly: true,
          prop: field.attr,
          options: [],
          dependentColumnOptions: [],
          columnName: label,
          filterOnly: true
        }
      };
    });

    this.set(
      'dependentSsoFilteringOptions',
      this.arrayHelper.sort(options, 'label')
    );
  }

  getEmployeeSSOFieldsForLookup (): Record<EmployeeSSOFields, EmployeeSSOField> {
    const fieldMap: Record<EmployeeSSOFields, EmployeeSSOField> = this.get('employeeSSOFields')
      .reduce((acc, hrField) => {
          return {
            ...acc,
            [hrField.column]: hrField
          };
      }, {} as Record<EmployeeSSOFields, EmployeeSSOField>);

      return fieldMap;
  }

  setColumnNameAndAttr (column: EmployeeSSOField) {
    column.type = 'text';
    switch (column.column) {
      case EmployeeSSOFields.DepartmentName:
        column.columnName =  this.i18n.translate(
          'GLOBAL:textDepartmentName',
          {},
          'Department name'
        );
        column.attr = 'departmentName';
        column.order = 1;
        break;
      case EmployeeSSOFields.Division:
        column.columnName = this.i18n.translate(
          'GLOBAL:textDivision',
          {},
          'Division'
        );
        column.attr = 'division';
        column.order = 2;
        break;
      case EmployeeSSOFields.EmployeeTypeCode:
        column.columnName = this.i18n.translate(
          'GLOBAL:textEmployeeTypeCode',
          {},
          'Employee type code'
        );
        column.attr = 'employeeTypeCode';
        column.order = 3;
        break;
      case EmployeeSSOFields.Region:
        column.columnName =  this.i18n.translate(
          'GLOBAL:textRegion',
          {},
          'Region'
        );
        column.attr = 'region';
        column.order = 4;
        break;
      case EmployeeSSOFields.ReportGroupName:
        column.columnName = this.i18n.translate(
          'GLOBAL:textReportGroupName',
          {},
          'Report group name'
        );
        column.attr = 'reportGroupName';
        column.order = 5;
        break;
      case EmployeeSSOFields.CustomChar1:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn1',
          {},
          'Custom column 1'
        );
        column.attr = 'customChar1';
        column.order = 6;
        break;
      case EmployeeSSOFields.CustomChar2:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn2',
          {},
          'Custom column 2'
        );
        column.attr = 'customChar2';
        column.order = 7;
        break;
      case EmployeeSSOFields.CustomChar3:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn3',
          {},
          'Custom column 3'
        );
        column.attr = 'customChar3';
        column.order = 8;
        break;
      case EmployeeSSOFields.CustomChar4:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn4',
          {},
          'Custom column 4'
        );
        column.attr = 'customChar4';
        column.order = 9;
        break;
      case EmployeeSSOFields.CustomChar5:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn5',
          {},
          'Custom column 5'
        );
        column.attr = 'customChar5';
        column.order = 10;
        break;
      case EmployeeSSOFields.CustomChar6:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn6',
          {},
          'Custom column 6'
        );
        column.attr = 'customChar6';
        column.order = 11;
        break;
      case EmployeeSSOFields.CustomChar7:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn7',
          {},
          'Custom column 7'
        );
        column.attr = 'customChar7';
        column.order = 12;
        break;
      case EmployeeSSOFields.CustomChar8:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn8',
          {},
          'Custom column 8'
        );
        column.attr = 'customChar8';
        column.order = 13;
        break;
      case EmployeeSSOFields.CustomChar9:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn9',
          {},
          'Custom column 9'
        );
        column.attr = 'customChar9';
        column.order = 14;
        break;
      case EmployeeSSOFields.CustomChar10:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn10',
          {},
          'Custom column 10'
        );
        column.attr = 'customChar10';
        column.order = 15;
        break;
      case EmployeeSSOFields.CustomChar11:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn11',
          {},
          'Custom column 11'
        );
        column.attr = 'customChar11';
        column.order = 16;
        break;
      case EmployeeSSOFields.CustomChar12:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn12',
          {},
          'Custom column 12'
        );
        column.attr = 'customChar12';
        column.order = 17;
        break;
      case EmployeeSSOFields.CustomChar13:
        column.columnName = this.i18n.translate(
          'GLOBAL:textCustomColumn13',
          {},
          'Custom column 13'
        );
        column.attr = 'customChar13';
        column.order = 18;
        break;
      case EmployeeSSOFields.PositionTitle:
        column.columnName = this.i18n.translate(
          'GLOBAL:textEmployeePositionTitle',
          {},
          'Position title'
        );
        column.attr = 'positionTitle';
        column.order = 19;
        break;
      case EmployeeSSOFields.Market:
        column.columnName = this.i18n.translate(
          'GLOBAL:textMarket',
          {},
          'Market'
        );
        column.attr = 'market';
        column.order = 20;
        break;
      case EmployeeSSOFields.ManagerEmployeeId:
        column.columnName = this.i18n.translate(
          'GLOBAL:textEmployeeManagerEmployeeId',
          {},
          'Manager employee ID'
        );
        column.type = 'number';
        column.attr = 'managerEmployeeId';
        column.order = 21;
        break;
      case EmployeeSSOFields.ExternalEmployeeId2:
        column.columnName = this.i18n.translate(
          'common:textExternalEmployeeId2',
          {},
          'External employee ID 2'
        );
        column.attr = 'externalEmployeeId2';
        column.order = 22;
        break;
      case EmployeeSSOFields.FullTimeCodeId:
        column.columnName = this.i18n.translate(
          'GLOBAL:textFullTimeCodeId',
          {},
          'Full time code ID'
        );
        column.attr = 'fullTimeCodeId';
        column.order = 23;
        break;
      case EmployeeSSOFields.HireDate:
         column.columnName = this.i18n.translate(
          'GLOBAL:textHireDate',
          {},
          'Hire date'
        );
        column.attr = 'hireDate';
        column.type = 'date';
        column.order = 24;
        break;
      case EmployeeSSOFields.JobCodeId:
        column.columnName = this.i18n.translate(
          'GLOBAL:textJobCodeId',
          {},
          'Job code ID'
        );
        column.attr = 'jobCodeId';
        column.order = 25;
        break;
      case EmployeeSSOFields.SiteCodeId:
        column.columnName = this.i18n.translate(
          'GLOBAL:textSiteCodeID',
          {},
          'Site code ID'
        );
        column.attr = 'siteCodeId';
        column.order = 26;
        break;
      case EmployeeSSOFields.ExternalEmployeeId:
        column.columnName = this.i18n.translate(
          'common:textExternalEmployeeId',
          {},
          'External employee ID'
        );
        column.attr = 'externalEmployeeId';
        column.order = 27;
        break;
      case EmployeeSSOFields.MemberId:
        column.columnName = this.i18n.translate(
          'GLOBAL:textMemberID',
          {},
          'Member ID'
        );
        column.attr = 'memberId';
        column.order = 28;
        break;
      case EmployeeSSOFields.AffiliateCorpName:
        column.columnName = this.i18n.translate(
          'GLOBAL:textAffiliateCorpNameID',
          {},
          'Affiliate Corporation Name'
        );
        column.attr = 'affiliateCorpName';
        column.order = 29;
        break;
      case EmployeeSSOFields.PayCurrencyCode:
        column.columnName = this.i18n.translate(
          'GLOBAL:textPayCurrencyCode',
          {},
          'Pay Currency Code'
        );
        column.attr = 'payCurrencyCode';
        column.order = 30;
        break;
      case EmployeeSSOFields.PayPeriods:
        column.columnName = this.i18n.translate(
        'GLOBAL:textPayPeriods',
        {},
        'Pay Periods'
        );
        column.attr = 'payPeriods';
        column.order = 31;
        break;
      case EmployeeSSOFields.PayrollCenter:
          column.columnName = this.i18n.translate(
          'GLOBAL:textpayrollCenter',
          {},
          'Payroll Center'
        );
        column.attr = 'payrollCenter';
        column.order = 32;
        break;
      case EmployeeSSOFields.PayrollId:
        column.columnName = this.i18n.translate(
          'GLOBAL:textpayrollId',
          {},
          'Payroll Id'
        );
        column.attr = 'payrollId';
        column.order = 33;
        break;
      case EmployeeSSOFields.MailStop:
          column.columnName = this.i18n.translate(
          'GLOBAL:textmailStop',
          {},
          'Mail Stop'
        );
        column.attr = 'mailStop';
        column.order = 34;
        break;
      case EmployeeSSOFields.HomeAddress:
        column.columnName = this.i18n.translate(
          'GLOBAL:texthomeAddress',
          {},
          'Home Address'
        );
        column.attr = 'homeAddress';
        column.order = 35;
        break;
      case EmployeeSSOFields.HomeAddress2:
        column.columnName = this.i18n.translate(
          'GLOBAL:texthomeAddress2',
          {},
          'Home Address 2'
        );
        column.attr = 'homeAddress2';
        column.order = 36;
        break;
      case EmployeeSSOFields.HomeCity:
        column.columnName = this.i18n.translate(
          'GLOBAL:texthomeCity',
          {},
          'Home City'
        );
        column.attr = 'homeCity';
        column.order = 37;
        break;
      case EmployeeSSOFields.HomeState:
        column.columnName = this.i18n.translate(
         'GLOBAL:texthomeState',
          {},
          'Home State'
          );
        column.attr = 'homeState';
        column.order = 38;
        break;
      case EmployeeSSOFields.HomeZipCode:
        column.columnName = this.i18n.translate(
          'GLOBAL:texthomeZipCode',
          {},
          'Home Zip Code'
          );
        column.attr = 'homeZipCode';
        column.order = 39;
        break;
      case EmployeeSSOFields.HomeCountryCode:
        column.columnName = this.i18n.translate(
          'GLOBAL:texthomeCountryCode',
          {},
          'Home Country Code'
        );
        column.attr = 'homeCountryCode';
        column.order = 40;
        break;
      case EmployeeSSOFields.ShiftCode:
        column.columnName = this.i18n.translate(
        'GLOBAL:textshiftCode',
          {},
          'Shift Code'
        );
        column.attr = 'shiftCode';
        column.order = 41;
        break;
      case EmployeeSSOFields.EmployeeType:
        column.columnName = this.i18n.translate(
          'GLOBAL:textemployeeType',
          {},
          'Employee Type'
         );
        column.attr = 'employeeType';
        column.order = 42;
        break;
      case EmployeeSSOFields.EmployeeStatus:
        column.columnName = this.i18n.translate(
          'GLOBAL:textemployeeStatus',
          {},
          'Employee Status'
        );
        column.attr = 'employeeStatus';
        column.order = 43;
        break;
      case EmployeeSSOFields.SubCompanyCode:
        column.columnName = this.i18n.translate(
          'GLOBAL:textsubCompanyCode',
          {},
          'Sub Company Code'
        );
         column.attr = 'subCompanyCode';
         column.order = 44;
         break;
      case EmployeeSSOFields.PayrollType:
        column.columnName = this.i18n.translate(
        'GLOBAL:textpayrollType',
          {},
          'Payroll Type'
        );
        column.attr = 'payrollType';
        column.order = 45;
        break;
      case EmployeeSSOFields.PayrollFilter:
        column.columnName = this.i18n.translate(
        'GLOBAL:textpayrollFilter',
          {},
          'Payroll Filter'
        );
        column.attr = 'payrollFilter';
        column.order = 46;
        break;
      case EmployeeSSOFields.MatchType:
        column.columnName = this.i18n.translate(
        'GLOBAL:textMatchType',
          {},
          'Match Type'
        );
        column.attr = 'matchType';
        column.order = 47;
        break;

      case EmployeeSSOFields.CustomSegment:
        column.columnName = this.i18n.translate(
          'GLOBAL:textcustomSegment',
          {},
          'CustomSegment'
        );
        column.attr = 'customSegment';
        column.order = 48;
        break;
      case EmployeeSSOFields.IsEmployeeActive:
        column.columnName = this.i18n.translate(
         'common:hdrIsEmployeeActive',
          {},
          'Is Employee Active'
        );
        column.attr = 'isEmployeeActive';
        column.order = 49;
        break;
        case EmployeeSSOFields.WorkAddress:
          column.columnName = this.i18n.translate(
            'GLOBAL:textworkAddress',
            {},
            'Work Address'
          );
          column.attr = 'workAddress';
          column.order = 50;
          break;
        case EmployeeSSOFields.WorkAddress2:
          column.columnName = this.i18n.translate(
            'GLOBAL:textworkAddress2',
            {},
            'Work Address 2'
          );
          column.attr = 'workAddress2';
          column.order = 51;
          break;
        case EmployeeSSOFields.WorkCity:
          column.columnName = this.i18n.translate(
            'GLOBAL:textworkCity',
            {},
            'Work City'
          );
          column.attr = 'workCity';
          column.order = 52;
          break;
        case EmployeeSSOFields.WorkState:
          column.columnName = this.i18n.translate(
           'GLOBAL:textworkState',
            {},
            'Work State'
            );
          column.attr = 'workState';
          column.order = 53;
          break;
        case EmployeeSSOFields.WorkPhone:
          column.columnName = this.i18n.translate(
            'GLOBAL:textworkPhone',
            {},
            'Work Phone'
            );
          column.attr = 'workPhone';
          column.order = 54;
          break;
      default:
        column.columnName = '';
        column.attr = '';
        column.order = 0;
        break;
    }
  }

  async setEmployeeSSOFieldData () {
    if (!this.employeeSSOFieldData) {
      const data = await this.employeeSSOFieldsResources.getEmployeeSSOFieldData();

      this.set('employeeSSOFieldData', data);
    }
  }

  async updateEmployeeSSOFields (payload: EmployeeSSOField[]) {
    try {
      await this.employeeSSOFieldsResources.updateEmployeeSSOFields(
        payload
      );
      this.notifier.success(this.i18n.translate(
        'CONFIG:textSuccessfullyUpdatedEmployeeSSOFields',
        {},
        'Successfully updated employee SSO fields'
      ));

      return true;
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'CONFIG:textErrorUpdatingEmployeeSSOFields',
        {},
        'There was an error updating employee SSO fields'
      ));

      return false;
    }
  }

  setEmployeeColumnsForAutomation () {
    const columns: Automation.BaseObjectColumnType[] = this.employeeSSOFields.filter((column) => {
      return column.inUse;
    }).map((column) => {
      return {
        label: column.name || column.columnName,
        key: column.attr,
        comparison: Automation.Comparisons.TextIs
      };
    });
    this.set('employeeColumnsForAutomation', columns);
  }

  setApplicantColumnsForReporting () {
    const columns = this.employeeSSOFields.filter((column) => {
      return column.inUse;
    }).map((column) => {
      const returnObj: AdHocReportingUI.ColumnDefinition = {
        display: column.name || column.columnName,
        i18nKey: '',
        type: 'text',
        column: column.attr
      };
      if (column.type === 'date') {
        (returnObj as any).type = 'date';
        (returnObj as any).format = 'date';
      }

      return returnObj;
    });
    this.set('applicantColumnsForReporting', columns);
  }


}


