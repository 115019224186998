import { FundingSourceTypes } from '@core/typings/budget.typing';
import { BatchStatuses, PaymentStatus, PaymentSubStatus } from '@core/typings/status.typing';
import { ApplicantInKindItem, InKindAwardedItemApi } from '@features/in-kind/in-kind.typing';
import { AddressRequestStatus } from '@features/platform-admin/address-requests/address-requests.typing';
import { OrganizationEligibleForGivingStatus } from '@yourcause/common';
import { FormControlIcon } from '@yourcause/common/core-forms';
import { AddressInfo } from 'net';
import { CyclesUI } from './ui/cycles.typing';


export interface PaymentUpdateImport {
  gcPaymentId: number;
  paymentDate: Date|string;
  paymentNumber: string;
  paymentStatus: PaymentStatus;
  externalPaymentId: string;
  paymentType: PaymentType;
  statusDate: Date|string;
}

export enum ProcessingTypes {
  Client = 1,
  YourCause = 2,
  Both = 3
}

export enum PaymentType {
  Check = 1,
  ACH = 2,
  WireTransfer = 3,
  Other = 4
}

export interface Payment {
  id?: number;
  scheduledDate: string;
  statusDate?: string;
  amount: number|string;
  type?: string;
  typeId?: FundingSourceTypes;
  budget: number;
  budgetName?: string;
  fundingSource: number;
  fundingSourceName?: string;
  isUnits?: boolean;
  status?: string;
  statusId?: PaymentStatus;
  notes?: string;
  paymentDesignation?: string;
  tags: number[];
  paymentNumber?: string;
  isACH?: boolean;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number|string;
  differentThanConversion?: boolean;
  exchangeRate?: number;
  exchangeRateDateTime?: string;
  exchangeRateId?: number;
  issuedDate?: string;
  clearedDate?: string;
  createdDate?: string;
  inKindItems?: InKindAwardedItemApi[];
  substatus: PaymentSubStatus;
  reissuedForPaymentId: number;
  reissuedInPaymentId: number;
  processor?: ProcessingTypes;
  payeeOverride?: PayeeOverride;
  careOf?: string;
  organizationEligibleForGivingStatus: OrganizationEligibleForGivingStatus;
  batchName: string;
  paymentType: PaymentType;
  hidePaymentStatus: boolean;
  alternatePaymentStatusText: string;
  externalPaymentId: string;
}

export interface AwardModalPayment extends Payment {
  isOverAllocation?: boolean;
}

export interface CreatePaymentResponse {
  paymentId: number;
  applicationId: number;
  awardId: number;
  scheduledDate: string;
  budgetId: number;
  fundingSourceId: number;
  fundingSourceType: FundingSourceTypes;
  totalAmount: number;
  totalUnits: number;
  notes: string;
  paymentDesignation: string;
  currencyRequested: string;
  currencyExchangeRate: number;
  totalAmountEquivalent: number;
  differentThanConversion: boolean;
  automaticallyRouted: boolean;
}

export interface PaymentFromApi {
  paymentId: number;
  awardId: number;
  budgetId: number;
  budgetName: string;
  fundingSourceId: number;
  fundingSourceName: string;
  fundingSourceType: FundingSourceTypes;
  scheduledDate: string;
  totalAmount: number;
  totalUnits?: number;
  canAdjustPayment: boolean;
  notes: string;
  status: PaymentStatus;
  statusDate?: string;
  payeeOverrideInfo?: PayeeOverride;
  paymentTags?: number[];
  paymentDesignation?: string;
  paymentNumber?: string;
  isACH?: boolean;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  differentThanConversion?: boolean;
  exchangeRate?: number;
  exchangeRateDateTime?: string;
  exchangeRateId?: number;
  clearedDate?: string;
  createdDate?: string;
  issuedDate?: string;
  inKindItems: InKindAwardedItemApi[];
  substatus: PaymentSubStatus;
  reissuedForPaymentId: number;
  reissuedInPaymentId: number;
  batchName: string;
  paymentType: PaymentType;
  paymentProcessingType?: ProcessingTypes;
  externalPaymentId: string;
}

export interface PaymentForApi {
  scheduledDate: string;
  budgetId: number;
  fundingSourceId: number;
  fundingSourceType: number;
  totalAmount: number;
  notes: string;
  paymentDesignation: string;
  id?: number;
  currencyExchangeRate: number;
  totalAmountEquivalent: number;
  differentThanConversion: boolean;
  currencyRequested: string;
  inKindPaymentItemsRequested: InKindAwardedItemApi[];
}

export interface AdjustPayment {
  scheduledDate: string;
  value: number;
  notes: string;
  paymentDesignation: string;
  valueEquivalent: number;
  currencyRequested: string;
  currencyExchangeRate: number;
  differentThanConversion: boolean;
  careOf: string;
  inKindPaymentItemsRequested: InKindAwardedItemApi[];
  budgetId: number;
  fundingSourceId: number;
}

export interface MyAwardPayment {
  paymentId: number;
  applicationId: number;
  awardId: number;
  fundingSourceType: number;
  totalAmount: number;
  totalUnits: number;
  scheduledDate: string;
  status: PaymentStatus;
  statusDate: string;
  budgetName?: string;
  fundingSourceName?: string;
  paymentNumber?: string;
  isACH?: boolean;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  inKindItems: InKindAwardedItemApi[];
  substatus: PaymentSubStatus;
  reissuedForPaymentId: number;
  reissuedInPaymentId: number;
  alternatePaymentStatus: boolean;
  alternatePaymentStatusText: string;
  paymentType: PaymentType;
  externalPaymentId: string;
}

export interface BatchItemFromApi {
  batchId: number;
  name: string;
  notes: string;
  processingTypeId: ProcessingTypes;
  batchStatusId: BatchStatuses;
  clientName: string;
  batchStatusLastUpdatedBy: StatusLastUpdatedBy;
  totalPaymentsInBatch: number;
  totalPaymentsAmount: number;
  batchStatusLastUpdatedDate: string;
  externalBatchId: string;
  grantProgramIds: number[];
}

export interface StatusLastUpdatedBy {
  firstName: string;
  lastName: string;
  email: string;
  userType: number;
  statusLastUpdatedDate: string;
  impersonatedBy: string;
}

export interface BatchItem {
  id: number;
  name: string;
  processorName: string;
  processorType: ProcessingTypes;
  paymentDates: string;
  batchDetails?: {
    statusDetailText?: string;
    totalPaymentsNumber?: number;
    totalPaymentsAmount?: number;
  };
  statusId?: number;
  availableDetails?: {
    includedPayments?: PaymentsBreakdown;
    excludedPayments?: PaymentsBreakdown;
  };
  notes?: string;
  footerMessage?: string;
  amount?: number | string;
  isInKind?: boolean;
  program?: string;
  availableDate?: string;
  issueDate?: string;
  clearedDate?: string;
  paymentStatusId?: PaymentStatus;
  externalBatchId?: string;
  grantProgramIds?: number[];
  paymentSubStatusId?: PaymentSubStatus;
}

export interface PaymentStatsFromApi {
  excludedPaymentsAmount: number;
  excludedPaymentsNumber: number;
  includedPaymentsAmount: number;
  includedPaymentsNumber: number;
  paymentEndDate: string;
  paymentStartDate: string;
  processorName: string;
  totalPaymentsAmount: number;
  totalPaymentsNumber: number;
}

export interface PaymentsBreakdown {
  number: number;
  total: number;
}

export interface CreateOpenBatchModalResponse {
  name: string;
  notes: string;
  externalBatchId?: string;
}

export interface SendToProcessingModalResponse {
  name: string;
  notes: string;
  paymentStatus: PaymentStatus;
  issueDate: Date;
}

export interface EditBatchModalResponse {
  name: string;
  notes: string;
  externalBatchId?: string;
}

export interface PaymentForProcess {
  paymentId: number;
  onHold: boolean;
  careOf: string;
  programName: string;
  statusId: number;
  batchInfo: {
    batchId: number;
    name: string;
    notes: string;
    processingTypeId: ProcessingTypes;
    batchStatusId: BatchStatuses;
    clientName: string;
  };
  statusLastUpdatedBy: StatusLastUpdatedBy;
  statusDate: string;
  fundingSourceName: string;
  budgetName: string;
  isACH: boolean;
  paymentNumber: string;
  programId: number;
  payeeOverrideInfo: PayeeOverride;
  paymentDesignation: string;
  organizationInfo: OrganizationInfo;
  applicantInfo: {
    applicantId: number;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    address: string;
    address2: string;
    country: string;
    state: string;
    city: string;
    postalCode: string;
    phoneNumber: string;
  };
  paymentProcessingType: ProcessingTypes;
  applicationId: number;
  awardId: number;
  scheduledDate: string;
  budgetId: number;
  fundingSourceId: number;
  fundingSourceType: FundingSourceTypes;
  totalAmount: number;
  totalUnits: number;
  notes: string;
  missingPayeeInfo: boolean;
  insightsInfo: {
    applicantId: number;
    organizationId: number;
    organizationGuid: string;
    inCurrentWorkflowLevel: boolean;
    isInternational: boolean;
  };
  currencyExchangeRate: number;
  currencyRequested: string;
  totalAmountEquivalent: number;
  differentThanConversion: boolean;
  clearedDate: string;
  issuedDate: string;
  inKindItems: ApplicantInKindItem[];
  isApplicationArchived: boolean;
  isProgramArchived: boolean;
  canViewApplication?: boolean;
  accountNumber: string;
  grantProgramCycle: CyclesUI.ProgramCycle;
  isMasked: boolean;
  canViewMaskedApplicantInfo: boolean;
  workflowId: number;
  workflowLevelId: number;
  substatus: PaymentSubStatus;
  reissuedForPaymentId: number;
  reissuedInPaymentId: number;
  addressRequestInvalid: boolean;
  notEligibleForGiving: boolean;
  createdBy: string;
  applicationApprovedBy: string;
  paymentType: PaymentType;
  isApplicationObfuscated: boolean;
}

export interface ApplicantInfo {
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  address2: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  phoneNumber: string;
}

export interface OrganizationInfo {
  name: string;
  address: string;
  address2: string;
  country: string;
  state: string;
  city: string;
  postalCode: string;
  phoneNumber: string;
  charityId: number;
  identification: string;
  imageUrl: string;
  isPrivateOrg?: boolean;
  organizationId: number;
  nonprofitGuid: string;
  eligibleForGivingStatus: OrganizationEligibleForGivingStatus;
  parentOrganizationName: string;
  parentOrganizationGuid: string;
}


export interface PayeeOverride {
  id?: number;
  name?: string;
  notes?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  applicationId?: number;
  paymentDesignation?: string;
  paymentId: number;
  reason?: string;
  fileUrl?: string;
  addressRequestStatus?: AddressRequestStatus;
}

export interface PaymentDesignation {
  paymentDesignation: string;
}

export interface AddBatchForApi {
  name: string;
  notes: string;
  processingTypeId: ProcessingTypes;
  externalBatchId?: string;
}

export interface UpdateBatchForApi {
  name: string;
  batchId: number;
  notes: string;
  externalBatchId?: string;
}

export interface ExpeditePaymentForApi {
  paymentId: number;
  batchName: string;
  batchNotes: string;
  batchProcessingTypeId: ProcessingTypes;
}

export interface SimplePaymentStats {
  includedPaymentsAmount: number;
  includedPaymentsNumber: number;
  endDate: string;
  startDate: string;
}

export interface PaymentNotes {
  paymentNotes: string;
  isOnHold: boolean;
  onHoldNotes: string;
  onHoldSetDate: string;
  onHoldSetByInfo: SetByInfo;
  isExpedited: boolean;
  expeditedNotes: string;
  expeditedSetDate: string;
  expeditedSetByInfo: SetByInfo;
  batchNotes: string;
  batchNotesSetByInfo: SetByInfo;
  batchNotesSetDate: string;
  paymentNotesSetByInfo: SetByInfo;
  paymentNotesSetDate: string;
  batchStatusUpdateNotes: string;
  batchStatusUpdateSetDate: string;
  batchStatusUpdateSetByInfo: SetByInfo;
}

export interface NotesDetail {
  notes: string;
  date: string;
  setBy: SetByInfo;
  type: 'payment'|'hold'|'expedite'|'batch'|'batchStatus';
  title: string;
}

export interface SetByInfo {
  firstName: string;
  lastName: string;
  email: string;
  userType: number;
  impersonatedBy?: string;
}

export interface ProcessAllAvailableApi {
  batchName: string;
  batchNotes: string;
  processingType: ProcessingTypes;
}

export interface UpdatePaymentsApi {
  paymentIds: number[];
  checkNumber: string;
  status: PaymentStatus;
  notes: string;
  issuedDate: string;
  clearedDate: string;
  accountNumber: string;
  paymentType: PaymentType;
  statusDate: string;
}

export interface UpdatePaymentsModalResponse {
  checkNumber: string;
  status: PaymentStatus;
  notes: string;
  issuedDate: string;
  clearedDate: string;
  accountNumber: string;
  paymentType: PaymentType;
  statusDate: string;
}

export interface PaymentForOrg {
  applicationId: number;
  awardId: number;
  scheduledDate: string;
  budgetId: number;
  budgetName: string;
  fundingSourceId: number;
  fundingSourceType: FundingSourceTypes;
  totalAmount: number;
  totalUnits: number;
  notes: string;
  paymentDesignation: string;
  currencyRequested: string;
  totalAmountEquivalent: number;
  differentThanConversion: boolean;
}

export interface PaymentForApplicant {
  paymentId: number;
  applicationId: number;
  awardId: number;
  budgetId: number;
  budgetName: string;
  fundingSourceId: number;
  fundingSourceName: string;
  fundingSourceType: FundingSourceTypes;
  totalAmount: number;
  totalUnits: number;
  scheduledDate: string;
  status: PaymentStatus;
  notes: string;
  statusDate: string;
  payeeOverrideInfo: PayeeOverride;
  paymentTags: number[];
  createDate: string;
  paymentDesignation: string;
  currencyRequested: string;
  currencyRequestedAmountEquivalent: number;
  paymentNumber: string;
  isACH: boolean;
}

export interface PaymentTopLevelStats {
  numberOfApplications: number;
  numberOfAwards: number;
  numberOfPayments: number;
  awardsTotal: number;
  paymentsTotal: number;
}

export interface PaymentDisplayInfo {
  amountLabel: string;
  amountHelp: string;
  canEdit: boolean;
  isEditing: boolean;
  currencyKey: string;
  currencySymbol: string;
  showConversion: boolean;
  isUnits: boolean;
  editIcons: FormControlIcon[];
  saveIcons: FormControlIcon[];
}


export interface InKindPaymentStats {
  pendingPaymentsCount: number;
  pendingUnitsCount: number;
  amountOfPendingUnits: number;
  fulfilledPaymentsCount: number;
  fulfilledUnitsCount: number;
  amountOfFulfilledUnits: number;
}

export interface UpdatePaymentSpecialHandling {
  paymentId: number;
  specialHandlingName: string;
  specialHandlingAddress1: string;
  specialHandlingAddress2: string;
  specialHandlingCountry: string;
  specialHandlingCity: string;
  specialHandlingStateProvinceRegion: string;
  specialHandlingPostalCode: string;
  specialHandlingNotes: string;
  specialHandlingReason: string;
  specialHandlingFileUrl: string;
  fileUploadId: number;
}

export interface PaymentSummaryResponse {
  outstandingPriorYear: number;
  newDisbursementsThisPeriod: number;
  clearedItems: number;
  voidedItems: number;
  outstandingEndOfPeriod: number;
}

export interface DisbursementPayload {
  startDate: Date;
  endDate: Date;
  programIds: number[];
}

export interface DisbursementReportRow {
  isACH: boolean;
  checkNumber: string;
  totalAmount: number;
  recipientFullName: string;
  programName: string;
  applicationId: number;
  paymentStatus: PaymentStatus;
  statusDate: string;
  issuedDate: string;
  organizationGuid: string;
  applicantId: number;
  applicantEmail: string;
  applicantPhoneNumber: string;
  organizationAddress: AddressInfo;
  priorPaymentAmount: number;
  currentPaymentAmount: number;
  clearedAmount: number;
  voidedAmount: number;
  currentOutstandingAmount: number;
  paymentType: PaymentType;
  processorType: ProcessingTypes;
}

export interface CanRecallPaymentPayload {
  processingType: ProcessingTypes;
  batchStatus: BatchStatuses;
  paymentStatus: PaymentStatus;
  paymentInBatch: boolean;
}

export interface RecallPaymentPayload {
  paymentId: number;
  reasonForRecall: string;
}
