import { APIAdminClient } from '@core/typings/api/admin-client.typing';
import { PaginatedResponse } from '@yourcause/common';
import { WorkflowLevelPermissions } from './workflow.typing';

export const User_Table_Key = 'USERS';

export enum UserTypes {
  MANAGER = 1,
  APPLICANT = 2,
  ADMIN = 3
}

export enum UserAccessTypes {
  Default = 0,
  Reviewer = 1
}

export interface User {
  firstName: string;
  lastName: string;
  active: boolean;
  culture: string;
  email: string;
  id: number;
  userId: number;
  isNewUser: boolean;
  isRootUser: boolean;
  jobTitle: string;
  roles: UserRole[];
  workflows: {
    id: number;
    name: string;
    description: string;
  }[];
  workFlowLevels: UserWorkflow[];
  acceptedTermsOfService: boolean;
  clientFeatures: APIAdminClient.ClientFeatureTypes[];
  isInNominationWorkFlow: boolean;
  isIntegratedWithCsrZone: boolean;
  affiliateId?: number;
  hasReports?: boolean;
  workflowManager?: boolean;
  isSso: boolean;
  hasGenAIEntitlement: boolean;
  accessType: UserAccessTypes;
}

export interface UserRole {
  policies: {
    clientRoleId: number;
    allow: boolean;
    permissionSetType: number;
    permissionType: number;
  }[];
  clientRoleId: number;
  clientRoleName: string;
  clientRoleDescription: string;
}

export interface UserWorkflow {
  id: number;
  name: string;
  description: string;
  workflow: {
    id: number;
    name: string;
    description: string;
  };
  clientId: number;
}

export interface UserInfo {
  firstName: string;
  lastName: string;
  email: string;
  userType: number;
  impersonatedBy: string;
  fullName?: string;
}

export interface UpdateUserProfile {
  firstName: string;
  lastName: string;
  culture?: string;
}

export interface SimpleUser {
  fullName: string;
  email: string;
  isRootUser: boolean;
  active: boolean;
  requirePasswordReset: boolean;
  id: number;
  roles: any[];
  acceptedTermsOfService: boolean;
  isInNominationWorkFlow: boolean;
  isIntegratedWithCsrZone: boolean;
  isDeactivated: boolean;
  userId: number;
  isSSO: boolean;
  isBbidLinked: boolean;
  accessType: UserAccessTypes;
}

export interface UserFromApi {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  isRootUser: boolean;
  active: boolean;
  isNewUser: boolean;
  id: number;
  culture: string;
  roles: UserRole[];
  acceptedTermsOfService: boolean;
  dateAcceptedTermsOfService: string;
  fullName?: string;
  isDeactivated: boolean;
  userId: number;
  clientHasNominations: boolean;
  isInNominationWorkFlow: boolean;
  isIntegratedWithCsrZone: boolean;
  workflows: BasicUserWorkflow[];
  workFlowLevels: BasicUserWorkflowLevel[];
  ssoId: string;
  isSSO: boolean;
  isBbidLinked: boolean;
  audienceIds: number[];
  accessType: UserAccessTypes;
}

export interface UserForUi extends UserFromApi {
  isCurrentUser: boolean;
}

export interface BasicUserWorkflow {
  id: number;
  name: string;
  description: string;
  workflowManager: boolean;
}

export interface BasicUserWorkflowLevel {
  id: number;
  name: string;
  description: string;
  workflow: BasicUserWorkflow;
  clientId: number;
  workflowLevelUserAccessType: WorkflowLevelPermissions;
}

export interface UsersResponseFromApi {
  users: UserFromApi[];
  usersAddedToAccount: number;
  usersWaitingForRegistration: number;
  activeUsers: number;
  inActiveUsers: number;
}

export interface UserStats {
  usersAddedToAccount: number;
  usersWaitingForRegistration: number;
  activeUsers: number;
  inActiveUsers: number;
}

export interface AddEditUser {
  firstName: string;
  lastName: string;
  jobTitle: string;
  email: string;
  accessType?: UserAccessTypes;
  isSSO?: boolean;
  id?: number;
}

export interface UserDragAndDrop {
  display: string;
  fullName: string;
  email: string;
}

export interface PaginatedUsersResponse {
  usersAddedToAccount: number;
  usersWaitingForRegistration: number;
  activeUsers: number;
  inActiveUsers: number;
  users: PaginatedResponse<UserFromApi>;
}


export interface ClientAffiliateInfo {
  id: number;
  name: string;
  corpName: string;
  supportEmail: string;
}

