import { Injectable } from '@angular/core';
import { LocationService } from '@core/services/location.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { SpinnerService } from '@core/services/spinner.service';
import { TokenService } from '@core/services/token/token.service';
import { ContactSupport } from '@core/typings/ui/support.typing';
import { UserService } from '@features/users/user.service';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { HeapService, TrackingPropertyNames, TrackingEventNames } from '@yourcause/common/heap';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { ModalFactory } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { ContactSupportModalComponent } from './contact-support-modal/contact-support-modal.component';
import { SupportResources } from './support.resources';
import { SupportState } from './support.state';

@AttachYCState(SupportState)
@Injectable({ providedIn: 'root'})
export class SupportService extends BaseYCService<SupportState> {
  readonly contactReasonPreAuthOptions: TypeaheadSelectOption<ContactSupport.ContactReasonFullList>[] = [
    {
      label: this.i18n.translate('common:textUpdateMyEmail', {}, 'Update my email'),
      value: ContactSupport.ContactReasonFullList.UPDATE_EMAIL
    },
    {
      label: this.i18n.translate('common:textChangeMyPassword', {}, 'Change my password'),
      value: ContactSupport.ContactReasonFullList.CHANGE_PASSWORD
    },
    {
      label: this.i18n.translate('common:textOther', {}, 'Other'),
      value: ContactSupport.ContactReasonFullList.OTHER
    }];
  readonly contactReasonPostAuthOptions: TypeaheadSelectOption<ContactSupport.ContactReasonFullList>[] = [
    {
      label: this.i18n.translate('common:textForms', {}, 'Forms'),
      value: ContactSupport.ContactReasonFullList.FORMS
    },
    {
      label: this.i18n.translate('common:textDataMissing', {}, 'Data missing'),
      value: ContactSupport.ContactReasonFullList.DATA_MISSING
    },
    {
      label: this.i18n.translate('common:textApplicationNomination', {}, 'Application/Nomination'),
      value: ContactSupport.ContactReasonFullList.APPLICATION_NOMINATION
    },
    {
      label: this.i18n.translate('common:textUnableToFindOrg', {}, 'Unable to find organization'),
      value: ContactSupport.ContactReasonFullList.UNABLE_TO_FIND_ORG
    },
    {
      label: this.i18n.translate('common:textProfileInformation', {}, 'Profile information'),
      value: ContactSupport.ContactReasonFullList.PROFILE_INFO
    },
    ...this.contactReasonPreAuthOptions
  ];

   constructor (
    private logger: LogService,
    private i18n: I18nService,
    private supportResources: SupportResources,
    private notifier: NotifierService,
    private tokenService: TokenService,
    private modalFactory: ModalFactory,
    private spinnerService: SpinnerService,
    private userService: UserService,
    private portal: PortalDeterminationService,
    private locationService: LocationService,
    private heapService: HeapService
  ) {
    super();
  }

  get prepopData () {
    return this.get('prepopData');
  }

  get contactReasonOptions () {
    const isLoggedIn = this.tokenService.getIsLoggedIn();
    if (isLoggedIn) {
      return this.contactReasonPostAuthOptions;
    }

    return this.contactReasonPreAuthOptions;
  }

  get liveChat () {
    return (window as any).embedded_svc;
  }

  get liveChatAvailable () {
    return this.liveChat ? !this.liveChat.isButtonDisabled : false;
  }

  setPrepopData (payload: ContactSupport.ContactSupportModalReturn) {
    this.set('prepopData', payload);
  }

  adaptPayloadForEmail (payload: ContactSupport.ContactSupportModalReturn) {
    // grab contact reason text
    const contactReason = this.contactReasonOptions.find((option) => {
      return option.value === payload.contactReason;
    });
    // build simple data string for email
    let payloadString = '';
    payloadString += `Contact reason: ${contactReason.label}\n`;
    payloadString += `Name: ${payload.firstName} ${payload.lastName}\n`;
    payloadString += `Email: ${payload.email}\n`;
    payloadString += `Phone number: ${payload.phoneNumber}\n`;
    payloadString += `Client name: ${payload.clientName}\n`;
    payloadString += `Application ID: ${payload.applicationId}\n`;
    payloadString += `Program name: ${payload.programName}\n`;
    payloadString += `Organization name: ${payload.organizationName}\n`;
    payloadString += `Organization tax ID: ${payload.taxId}\n`;
    payloadString += `Description: ${payload.description}\n`;

    return payloadString;
  }

  async sendSupportEmail (payload: ContactSupport.ContactSupportModalReturn) {
    try {
      const emailBody = this.adaptPayloadForEmail(payload);
      await this.supportResources.sendSupportEmail(emailBody);
      this.notifier.success(this.i18n.translate(
        'common:textSupportTicketSuccessfullySubmitted',
        {},
        'Support ticket successfully submitted'
      ));
      
      // track event
      this.heapService.track(
        TrackingEventNames.SubmitForm,
        {
          [TrackingPropertyNames.Success]: String(true)
        }
      );
    } catch (e) {
      this.notifier.error(this.i18n.translate(
        'common:textErrorSubmittingTicket',
        {},
        'There was an error submitting your support ticket'
      ));
      this.logger.error(e);
      
      // track event
      this.heapService.track(
        TrackingEventNames.SubmitForm,
        {
          [TrackingPropertyNames.Success]: String(false)
        }
      );
    }
  }

  trackClickAndOpenLink (eventName: string, link: string) {
    this.heapService.track(
      eventName,
      {
        [TrackingPropertyNames.Link]: link
      }
    );

    window.open(link, '_blank');
  }

  trackClick (eventName: string, link: string) {
    this.heapService.track(
      eventName,
      {
        [TrackingPropertyNames.Link]: link
      }
    );
  }

  async openSupportEmailModal () {
    // track event
    this.heapService.track(
      TrackingEventNames.ContactSupport,
      {}
    );
    const modalResponse = await this.modalFactory.open(
      ContactSupportModalComponent,
      {
        contactReasonPostAuthOptions: this.contactReasonOptions,
        prepopData: {
          ...this.prepopData,
          contactReason: null,
          firstName: this.userService.currentUser?.firstName,
          lastName: this.userService.currentUser?.lastName,
          email: this.userService.currentUser?.email,
          phoneNumber: this.userService.applicant?.phoneNumber ?? '',
          description: ''
        }
      }
    );
    if (modalResponse) {
      this.spinnerService.startSpinner();
      await this.sendSupportEmail(modalResponse);
      this.spinnerService.stopSpinner();
    }
  }

  openLiveChat () {
    const eles = document.getElementsByClassName('embeddedServiceHelpButton');
    const chat = eles.item(0);
    if (chat) {
      chat.classList.add('make-visible');
      const buttons = document.getElementsByClassName('helpButtonEnabled');
      if (buttons.length > 0) {
        // track event
        this.heapService.track(
          TrackingEventNames.LiveChat,
          {}
        );
        const button = buttons[0] as HTMLButtonElement;
        button.click();
      }
    }
  }

  getHelpCenterLink () {
    return this.portal.isManager ?
      'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/home.html' :
      'https://webfiles.blackbaud.com/files/support/helpfiles/grantsconnect/content/gc-applicants.html';
  }

  openHelpCenter () {
    const link = this.getHelpCenterLink();
    this.locationService.openExternalUrl(link);
  }
}

