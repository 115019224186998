import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthBehaviors } from '@core/services/token/token-behaviors';
import { TokenStorageService } from '@core/services/token/token-storage.service';
import { TokenResponse } from '@core/typings/token.typing';
import { environment } from '@environment';
import { PaginationOptions } from '@yourcause/common';
import { I18nService } from '@yourcause/common/i18n';
import { LogService } from '@yourcause/common/logging';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { GuidService } from '@yourcause/common/utils';
import { ImpersonationResources } from './impersonation.resources';
import { ImpersonationState } from './impersonation.state';
import { ImpersonationLogRecord } from './impersonation.typing';


@AttachYCState(ImpersonationState)
@Injectable({ providedIn: 'root' })
export class ImpersonationService extends BaseYCService<ImpersonationState> {

  private poll: NodeJS.Timer|number;

   constructor (
    private logger: LogService,
    private impersonationResources: ImpersonationResources,
    private i18n: I18nService,
    private notifier: NotifierService,
    private tokenStorageService: TokenStorageService,
    private authBehaviors: AuthBehaviors,
    private router: Router,
    private guidService: GuidService
  ) {
    super();
  }

  get impersonationSessionId () {
    return localStorage.getItem(
      this.impersonationSessionIdAttr
    );
  }

  get terminatedImpersonationSessionId () {
    return localStorage.getItem(
      this.terminatedImpersonationSessionIdAttr
    );
  }

  get impersonationSessionIdAttr () {
    return this.authBehaviors.current.impersonationSessionId;
  }

  get terminatedImpersonationSessionIdAttr () {
    return this.authBehaviors.current.terminatedImpersonationSessionId;
  }

  getImpersonationHistory (options: PaginationOptions<ImpersonationLogRecord>) {
    return this.impersonationResources.getImpersonationHistory(options);
  }

  async handleImpersonateUser (
    userId: number,
    subdomain: string,
    clientId: number
  ): Promise<string> {
    try {
      const response = await this.impersonationResources.impersonateUser(
        userId,
        clientId,
        this.tokenStorageService.clientIdentifier
      );

      return this.generateUrlForImpersonation(response, subdomain, clientId);
    } catch (e) {
      this.logger.error(e);
      this.notifier.error(this.i18n.translate(
        'ADMIN:textErrorImpersonatingUser',
        {},
        'There was an error impersonating the user'
      ));

      return '';
    }
  }

  generateUrlForImpersonation (
    response: TokenResponse,
    subdomain: string,
    clientId: number
  ) {
    let base = `${environment.actualLocationBase}${+location.port > 443 ? `:${location.port}` : ''}`;
    if (subdomain && environment.supportsSubdomains) {
      base = `${subdomain}.${base}`;
    } else if (!environment.isLocalhost && !base.startsWith('www')) {
      base = `www.${base}`;
    }
    const urlExtras = `response=${btoa(JSON.stringify(response))}&clientIdentifier=${this.tokenStorageService.clientIdentifier}&clientId=${clientId}`;
    const url = `/management/impersonation?${urlExtras}`;

    return 'https://' + base + url;
  }


  async logoutExistingUserBeforeImpersonation () {
    this.handleEndImpersonationSession();
  }

  setImpersonationSessionId () {
    localStorage.setItem(
      this.impersonationSessionIdAttr,
      this.guidService.short()
    );
  }

  clearImpersonationSessionId () {
    localStorage.removeItem(
      this.impersonationSessionIdAttr
    );
    localStorage.removeItem(
      this.terminatedImpersonationSessionIdAttr
    );
  }

  handleEndImpersonationSession () {
    // Set Terminated Session to Current session for setInterval to catch
    // on all tabs and finish ending the session
    localStorage.setItem(
      this.terminatedImpersonationSessionIdAttr,
      this.impersonationSessionId
    );
  }

  finishEndImpersonationSession () {
    this.clearImpersonationSessionId();
    window.close();
    // If close does not work because we did not open the tab
    // send them to landing page
    this.router.navigateByUrl(
      '/management/impersonation-session-ended'
    );
  }

  startPollingEndedImpersonationSession () {
    this.poll = setInterval(() => {
      const currentSession = this.impersonationSessionId;
      const endedSession = this.terminatedImpersonationSessionId;
      if (currentSession === endedSession) {
        this.finishEndImpersonationSession();
      }
    }, 500);
  }

  stopPollingEndedImpersonationSession () {
    if (this.poll) {
      clearInterval(this.poll as number);
    }
  }
}
