import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { SpinnerService } from '@core/services/spinner.service';
import { TranslationService } from '@core/services/translation.service';
import { BaseApplication } from '@core/typings/application.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { UIExternalAPI } from '@core/typings/ui/external-api.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { BaseApplicationForLogic, ComponentTabIndexMap, FormChangesWithCompKey, FormDefinitionComponent, FormDefinitionForUi, FormTab, FormTranslations, FormTypes, ValueLogicResult } from '@features/configure-forms/form.typing';
import { FormsService } from '@features/configure-forms/services/forms/forms.service';
import { EmployeeSSOFieldsService } from '@features/employee-sso-fields/employee-sso-fields.service';
import { ExternalAPIService } from '@features/external-api/external-api.service';
import { FormFieldHelperService } from '@features/form-fields/services/form-field-helper.service';
import { ExternalAPISelection } from '@features/forms/component-configuration/external-api-selector-settings/external-api-selector-settings.component';
import { FormBuilderActionEvent, FormBuilderActions, FormBuilderDropEvent } from '@features/forms/form-builder/form-builder.typing';
import { FormBuilderService } from '@features/forms/form-builder/services/form-builder/form-builder.service';
import { EvaluationFn } from '@features/forms/form-logic-evaluator.typing';
import { ComponentHelperService } from '@features/forms/services/component-helper/component-helper.service';
import { FormHelperService } from '@features/forms/services/form-helper/form-helper.service';
import { FormLogicService } from '@features/forms/services/form-logic/form-logic.service';
import { FormValidationService } from '@features/forms/services/form-validation/form-validation.service';
import { ReportFieldService } from '@features/forms/services/report-field/report-field.service';
import { FormulaBuilderService } from '@features/formula-builder/formula-builder.service';
import { FormulaState } from '@features/formula-builder/formula-builder.typing';
import { LogicBuilderService } from '@features/logic-builder/logic-builder.service';
import { ListLogicState, LogicColumn, LogicState, NestedPropColumn } from '@features/logic-builder/logic-builder.typing';
import { PrepareAdHocReportingService } from '@features/reporting/services/prepare-ad-hoc-reporting.service';
import { SignatureService } from '@features/signature/signature.service';
import { Tab, TypeToken } from '@yourcause/common';
import { TypeSafeFormGroup } from '@yourcause/common/core-forms';
import { DomService } from '@yourcause/common/dom';
import { YcFile } from '@yourcause/common/files';
import { I18nService } from '@yourcause/common/i18n';
import { ModalFactory } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { SignatureModalComponent, SignatureModalResponse } from '@yourcause/common/signature';
import { ArrayHelpersService, YCTwoWayEmitter } from '@yourcause/common/utils';
import { isUndefined } from 'lodash';
import { Subscription } from 'rxjs';

/**
 * TODO: correctly associate drop list directives
 *
 * Thoughts:
 *
 * fetch from and use from parent instance
 *
 * flow: assign to parent, pass back into directive in each layout comp. not sure why they aren't connected to themselves
 */

@Component({
  selector: 'gc-form-renderer',
  templateUrl: './form-renderer.component.html',
  styleUrls: ['./form-renderer.component.scss']
})
export class FormRendererComponent<T extends BaseApplication> implements OnInit, OnChanges, OnDestroy {
  @Input() form: FormDefinitionForUi[];
  @Input() defaultFormLang: string;
  @Input() applicationId: number;
  @Input() applicationFormId: number;
  @Input() formType: FormTypes;
  @Input() programId: string|number;
  @Input() formId: number;
  @Input() hideSubmitButton = false;
  @Input() disableSubmitButton = false;
  @Input() requireSignature: boolean;
  @Input() signatureDescription: string;
  @Input() supportsBypassSignature: boolean;
  @Input() formRevisionId: number;
  @Input() isManagerEditingApplicantForm = false;
  @Input() masked = true;
  @Input() externalFields: Partial<T>;
  @Input() notAutoSave: boolean;
  @Input() scrollBoxClass: string;
  @Input() triggerSubmit: YCTwoWayEmitter<Promise<boolean>>;
  @Input() showTabs = true;
  @Input() isFormBuilderView: boolean;
  @Input() set readOnly (value: boolean) {
    this._readOnly = value;
  }
  get readOnly (): boolean {
    return this._readOnly;
  }
  @Input() editable: boolean;

  @Input() tabIndex = 0;
  @Input() getSavedSignature: () => Promise<YcFile>;
  @Input() orgId: number;
  @Input() refIdsChanged: number[];
  @Input() standardFieldsChanged: ReferenceFieldsUI.StandardFieldTypes[];
  @Output() onSubmit = new EventEmitter();
  @Output() onChange = new EventEmitter<FormChangesWithCompKey>();
  @Output() onConditionalVisibilityStateChanged = new EventEmitter<
    LogicState<BaseApplicationForLogic, boolean>
  >();
  @Output() formChange = new EventEmitter<FormDefinitionForUi[]>();
  @Output() dropListsChanged = new EventEmitter<CdkDropList[]>();
  @Output() componentActionClick = new EventEmitter<FormBuilderActionEvent>();
  @Output() onTranslationsReady = new EventEmitter<FormTranslations>();
  @Output() onTabIndexChangedForGoToComp = new EventEmitter<number>();
  @Output() onHandleComponentAction = new EventEmitter<FormBuilderActionEvent>();
  @Output() onUpdateTabIndex = new EventEmitter<number>();

  conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>;
  validityState: LogicState<BaseApplicationForLogic, boolean>;
  setValueState: ListLogicState<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>;
  formulaState: FormulaState<BaseApplicationForLogic>;
  hiddenCompKeys: string[] = [];
  private _readOnly: boolean;
  translations: Record<string, string> = {};
  richTextTranslations: Record<string, string> = {};
  isAfterInit = false;
  reportFieldResponse: AdHocReportingUI.ReportFieldResponseRow;
  sub = new Subscription();
  tabs: FormTab[] = [];
  firstVisibleTabIndex: number;
  lastVisibleTabIndex: number;
  submitting = false;
  parentFields: Partial<BaseApplication>;
  $tabType = new TypeToken<Tab[]>();
  // This form group map will store form groups for each tab. Will be used to track validation
  formGroupMap: Record<number, TypeSafeFormGroup<Record<string, any>>> = {};
  componentTabIndexMap: ComponentTabIndexMap = {}; // Component Key -> Tab Index
  compKeyToComponent: Record<string, FormDefinitionComponent> = {};
  labelMap: Record<string, FormDefinitionComponent> = {};
  dropLists: Record<number, CdkDropList[]> = {};
  isManagerForm: boolean;
  customJSExecutions: EvaluationFn[] = [];
  jsExecutionCount = 0;
  totalNumberOfFieldsOnForm = 0;
  totalNumberOfFieldsChanged = 0;
  inFormBuilder = this.formBuilderService.inFormBuilder;
  loading = false;

  constructor (
    private formBuilderService: FormBuilderService,
    private formulaBuilderService: FormulaBuilderService,
    private arrayHelper: ArrayHelpersService,
    private logicBuilderService: LogicBuilderService,
    private externalAPIService: ExternalAPIService,
    private translationService: TranslationService,
    private formService: FormsService,
    private portal: PortalDeterminationService,
    private reportFieldService: ReportFieldService,
    private formHelperService: FormHelperService,
    private formFieldHelperService: FormFieldHelperService,
    private notifierService: NotifierService,
    private domService: DomService,
    private i18n: I18nService,
    private signatureService: SignatureService,
    private modalFactory: ModalFactory,
    private componentHelper: ComponentHelperService,
    private formLogicService: FormLogicService,
    private spinnerService: SpinnerService,
    private employeeSsoService: EmployeeSSOFieldsService,
    private formValidationService: FormValidationService,
    private prepareAdHocService: PrepareAdHocReportingService
  ) {
    this.sub.add(this.formBuilderService.changesTo$('componentToViewOrEdit').subscribe(() => {
      const component = this.formBuilderService.componentToViewOrEdit;
      if (!!component) {
        this.beginViewOrEditComponent(component.compKey);
      }
    }));
    this.sub.add(this.formBuilderService.changesTo$('formTabWasUpdatedForGoToComp').subscribe((wasUpdated) => {
      if (wasUpdated) {
        this.finishViewOrEditComponent();
      }
    }));
  }

  get currentFormDefinition () {
    return this.form[this.tabIndex];
  }

  get allTabsValid () {
    return this.tabs.every((tab) => tab.valid);
  }

  async ngOnInit () {
    this.registerTriggerSubmit();
    if (!!this.form) {
      await this.fetchFormDependencies();
      this.prepFormHelpers();
      this.initLogic();
    }
    this.isAfterInit = true;
  }

  ngOnChanges (changes: SimpleChanges) {
    if (this.isAfterInit) {
      if (changes.form) {
        this.prepFormHelpers();
      }
      if (changes.externalFields) {
        this.assignParentFields();
      }
      if (changes.orgId) {
        // only pass in org id at apply page or forms tab
        this.setReportFieldResponse();
      }
    }
  }

  prepFormHelpers () {
    this.isManagerForm = this.portal.isManager ?
      this.formService.isManagerForm(this.formId) :
      false;
    const reportFieldColumns = this.reportFieldService.getReportFieldColumns(
      this.form,
      this.formType === FormTypes.NOMINATION
    );
    this.formLogicService.setReportFieldLogicColumns(reportFieldColumns);
    this.setHelperMaps();
    this.assignParentFields();
    this.componentHelper.trimFromDefinitionData(this.form);
    this.form = this.adaptFormDefinition(this.form);
    this.formLogicService.setLogicComponentsMap(this.form);
    this.setTabsAndFormGroupMap();
    this.determineFirstAndLastVisibleTabs();
    this.updateBadgeNumberOnTabs();
    this.replaceRichText();
    this.executeServices();
  }

  beginViewOrEditComponent (compKey: string) {
    // Find the tab the component lives on and switch to it
    // We then have to wait for the dom to update to the new tab before proceeding
    let foundIndex: number;
    this.form.forEach((tab, index) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        if (comp.key === compKey) {
          foundIndex = index;
        }
      });
    });
    if (foundIndex > -1) {
      if (foundIndex === this.tabIndex) {
        this.finishViewOrEditComponent();
      } else {
        this.onTabIndexChangedForGoToComp.emit(foundIndex);
      }
    }
  }

  finishViewOrEditComponent () {
    const comp = this.formBuilderService.componentToViewOrEdit;
    const compClass = `apiKey_${comp.compKey}`;
    const componentElement = document.getElementsByClassName(compClass)[0];
    if (componentElement) {
      componentElement.scrollIntoView();
      this.formBuilderService.setComponentToViewOrEdit(null);
      this.formBuilderService.setFormTabWasUpdatedForGoToComp(false);
    }
    if (comp.isEdit) {
      setTimeout(() => {
        const {
          foundComponent
        } = this.componentHelper.findComponentByIdentifier(this.form, comp.compKey);
        this.onHandleComponentAction.emit({
          component: foundComponent,
          action: FormBuilderActions.Edit_Component
        });
      });
    }
  }

  setHelperMaps () {
    const labelMap: Record<string, FormDefinitionComponent> = {};
    const maps = this.formBuilderService.getComponentTabIndexMap(this.form);
    this.compKeyToComponent = maps.compKeyToComponent;
    this.componentTabIndexMap = maps.componentTabIndexMap;
    this.form.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        labelMap[comp.key] = comp;
      });
    });
    this.labelMap = labelMap;
  }

  async setTranslations () {
    const {
      richTextMap,
      standardMap
    } = await this.translationService.getFormTranslationsByLanguage(this.formId, this.defaultFormLang);
    this.translations = standardMap;
    this.richTextTranslations = richTextMap;
    this.onTranslationsReady.emit({
      translations: this.translations,
      richTextTranslations: this.richTextTranslations
    });
  }

  replaceRichText () {
    this.componentHelper.replaceRichText(
      this.form,
      this.richTextTranslations
    );
  }

  adaptFormDefinition (definitions: FormDefinitionForUi[]) {
    const forceDefaultCurrency = this.formHelperService.shouldForceDefaultCurrencyInAmountRequested(
      this.isManagerForm,
      this.isManagerEditingApplicantForm
    );

    return definitions.map<FormDefinitionForUi>((tab, index) => {
      this.componentHelper.eachComponent(tab.components, (component) => {
        component.value = this.formHelperService.getValueFromComponent(
          component,
          this.parentFields,
          forceDefaultCurrency
        );
      });

      tab.index = index;

      return tab;
    });
  }

  async fetchFormDependencies () {
    this.loading = true;
    await Promise.all([
      this.employeeSsoService.setEmployeeSSOFields(),
      this.prepareAdHocService.resolveAdHocDefinitions(),
      this.setTranslations(),
      this.setReportFieldResponse(),
      this.formHelperService.prepareComponentsForRenderForm(
        [this.form],
        [this.formId]
      )
    ]);
    this.loading = false;
  }

  initLogic () {
    const response = this.formLogicService.initFormDefinitionLogic(
      this.form,
      this.externalFields as BaseApplication,
      this.reportFieldResponse
    );
    this.setConditionalVisibilityState(response.conditionalVisibilityState);
    this.formValidationService.setValidationMapOnInit(this.form);
    this.setValidityState(response.validityState);
    this.setValueState = response.setValueState;
    this.formulaState = response.formulaState;
    this.emitConditionalVisibilityState();
    setTimeout(() => {
      this.applyComponentLogicResults();
    });
  }

  setConditionalVisibilityState (
    conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>
  ) {
    this.conditionalVisibilityState = conditionalVisibilityState;
    const tabsWithVisibilityChange: (Tab&{ index: number })[] = [];
    this.tabs = this.tabs.map((tab, index) => {
      const newHiddenValue = !this.getTabIsVisible(index);
      const newTab = {
        ...tab,
        hidden: newHiddenValue
      };
      const isCurrentlyHidden = tab.hidden;
      if (isCurrentlyHidden !== newHiddenValue) {
        tabsWithVisibilityChange.push({
          ...newTab,
          index
        });
      }

      return newTab;
    });
    if (tabsWithVisibilityChange.length > 0) {
      const changes = tabsWithVisibilityChange.reduce((acc, tab) => {
        const tabChanges = this.formLogicService.applyConditionalLogicResultsForTab(
          tab,
          this.formService.getAudienceFromFormType(this.formType),
          this.form[tab.index],
          this.readOnly
        );

        return [
          ...acc,
          ...tabChanges
        ];
      }, [] as FormChangesWithCompKey[]);
      this.handleChangesFromApplyComponentLogic(changes, []);
    }
  }

  updateBadgeNumberOnTabs () {
    if (this.readOnly) {
      this.totalNumberOfFieldsOnForm = 0;
      this.totalNumberOfFieldsChanged = 0;
      this.form.forEach((tab, index) => {
        let badgeNumber = 0;
        this.componentHelper.eachComponent(tab.components, (comp) => {
          if (this.isValidComp(comp.type)) {
            const hasChanges = this.formHelperService.getHasFieldChanges(
              comp.type,
              comp.isHidden,
              this.refIdsChanged,
              this.standardFieldsChanged
            );
            badgeNumber = hasChanges ? badgeNumber + 1 : badgeNumber;
            this.totalNumberOfFieldsChanged = hasChanges ? this.totalNumberOfFieldsChanged + 1 : this.totalNumberOfFieldsChanged;
            this.totalNumberOfFieldsOnForm = comp.isHidden ? this.totalNumberOfFieldsOnForm : this.totalNumberOfFieldsOnForm + 1;
          }
        });
        if (badgeNumber > 0) {
          this.tabs = [
            ...this.tabs.slice(0, index),
            {
              ...this.tabs[index],
              badgeNumber
            },
            ...this.tabs.slice(index + 1)
          ];
        }
      });
    }
  }

  assignParentFields (externalFields = this.externalFields) {
    this.parentFields = {
      ...externalFields,
      applicationId: this.applicationId,
      applicationFormId: this.applicationFormId,
      formId: this.formId,
      revisionId: this.formRevisionId,
      programId: this.programId,
      formType: this.formType,
      reportFieldResponse: this.reportFieldResponse
    };
  }

  async setReportFieldResponse () {
    if (!!this.applicationId) {
      const {
        formContainsReportField,
        formHasNominationReportField
      } = this.reportFieldService.formContainsReportField(this.form);
      if (formContainsReportField) {
        this.reportFieldResponse = await this.reportFieldService.getReportFieldResponseFromAPI(
          this.applicationId,
          this.formRevisionId,
          formHasNominationReportField
        );
        this.assignParentFields();
      }
    }
  }

  getFormFieldResponseFromCompKey (compKey: string) {
    if (this.componentTabIndexMap[compKey]) {
      return this.externalFields.referenceFields[this.componentTabIndexMap[compKey].refKey];
    }

    return null;
  }

  setTabsAndFormGroupMap () {
    this.tabs = this.form.map<FormTab>((tab, index) => {
      this.formGroupMap[index] = this.formValidationService.getFormComponentMap(
        tab,
        this.translations,
        this.formBuilderService.inFormBuilder,
        this.readOnly,
        this.isManagerForm,
        false,
        false
      );

      return {
        order: index,
        label: this.translations[tab.tabName] || tab.tabName,
        active: this.tabIndex === index,
        actions: [],
        context: tab,
        valid: true,
        logic: tab.logic,
        hidden: false,
        touched: false,
        showErrorSummary: false
      };
    });
  }

  isValidComp (compType: string) {
    return !compType.startsWith('referenceFieldsType') &&
      !compType.startsWith('failedComponent') &&
      compType !== 'button' &&
      compType !== 'content'&&
      compType !== 'htmlelement';
  }

  rerunLogic (
    changedColumn: NestedPropColumn<BaseApplicationForLogic, 'referenceFields'>|
      NestedPropColumn<BaseApplicationForLogic, 'application'>
  ) {
    const column = changedColumn as LogicColumn<BaseApplicationForLogic>;
    const recordForLogic = this.formLogicService.getRecordForLogic(
      this.externalFields as BaseApplication,
      this.reportFieldResponse
    );

    const conditionalVisibilityState = this.logicBuilderService.rerunConditionalLogic(
      column,
      this.conditionalVisibilityState,
      recordForLogic
    );
    this.setConditionalVisibilityState(conditionalVisibilityState);
    this.setValueState = this.logicBuilderService.rerunValueLogic(
      column,
      this.setValueState,
      recordForLogic
    );
    const validityState = this.logicBuilderService.rerunConditionalLogic(
      column,
      this.validityState,
      recordForLogic
    );
    this.setValidityState(validityState);
    this.formulaState = this.formulaBuilderService.rerunFormulas<BaseApplicationForLogic>(
      column.join('.'),
      this.formulaState,
      recordForLogic
    );

    this.emitConditionalVisibilityState();
  }

  setValidityState (validityState: LogicState<BaseApplicationForLogic, boolean>) {
    this.formLogicService.setCurrentValidityState(validityState);
    this.validityState = this.formLogicService.currentValidityState;
  }

  emitConditionalVisibilityState () {
    this.onConditionalVisibilityStateChanged.emit(this.conditionalVisibilityState);
  }

  applyComponentLogicResults () {
    let validityChanges: string[] = [];
    const changes = this.form.reduce<FormChangesWithCompKey[]>((acc, tab) => {
      const changes = this.formLogicService.applyComponentLogicResults(
        tab,
        this.setValueState,
        this.conditionalVisibilityState,
        this.formulaState,
        this.readOnly,
        this.formService.getAudienceFromFormType(this.formType),
        this.inFormBuilder
      );
      validityChanges = [
        ...validityChanges,
        ...changes.validityChanges
      ];

      return [
        ...acc,
        ...changes.valueChanges
      ];
    }, []);
    this.handleChangesFromApplyComponentLogic(changes, validityChanges);
  }

  handleChangesFromApplyComponentLogic (
    valueChanges: FormChangesWithCompKey[],
    validityChanges: string[]
  ) {
    valueChanges.forEach((change) => {
      this.handleValueChange(change);
    });
    this.setHiddenCompKeys();
    if (validityChanges.length > 0) {
      this.formValidationService.handleForcingValidationUpdates(
        validityChanges,
        this.componentTabIndexMap,
        this.formGroupMap
      );
      setTimeout(() => {
        const tabIndexValidityChanges = this.formValidationService.setFormValidationMap(this.formGroupMap);
        tabIndexValidityChanges.forEach((index) => {
          this.checkTabValidity(index);
        });
      });
    }
  }

  setHiddenCompKeys () {
    this.hiddenCompKeys = [];
    this.form.forEach((tab) => {
      this.componentHelper.eachComponent(tab.components, (comp) => {
        if (comp.isHidden || comp.hiddenFromParent) {
          this.hiddenCompKeys.push(comp.key);
        }
      }, true);
    });
  }

  markFormGroupAsDirty (
    doUpdateValueAndValidity: boolean,
    index: number
  ) {
    if (!this.inFormBuilder) {
      for (const key in this.formGroupMap[index].controls) {
        if (Object.prototype.hasOwnProperty.call(this.formGroupMap[index].controls, key)) {
          const control = this.formGroupMap[index].controls[key];
          if (control.invalid) {
            control.markAsDirty();
            control.markAsTouched();
          }
          if (doUpdateValueAndValidity) {
            control.updateValueAndValidity();
            if (control.valid) {
              control.markAsDirty();
              control.markAsTouched();
            }
          }
        }
      }
    }
  }

  activeTabChanged (tab: Tab) {
    this.markFormGroupAsDirty(false, this.tabIndex);
    const newIndex = this.getNewIndexFromActiveTabChanged(tab);
    if (this.tabIndex !== newIndex) {
      // Check tab validity of old tab
      this.checkTabValidity(this.tabIndex);
      this.updateTabTouched(this.tabIndex, true);
      this.tabIndex = newIndex;
      if (!this.tabs[this.tabIndex].valid) {
        // If new tab is not valid, re-run logic to see if it has become valid
        this.checkTabValidity(this.tabIndex);
      }
      this.determineFirstAndLastVisibleTabs();
    }
  }

  updateTabTouched (tabIndex: number, touched: boolean) {
    this.tabs = this.tabs.map((tab, index) => {
      let isTouched = tab.touched;
      let icon = tab.icon;
      if (index === tabIndex) {
        isTouched = touched;
        icon = tab.valid || !isTouched ? '' : 'exclamation-circle text-danger';
      }

      return {
        ...tab,
        icon,
        touched: isTouched,
        showErrorSummary: this.getShouldShowErrorSummary(tab.valid, isTouched)
      };
    });
  }

  getShouldShowErrorSummary (valid: boolean, isTouched: boolean) {
    return !this.inFormBuilder && !valid && isTouched;
  }

  getNewIndexFromActiveTabChanged (tab: Tab): number {
    return this.form.findIndex((def) => {
      return tab.context.uniqueId === def.uniqueId;
    });
  }

  nextTab () {
    const newTabIndex = this.tabs.findIndex((_, index) => {
      const tabVisible = this.getTabIsVisible(index);

      if (index > this.tabIndex && tabVisible) {
        return true;
      }

      return false;
    });

    this.updateTabIndex(newTabIndex);
    this.scrollToTopOfForm();
  }

  previousTab () {
    const newTab = this.arrayHelper.reverse(this.tabs).find((tab) => {
      const index = this.tabs.indexOf(tab);
      const tabVisible = this.getTabIsVisible(index);

      if (index < this.tabIndex && tabVisible) {
        return true;
      }

      return false;
    });

    const newTabIndex = this.tabs.indexOf(newTab);

    this.updateTabIndex(newTabIndex);
    this.scrollToTopOfForm();
  }

  private determineFirstAndLastVisibleTabs () {
    let firstVisibleIndex = this.tabs.findIndex((_, index) => {
      return this.getTabIsVisible(index);
    });
    const lastVisible = this.arrayHelper.reverse(this.tabs).find((tab) => {
      const index = this.tabs.indexOf(tab);

      return this.getTabIsVisible(index);
    });
    let lastVisibleTabIndex = this.tabs.indexOf(lastVisible);
    if (firstVisibleIndex === -1) {
      firstVisibleIndex = 0;
    }
    if (lastVisibleTabIndex === -1) {
      lastVisibleTabIndex = this.tabs.length - 1;
    }

    this.firstVisibleTabIndex = firstVisibleIndex;
    this.lastVisibleTabIndex = lastVisibleTabIndex;
  }

  private getTabIsVisible (index: number) {
    if (!this.conditionalVisibilityState) {
      return true;
    }
    const column: NestedPropColumn<BaseApplicationForLogic, 'tabs'> = ['tabs', index];
    const tabCurrentLogicState = this.logicBuilderService.getCurrentLogicValueOfColumn(
      column as LogicColumn<BaseApplicationForLogic>,
      this.conditionalVisibilityState
    );

    return tabCurrentLogicState ?? true;
  }

  updateTabIndex (newIndex: number) {
    this.tabs = this.tabs.map((tab, index) => {
      return {
        ...tab,
        active: index === newIndex
      };
    });
    this.activeTabChanged(this.tabs[newIndex]);
    this.onUpdateTabIndex.emit(newIndex);
  }

  /* returns whether all tabs are valid */
  checkAllTabValidity () {
    this.tabs.forEach((_, index) => {
      this.checkTabValidity(index);
    });

    return this.allTabsValid;
  }

  /**
   *
   * @param index: Tab index to check validity
   */
  async checkTabValidity (index: number) {
    const visible = this.getTabIsVisible(index);
    if (visible) {
      const valid = this.formHelperService.checkFormGroupValidity(
        this.formGroupMap[index],
        this.form[index]
      );
      this.updateTabValidity(valid, index);

      return valid;
    } else {
      this.updateTabValidity(true, index);
    }

    return true;
  }

  /* updates the tab with an icon if the tab is invalid */
  updateTabValidity (
    valid: boolean,
    index: number
  ) {
    if (
      !this.readOnly &&
      (this.tabs[index].valid !== valid)
    ) {
      const tab = this.tabs[index];
      this.tabs = [
        ...this.tabs.slice(0, index),
        {
          ...tab,
          icon: valid || !tab.touched ? '' : 'exclamation-circle text-danger',
          valid,
          showErrorSummary: this.getShouldShowErrorSummary(valid, tab.touched)
        },
        ...this.tabs.slice(index + 1)
      ];
    }

    return this.allTabsValid;
  }

  private registerTriggerSubmit () {
    if (this.triggerSubmit) {
      this.triggerSubmit.registerAction(async () => {
        // Mark all tabs touched and controls dirty
        this.form.forEach((_, index) => {
          this.updateTabTouched(index, true);
          this.markFormGroupAsDirty(true, index);
        });
        const valid = this.checkAllTabValidity();
        if (!valid) {
          this.onFormInvalid();
        }

        return valid;
      });
    }
  }

  async _onSubmit () {
    this.submitting = true;
    this.applyComponentLogicResults();
    this.form.forEach((_, index) => {
      this.updateTabTouched(index, true);
      this.markFormGroupAsDirty(true, index);
    });
    const allTabsValid = this.checkAllTabValidity();
    if (allTabsValid) {
      if (this.requireSignature) {
        const signatureModalResponse = await this.signatureModal();
        if (signatureModalResponse) {
          this.onSubmit.emit(signatureModalResponse);
          this.updateTabIndex(this.firstVisibleTabIndex);
        }
      } else {
        this.onSubmit.emit();
        this.updateTabIndex(this.firstVisibleTabIndex);
      }
    } else {
      this.notifierService.error(this.i18n.translate(
        'APPLY:textFormIsInvalidFixErrorsBelow',
        {},
        'Form is invalid. Please fix the errors below.'
      ));
      this.onFormInvalid();
    }
    this.submitting = false;
  }

  onFormInvalid () {
    let firstInvalidTabIndex: number;
    this.tabs = this.tabs.map((tab, index) => {
      if (isUndefined(firstInvalidTabIndex) && !tab.valid) {
        const isVisible = this.getTabIsVisible(index);
        if (isVisible) {
          firstInvalidTabIndex = index;
        }
      }

      return {
        ...tab,
        touched: true,
        icon: tab.valid ? '' : 'exclamation-circle text-danger'
      };
    });
    
    if (!isUndefined(firstInvalidTabIndex)) {
      this.updateTabIndex(firstInvalidTabIndex);
    }
    this.scrollToTopOfForm();
  }

  scrollToTopOfForm () {
    if (this.scrollBoxClass) {
      const scrollBox = document.getElementsByClassName(this.scrollBoxClass);
      if (scrollBox.length > 0) {
        scrollBox[0].scrollTo({ top: 0 });
      }
    } else {
      this.domService.scrollToTop();
    }
  }

  async signatureModal (): Promise<SignatureModalResponse> {
    let savedSignature: YcFile;
    if (!!this.getSavedSignature) {
      this.spinnerService.startSpinner();
      savedSignature = await this.getSavedSignature();
      this.spinnerService.stopSpinner();
    }

    return this.modalFactory.open(
      SignatureModalComponent,
      {
        savedSignature,
        supportsBypass: this.supportsBypassSignature,
        signatureDescription: this.signatureDescription ||
          this.signatureService.getDefaultFormDescriptionApplicant(
            this.formType === FormTypes.NOMINATION
          )
      }
    );
  }

  goToFirstInvalidTab () {
    const firstInvalidTabIndex = this.tabs.findIndex((tab) => {
      return !tab.valid;
    });
    this.updateTabIndex(firstInvalidTabIndex);
  }

  handleValueChange (
    change: FormChangesWithCompKey
  ) {
    if (this.isAfterInit) {
      this.onChange.emit(change);
      if (change.updateFormGroup) {
        const tabIndex = this.componentTabIndexMap[change.componentKey].tabIndex;
        const group = this.formGroupMap[tabIndex];
        const control = group.get(change.componentKey);
        if (!!control) {
          control.setValue(change.value);
          control.markAsDirty();
        }
      }
      this.formFieldHelperService.handleChangeTracking([change], this.externalFields);
      this.assignParentFields();
      this.handleCallsToMake(change);
      if (change.isReferenceField) {
        this.rerunLogic(['referenceFields', change.key]);
      } else {
        this.rerunLogic(['application', change.type as keyof BaseApplication]);
      }
      this.determineFirstAndLastVisibleTabs();

      setTimeout(() => {
        this.applyComponentLogicResults();
      });
    }
  }

  handleCallsToMake (change: FormChangesWithCompKey) {
    const callsToMake: (ExternalAPISelection&{relatedComponent: string})[] = [];
    this.formLogicService.getExternalAPICalls(this.form).forEach((service) => {
      if (
        service.relatedComponent &&
        change.componentKey === service.relatedComponent
      ) {
        callsToMake.push(service);
      }
    });
    this.executeServices(callsToMake);
  }

  handleDropListChange (dropLists: CdkDropList[], index: number) {
    this.dropLists = {
      ...this.dropLists,
      [index]: dropLists
    };
    const allDroplists = Object.keys(this.dropLists).reduce((acc, key) => {
      return [
        ...acc,
        ...this.dropLists[+key]
      ];
    }, []);
    this.dropListsChanged.emit(allDroplists);
  }

  async handleComponentDrop (drop: CdkDragDrop<FormDefinitionComponent[]>) {
    const dropType = await this.formBuilderService.handleComponentDrop(
      drop,
      this.formId,
      this.form,
      this.isManagerForm
    );
    switch (dropType) {
      case FormBuilderDropEvent.NewComponent:
      case FormBuilderDropEvent.ReorderComponent:
        this.formChange.emit(this.form);
        break;
    }
  }

  private executeServices (
    uniqueIntegrations = this.formLogicService.getExternalAPICalls(this.form)
  ) {
    // make the calls if we are in an application
    // OR we are in the applicant portal filling out an eligibility form
    if (
      (this.applicationFormId && this.applicationId) ||
      (this.portal.isApply && this.formType === FormTypes.ELIGIBILITY)
    ) {
      uniqueIntegrations.forEach((req) => {
        const formFieldResponse = this.getFormFieldResponseFromCompKey(req.relatedComponent);
        if (
          // make the call if there is no related component
          (!req.relatedComponent || (req.relatedComponent === UIExternalAPI.EMPTY_FORM_FIELD_SOURCE)) ||
          // OR there is a related component that has an answer
          (formFieldResponse !== '' && formFieldResponse !== null && formFieldResponse !== undefined)
        ) {
          this.externalAPIService.setUpIntegrationRequest(
            req.integrationId,
            req.relatedComponent,
            {
              formField: formFieldResponse?.toString() ?? '',
              applicationId: this.applicationId,
              applicationFormId: this.applicationFormId,
              formId: this.formId,
              formRevisionId: this.formRevisionId
            }
          );
        }
      });
    }
  }

  ngOnDestroy () {
    this.sub?.unsubscribe();
  }
}
