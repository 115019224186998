import { Injectable } from '@angular/core';
import { HttpRestService } from '@core/services/http-rest.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { ClientIntAuthorities, ClientSettings, CurrencySetting, SelectedLangsFromApi } from '@core/typings/api/admin-client.typing';
import { ClientBrandingFromApi, ColorSchemePayload, SaveBrandingForApi, UpdateClientCurrencyPayload } from '@core/typings/branding.typing';
import { ClientAffiliateInfo } from '@core/typings/client-user.typing';
import { RegistrationAuthorityFromApi } from '@core/typings/organization.typing';

@Injectable({ providedIn: 'root' })
export class ClientSettingsResources {

  constructor (
    private  httpRestService: HttpRestService,
    private portal: PortalDeterminationService
  ) { }

  getClientSettings (): Promise<ClientSettings> {
    const endpoint = 'api/client/settings/';

    return this.httpRestService.get(endpoint);
  }

  getSelectedLanguages (): Promise<SelectedLangsFromApi[]> {
    const endpoint = 'api/client/languages';

    return this.httpRestService.get(endpoint);
  }

  getSelectedCurrencies (): Promise<CurrencySetting[]> {
    const endpoint = 'api/client/currencies';

    return this.httpRestService.get(endpoint);
  }

  getRegistrationAuthorities (countryCode: string): Promise<ClientIntAuthorities> {
    const endpoint = `/api/lookup/GetRegistrationAuthorities?countryCode=${countryCode}`;

    return this.httpRestService.get(endpoint);
  }

  getRestrictedRegistrationAuthorities (clientId?: number): Promise<RegistrationAuthorityFromApi[]> {
    const endpoint = this.portal.isApply ?
      `api/portal/registrationAuthorities/getRestricted/${clientId}` :
      'api/registrationAuthorities/getRestricted';

    return this.httpRestService.get(endpoint);
  }

  updateClientLanguages (cultures: string[]) {
    const endpoint = 'api/client/languages/update';

    return this.httpRestService.post(endpoint, {
      cultures
    });
  }

  updateClientCurrencies (payload: UpdateClientCurrencyPayload) {
    const endpoint = 'api/client/currencies/update';

    return this.httpRestService.post(endpoint, payload);
  }

  getBranding (): Promise<ClientBrandingFromApi> {
    const endpoint = 'api/client/branding';

    return this.httpRestService.get(endpoint);
  }

  saveBranding (payload: SaveBrandingForApi) {
    return this.httpRestService.post('api/client/branding', payload);
  }

  saveColorScheme (colorSchemes: ColorSchemePayload) {
    const endpoint = 'api/client/ClientColorScheme';

    return this.httpRestService.post(endpoint, colorSchemes);
  }

  getClientSettingsForApplicant (clientID: number): Promise<ClientSettings> {
    return this.httpRestService.get(`api/portal/programs/clientSettings/${clientID}`);
  }

  getAvailableApplicantCurrencies (programId: number): Promise<CurrencySetting[]> {
    const endpoint = `api/portal/programs/clientCurrencies/${programId}`;

    return this.httpRestService.get(endpoint);
  }

  getClientAffiliateInfo (): Promise<ClientAffiliateInfo> {
    const endpoint = this.portal.isApply ?
      'api/portal/applicant/AffiliateInfo' :
      'api/client/AffiliateInfo'

    return this.httpRestService.get(endpoint);
  }
}

