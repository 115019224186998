import { Injectable } from '@angular/core';
import { GcFlyoutService } from '@core/services/gc-flyout.service';
import { PortalDeterminationService } from '@core/services/portal-determination.service';
import { Applicant } from '@core/typings/applicant.typing';
import { AddEditUser, SimpleUser, User, UserAccessTypes, UserFromApi, User_Table_Key } from '@core/typings/client-user.typing';
import { SimpleReportOwner } from '@core/typings/user.typing';
import { FlyoutService } from '@yourcause/common/flyout';
import { I18nService } from '@yourcause/common/i18n';
import { ConfirmAndTakeActionService } from '@yourcause/common/modals';
import { NotifierService } from '@yourcause/common/notifier';
import { AttachYCState, BaseYCService } from '@yourcause/common/state';
import { Subscription } from 'rxjs';
import { UserDetailFlyoutComponent } from './user-detail-flyout/user-detail-flyout.component';
import { UserResources } from './user.resources';
import { UserState } from './user.state';
import { AdHocReportingAPI } from '@core/typings/api/ad-hoc-reporting.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ArrayHelpersService } from '@yourcause/common/utils';
import { GCDashboards } from '@features/dashboards/dashboards.typing';
import { TopLevelFilter, ALL_SKIP_FILTER } from '@yourcause/common';

@AttachYCState(UserState)
@Injectable({ providedIn: 'root' })
export class UserService extends BaseYCService<UserState> {
  sub = new Subscription();

   constructor (
    private i18n: I18nService,
    private notifier: NotifierService,
    private portal: PortalDeterminationService,
    private userResources: UserResources,
    private gcFlyoutService: GcFlyoutService,
    private flyoutService: FlyoutService,
    private confirmAndTakeAction: ConfirmAndTakeActionService,
    private arrayHelper: ArrayHelpersService
  ) {
    super();
    this.sub.add(
      this.changesTo$(this.userKey).subscribe(() => {
        const user = this.currentUser;
        const firstName = user ? user.firstName : '';
        const lastName = user ? user.lastName : '';
        const jobTitle = user ? (user as User).jobTitle : '';
        const name = `${
            firstName.slice(0, 10) + (firstName.length > 10 ? '...' : '')
          } ${
            lastName.slice(0, 15) + (lastName.length > 15 ? '...' : '')
          }`;
        this.set('userName', name);
        this.set('userJobTitle', jobTitle);
      })
    );
  }

  get userKey (): 'user'|'applicant'|'admin' {
    return this.portal.isManager ?
      'user' :
      this.portal.isApply ? 'applicant' : 'admin';
  }

  get user () {
    return this.get('user');
  }

  get allUsers () {
    return this.get('allUsers');
  }

  get allUsersMap () {
    return this.get('allUsersMap');
  }

  get allUsersDetailed () {
    return this.get('allUsersDetailed');
  }

  get userAudienceMap () {
    return this.get('userAudienceMap');
  }

  get applicant () {
    return this.get('applicant');
  }

  get userEmail () {
    return this.user ? this.user.email : '';
  }

  get currentUser () {
    return this.get(this.userKey);
  }

  get adminPermissions () {
    return this.get('adminPermissions');
  }

  get lastSelectedCurrency () {
    return this.get('lastSelectedCurrency');
  }

  isReviewerOnlyUser () {
    return this.user?.accessType === UserAccessTypes.Reviewer;
  }

  setApplicant (applicant: Applicant) {
    this.set('applicant', applicant);
  }

  setLastSelectedCurrency (currency: string) {
    this.set('lastSelectedCurrency', currency);
  }

  setAdmin (admin: User) {
    this.set('admin', admin);
  }

  async setAdminPermissions () {
    const adminPermissions = await this.userResources.getAdminPermissions();
    this.set('adminPermissions', adminPermissions);
  }

  setUser (user: User) {
    this.set('user', user);
  }

  getCurrentUserCulture () {
    return this.currentUser ?
      this.currentUser.culture || 'en-US' :
      'en-US';
  }

  async resetAllUsers () {
    let hasAllUsers = !!this.allUsers;
    let hasAllDetailedUsers = !!this.allUsersDetailed;
    if (hasAllUsers) {
      this.set('allUsers', undefined);
    }
    if (hasAllDetailedUsers) {
      this.set('allUsersDetailed', undefined);
    }

    await Promise.all([
      hasAllUsers ? this.setAllUsers() : undefined,
      hasAllDetailedUsers ? this.setAllUsersDetailed() : undefined
    ]);
  }

  async setAllUsers () {
    if (!this.allUsers) {
      const allUsers = await this.userResources.getAllUsers();
      this.setAllUsersMap(allUsers);
      this.set('allUsers', allUsers);
    }
  }

  setAllUsersMap (allUsers: SimpleUser[]) {
    const allUsersMap = allUsers.reduce((acc, user) => {
      return {
        ...acc,
        [user.email]: user
      };
    }, {} as Record<string, SimpleUser>);
    this.set('allUsersMap', allUsersMap);
  }

  async setAllUsersDetailed () {
    if (!this.allUsersDetailed) {
      const allUsers = await this.userResources.getAllUsersDetailed();
      const adaptedUsers = allUsers.map((user) => {
        return {
          ...user,
          isCurrentUser: user.id === this.currentUser.id
        };
      });
      this.set('allUsersDetailed', adaptedUsers);
    }
  }

  /**
   * Do the add or edit of a client user
   * 
   * @param user: User to Add or Edit
   */
  async doAddEditUser (user: AddEditUser) {
    user.firstName = user.firstName.trim();
    user.lastName = user.lastName.trim();
    await this.userResources.addEditUser(user);
    await this.resetAllUsers();
  }

  /**
   * Handles Add or Editing a Client User
   * 
   * @param user: User to Add or Edit
   * @returns if the method was successful
   */
  async addEditUser (user: AddEditUser) {
    const {
      passed,
      error
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doAddEditUser(user),
      this.i18n.translate(
        user.id ?
          'USERS:textSuccessfullyUpdatedUser' :
          'USERS:textSuccessfullyAddedUser',
        {},
        user.id ?
          'Successfully updated the user' :
          'Successfully added the user'
      ),
      ''
    );
    if (!passed) {
      this.showAddEditUserError(!!user.id, error);
    }

    return passed;
  }

  showAddEditUserError (
    isUpdate: boolean,
    error: any
  ) {
    if (error?.error?.message === `ClientUser record already exists for this email and client combination.`) {
      this.notifier.error(this.i18n.translate(
        'common:textEmailAlreadyInUse',
        {},
        'Email address already in use'
      ));
    } else {
      this.notifier.error(this.i18n.translate(
        isUpdate ?
          'USERS:textErrorUpdatingUser' :
          'USERS:textErrorAddingUser',
        {},
        isUpdate ?
          'There was an error updating the user' :
          'There was an error adding the user'
      ));
    }
  }

  /**
   * Does the Activation of a Client User
   * 
   * @param id: ID of user to activate
   */
  async doActivateUser (id: number) {
    await this.userResources.activateUser(id);
    await this.resetAllUsers();
  }

  /**
   * Handles activating a client user
   * 
   * @param id: ID of user to activate
   * @returns if it was successful
   */
  async activateUser (id: number) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doActivateUser(id),
        this.i18n.translate(
          'USERS:textSuccessfullyActivatedUser',
          {},
          'Successfully activated the user'
        ),
        this.i18n.translate(
        'USERS:textErrorActivatingUser',
        {},
        'There was an error activating the user'
      )
    );

    return passed;
  }

  /**
   * Deactivates a User
   *
   * @param clientUserId: User ID to Deactivate
   */
  async doDeactivateUser (
    clientUserId: number
  ) {
    await this.userResources.deactivateUser([clientUserId]);
    await this.resetAllUsers();
  }

  /**
   * Handles Deactivating a User
   * 
   * @param clientUserId: User ID to Deactivate
   * @returns if it was successful
   */
  async handleDeactivateUser (
    userId: number
  ) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDeactivateUser(userId),
      this.i18n.translate(
        'USERS:textSuccessfullyDeactivatedUser',
        {},
        'Successfully deactivated the user'
      ),
      this.i18n.translate(
        'USERS:textErrorDeactivatingUser',
        {},
        'There was an error deactivating the user'
      )
    );

    return passed;
  }

  /**
   * Deactivates a group of users
   *
   * @param ids: IDs of users to deactivate
   */
  async doDeactivateUsers (ids: number[]) {
    await this.userResources.deactivateUser(ids);
    await this.resetAllUsers();
  }

  /**
   * Handles Deactivating a Group of Users
   *
   * @param ids: IDs of users to deactivate
   * @returns if it was successful
   */
  async deactivateUsers (ids: number[]) {
    const {
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doDeactivateUsers(ids),
      this.i18n.translate(
        'USERS:textSuccessfullyDeactivatedUsers',
        {},
        'Successfully deactivated the users'
      ),
      this.i18n.translate(
        'USERS:textErrorDeactivatingUsers',
        {},
        'There was an error deactivating the users'
      )
    );

    return passed;
  }

  /**
   * Sets the User Audience Map
   *
   * @param id: User ID
   */
  async setUserAudienceMap (id: number) {
    if (!this.userAudienceMap[id]) {
      const audiences = await this.userResources.getAudiencesForUser(id);
      this.set('userAudienceMap', {
        ...this.userAudienceMap,
        [id]: audiences
      });
    }
  }

  /**
   * Resets the User Audience Map
   * 
   * @param id: User Id
   */
  async resetUserAudienceMapForUser (id: number) {
    if (!!this.userAudienceMap[id]) {
      this.set('userAudienceMap', {
        ...this.userAudienceMap,
        [id]: undefined
      });
      await this.setUserAudienceMap(id);
    }
  }

  resetUserAudienceMap () {
    this.set('userAudienceMap', {});
  }

  /**
   * Does the Fetch to Get Details Needed to Transfer Report and Dashboard Ownership
   * 
   * @param userId: ID of user to get details for
   * @param dontIncludeDashboards: If we should include dashboard ownership
   * @returns the reports and dashboards owned by that user and the list of GMs who can take ownership
   */
  async doGetReportAndDashboardTransferOwnershipDetails (
    userId: number,
    dontIncludeDashboards: boolean
  ): Promise<{
    reportsAndDashboardsOwnedByUser: AdHocReportingUI.SimpleReportOwnedByUserForUi[];
    usersWhoCanTakeOwnershipOfReports: SimpleReportOwner[]
  }> {
    const [
      reportsOwnedByUser,
      dashboardsOwnedByUser,
      usersWhoCanTakeOwnershipOfReports
    ] = await Promise.all([
      this.userResources.getReportsOwnedByUser(userId),
      dontIncludeDashboards ? [] : this.userResources.getDashboardsOwnedByUser(userId),
      this.userResources.getUsersWhoCanTakeOwnershipOfReports()
    ]);
    const reportsMapped = reportsOwnedByUser.filter((report) => {
      return report.reportType === AdHocReportingAPI.AdHocReportType.AdHoc;
    }).map((report) => {
      return {
        id: report.id,
        name: report.name,
        description: report.description,
        isDashboard: false,
        newOwnerId: null
      };
    });
    const dashboardsMapped = dashboardsOwnedByUser.filter((dashboard) => {
      return dashboard.dashboardType !== GCDashboards.DashboardTypes.MY_WORKSPACE;
    }).map((dashboard) => {
      return {
        id: dashboard.id,
        name: dashboard.name,
        description: '',
        isDashboard: true,
        newOwnerId: null
      };
    });


    return {
      reportsAndDashboardsOwnedByUser: this.arrayHelper.sort([
        ...dashboardsMapped,
        ...reportsMapped
      ], 'name'),
      usersWhoCanTakeOwnershipOfReports: usersWhoCanTakeOwnershipOfReports.filter((user) => {
        return user.userId !== userId;
      })
    };
  }

  /**
   * Handles Getting Report and Dashboard Transfer Ownership Details
   *
   * @param userId: ID of the user to get details for
   * @param dontIncludeDashboards: If we should include dashboard ownership
   * @returns the details for transferring ownership
   */
  async getReportAndDashboardTransferOwnershipDetails (
    userId: number,
    dontIncludeDashboards: boolean
  ) {
    const {
      endpointResponse,
      passed
    } = await this.confirmAndTakeAction.genericTakeAction(
      () => this.doGetReportAndDashboardTransferOwnershipDetails(userId, dontIncludeDashboards),
      '',
      ''
    );

    return passed ? endpointResponse : null;
  }

  /**
   * Handle the reassigning of reports and dashboards
   *
   * @param reportsToReassign: List of reports to reassign
   * @param dashboardsToReassign: List of dashboards to reassign
   * @returns if the reassignment was successful
   */
  async handleReassignReportsAndDashboards (
    reportsToReassign: AdHocReportingAPI.ReportToReassign[],
    dashboardsToReassign: GCDashboards.DashboardToReassign[]
  ) {
    let reportsPassed = true;
    let dashboardsPassed = true;
    if (reportsToReassign.length > 0) {
      const reportsResult = await this.confirmAndTakeAction.genericTakeAction(
        () => this.userResources.reassignReports(reportsToReassign),
        this.i18n.translate('USERS:textSuccessfullyReassignedReports', {}, 'Successfully reassigned reports'),
        this.i18n.translate('USERS:textErrorReassigningReports', {}, 'There was an error reassigning reports')
      );
      reportsPassed = reportsResult.passed;
    }
    if (dashboardsToReassign.length > 0) {
      const dashboardResult = await this.confirmAndTakeAction.genericTakeAction(
        () => this.userResources.reassignDashboards(dashboardsToReassign),
        this.i18n.translate('USERS:textSuccessfullyReassignedDashboards', {}, 'Successfully reassigned dashboards'),
        this.i18n.translate('USERS:textErrorReassigningDashboards', {}, 'There was an error reassigning dashboards')
      );
      dashboardsPassed = dashboardResult.passed;
    }

    return dashboardsPassed && reportsPassed;
  }

  getUserAccessTypeTopLevelFilter () {
    return new TopLevelFilter(
      'typeaheadSingleEquals',
      'accessType',
      ALL_SKIP_FILTER,
      '',
      {
        selectOptions: [{
          value: ALL_SKIP_FILTER,
          display: this.i18n.translate(
            'common:textAllAccessTypes',
            {},
            'All access types'
          )
        }, {
          value: UserAccessTypes.Default,
          display: this.i18n.translate(
            'common:textGrantManagerDefault',
            {},
            'Grant manager (Default)'
          )
        }, {
          value: UserAccessTypes.Reviewer,
          display: this.i18n.translate(
            'common:textReviewerOnly2',
            {},
            'Reviewer only (Limited access)'
          )
        }]
      },
      this.i18n.translate(
        'common:textAccessType',
        {},
        'Access type'
      )
    );
  }

  /**
   * Opens the user flyout for the given record
   *
   * @param user: User to open flyout for
   */
  async openUserFlyout (user: UserFromApi): Promise<void> {
    this.gcFlyoutService.setInfoForFlyout(user, User_Table_Key, 'userId');
    const only1Record = this.gcFlyoutService.idsForFlyout.length === 1;
    await this.flyoutService.openFlyout(
      UserDetailFlyoutComponent,
      {
        showIterator: !only1Record
      },
      this.gcFlyoutService.onNextFlyoutRecord,
      this.gcFlyoutService.onPreviousFlyoutRecord,
      this.prepareUserFlyout,
      this.gcFlyoutService.onInitialFlyoutRecord
    );
  }

  /**
   * Prepare the User Flyout
   */
  prepareUserFlyout = async (id: number|string) => {
    await this.setUserAudienceMap(id as number);
  };
}
