<div *ngIf="!!user"
  class="d-flex m-3 flex-column mb-3">
  <div class="d-flex align-items-center justify-content-between">
    <div>
      <!-- Name -->
      <div class="flyout-header">
        {{ user.fullName }}
        <ng-container *ngIf="user.isDeactivated">
          (<yc-i18n
            key="common:textDeactivated"
            defaultValue="Deactivated">
          </yc-i18n>)
        </ng-container>
      </div>
      <!-- Access Type -->
      <div *ngIf="showAccessType">
        <yc-i18n
          key="common:textAccessType"
          defaultValue="Access type">
        </yc-i18n>: 
        <yc-i18n
          [key]="user.accessType === UserAccessTypes.Default ?
            'common:textGrantManagerDefault' :
            'common:textReviewerOnly2'"
          [defaultValue]="user.accessType === UserAccessTypes.Default ?
            'Grant manager (Default)' :
            'Reviewer only (Limited access)'">
        </yc-i18n>
      </div>
      <div>
        {{ user.email }}
      </div>
      <div>
        {{ user.jobTitle }}
      </div>
    </div>
    <!-- Edit -->
    <yc-button
      class="me-2"
      [primary]="true"
      [langKey]="'ADMIN:hdrEditUser'"
      [defaultText]="'Edit User'"
      (onClick)="editUser()">
    </yc-button>
  </div>

  <!-- Roles -->
  <yc-expandable-container
    [header]="'GLOBAL:textRoles' | ycI18n: 'Roles'"
    [subHeader]="rolesSubHeader"
    [hasData]="user.roles.length > 0">
    <yc-table
      [key]="'USER_ROLES_' + user.userId"
      [rows]="user.roles"
      [simple]="true"
      [rowsPerPage]="5">
      <ng-container *ycTableColumn="'common:lblName';
        let row;">
        {{ row.clientRoleName }}
      </ng-container>
    </yc-table>
  </yc-expandable-container>

  <!-- Workflows -->
  <yc-expandable-container
    [header]="'GLOBAL:textWorkflows' | ycI18n: 'Workflows'"
    [subHeader]="workflowsSubHeader"
    [hasData]="user.workflows.length > 0">
    <yc-table
      [key]="user.userId + '_WORKFLOWS'"
      [rows]="user.workflows"
      [simple]="true"
      [rowsPerPage]="5">
      <ng-container *ycTableColumn="'common:lblName';
        let row;">
        {{ row.name }}
      </ng-container>
    </yc-table>
  </yc-expandable-container>

  <!-- Audiences -->
  <yc-expandable-container
    [header]="'CONFIG:hdrAudiences' | ycI18n: 'Audiences'"
    [subHeader]="audiencesSubHeader"
    [hasData]="audiences.length > 0">
    <yc-table
      [key]="user.userId + '_AUDIENCES'"
      [rows]="audiences"
      [simple]="true"
      [rowsPerPage]="5">
      <ng-container *ycTableColumn="'common:lblName';
        let row;">
        {{ row.audienceName }}
      </ng-container>

      <ng-container *ycTableColumn="''
        let row;
        prop: '',
        filterType: '',
        class: 'actions',
        locked: true">
        <yc-button
          [icon]="true"
          [fontAwesomeIcon]="'times'"
          [textDanger]="true"
          [tooltipText]="'common:textRemove' | ycI18n: 'Remove'"
          (onClick)="removeFromAudience(row.audienceId)">
        </yc-button>
      </ng-container>
    </yc-table>

  </yc-expandable-container>
  
</div>

