import { UntypedFormGroup } from '@angular/forms';
import { ReferenceFieldAPI } from '@core/typings/api/reference-fields.typing';
import { ApplicantFormComment, BaseApplication, Nominee, PortalFormAvailabilityInfo } from '@core/typings/application.typing';
import { UserInfo } from '@core/typings/client-user.typing';
import { ProgramTypes } from '@core/typings/program.typing';
import { ApplicationStatuses, StatusOptions } from '@core/typings/status.typing';
import { Translation } from '@core/typings/translation.typing';
import { AdHocReportingUI } from '@core/typings/ui/ad-hoc-reporting.typing';
import { ReferenceFieldsUI } from '@core/typings/ui/reference-fields.typing';
import { WorkflowLevel } from '@core/typings/workflow.typing';
import { EmployeeSSOFieldsData } from '@features/employee-sso-fields/employee-sso-fields.typing';
import { IsValidTypes, ValidationTypes } from '@features/forms/component-configuration/component-configuration.typing';
import { ExternalAPISelection } from '@features/forms/component-configuration/external-api-selector-settings/external-api-selector-settings.component';
import { SpecialHandling } from '@features/forms/form-renderer-components/standard-form-components/form-special-handling/form-special-handling.component';
import { FormulaState, RootFormula } from '@features/formula-builder/formula-builder.typing';
import { InKindRequestedItem } from '@features/in-kind/in-kind.typing';
import { GlobalLogicGroup, GlobalValueLogicGroup, ListLogicState, LogicColumn, LogicState } from '@features/logic-builder/logic-builder.typing';
import { EmailNotificationType, EmailOptionsModelForSave } from '@features/system-emails/email.typing';
import { SimpleGrantsAddress, SimpleStringMap, Tab } from '@yourcause/common';
import { TypeaheadSelectOption } from '@yourcause/common/core-forms';
import { CurrencyRadioOptions } from '@yourcause/common/currency';
import { Status } from '@yourcause/common/form-control-validation';
export const MAX_COMPONENTS_PER_PAGE = 100;

export const Forms_Table_Key = 'FORMS_TABLE';

export const FORM_STATUS: StatusOptions[] = [{
  name: 'Not sent',
  id: 1
}, {
  name: 'Not submitted',
  id: 2
}, {
  name: 'Draft saved',
  id: 3
}, {
  name: 'Submitted',
  id: 4
}, {
  name: 'Revision requested',
  id: 5
}];

export enum FormAudience {
  APPLICANT = 1,
  MANAGER = 2
}
export const FormDueEmails = {
  applicant: {
    nomination: {
      due: EmailNotificationType.NominationFormDueForApplicant,
      overdue: EmailNotificationType.NominationFormPastDueForApplicant
    },
    application: {
      due: EmailNotificationType.FormDueForApplicant,
      overdue: EmailNotificationType.FormPastDueForApplicant
    }
  },
  manager: {
    nomination: {
      due: EmailNotificationType.NominationFormDueForGrantManager,
      overdue: EmailNotificationType.NominationFormPastDueForGrantManager
    },
    application: {
      due: EmailNotificationType.FormDueForGrantManager,
      overdue: EmailNotificationType.FormPastDueForGrantManager
    }
  }
};

export const FORM_TYPES = [
  {
    id: 1,
    name: 'Request',
    audience: FormAudience.APPLICANT
  },
  {
    id: 2,
    name: 'Eligibility',
    audience: FormAudience.APPLICANT
  },
  {
    id: 3,
    name: 'LOI (Letter of Intent/Inquiry)',
    audience: FormAudience.APPLICANT
  },
  {
    id: 4,
    name: 'Review',
    audience: FormAudience.MANAGER
  },
  {
    id: 5,
    name: 'Award',
    audience: FormAudience.MANAGER
  },
  {
    id: 6,
    name: 'Approve (Decision)',
    audience: FormAudience.MANAGER
  },
  {
    id: 7,
    name: 'Decline (Decision)',
    audience: FormAudience.MANAGER
  },
  {
    id: 8,
    name: 'Payment',
    audience: FormAudience.MANAGER
  },
  {
    id: 9,
    name: 'Progress report',
    audience: FormAudience.APPLICANT
  },
  {
    id: 10,
    name: 'Grant agreement',
    audience: FormAudience.APPLICANT
  },
  {
    id: 11,
    name: 'Site visit',
    audience: FormAudience.MANAGER
  },
  {
    id: 12,
    name: 'Meeting notes',
    audience: FormAudience.MANAGER
  },
  {
    id: 13,
    name: 'Additional documentation',
    audience: FormAudience.APPLICANT
  },
  {
    id: 14,
    name: 'Approval',
    audience: FormAudience.MANAGER
  },
  {
    id: 15,
    name: 'Other - Applicant form',
    audience: FormAudience.APPLICANT
  },
  {
    id: 16,
    name: 'Other - Grant Manager form',
    audience: FormAudience.MANAGER
  },
  {
    id: 17,
    name: 'Nomination',
    audience: FormAudience.APPLICANT
  },
  {
    id: 18,
    name: 'Routing',
    audience: FormAudience.APPLICANT
  }
];

export const SampleExternalFields: Partial<BaseApplication> = {
  amountRequested: null,
  currencyRequestedAmountEquivalent: null,
  amountRequestedForEdit: null,
  currencyRequested: null,
  referenceFields: {},
  employeeInfo: {} as EmployeeSSOFieldsData,
  reviewerRecommendedFundingAmount: null,
  specialHandling: {
    name: 'Test Organization',
    address1: '',
    address2: '',
    city: '',
    country: '',
    postalCode: '',
    fileUrl: '',
    reason: '',
    notes: ''
  },
  defaultSpecialHandling: {
    name: 'Test Organization',
    address1: '',
    address2: '',
    city: '',
    country: '',
    postalCode: '',
    fileUrl: '',
    reason: '',
    notes: ''
  }
};


export enum FormStatuses {
  NotSent = 1,
  Sent = 2,
  DraftSaved = 3,
  Submitted = 4,
  RevisionRequested = 5
}

export enum FormDecisionTypes {
  Approve = 1,
  Decline = 2,
  Recused = 3
}

export enum SaveFormOptions {
  CREATE_NEW_REVISION = 1,
  UPDATE_CURRENT_REVISION = 2
}

export enum FormTypes {
  REQUEST = 1,
  ELIGIBILITY = 2,
  LOI = 3,
  REVIEW = 4,
  AWARD = 5,
  APPROVE_DECISION = 6,
  DECLINE_DECISION = 7,
  PAYMENT = 8,
  PROGRESS_REPORT = 9,
  GRANT_AGREEMENT = 10,
  SITE_VISIT = 11,
  MEETING_NOTES = 12,
  ADDITIONAL_DOCUMENTATION = 13,
  APPROVAL = 14,
  OTHER_APPLICANT_FORM = 15,
  OTHER_GRANT_MANAGER_FORM = 16,
  NOMINATION = 17,
  ROUTING = 18
}

export enum FormStates {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  PRODUCT_TEMPLATE = 'template'
}

export enum ResponseVisibilityOptions {
  VIEW_NONE = 1,
  VIEW_AT_THIS_WORKFLOW = 2,
  VIEW_AT_ALL_WORKFLOW = 3
}

export enum CompletionRequirementType {
  NONE = 1,
  ALL_USERS = 2,
  MAJORITY = 3,
  SPECIFIC_COUNT = 4,
  VIEW_ONLY = 5
}

export enum AvailabilityOptions {
  AUTO = 1,
  MANUAL = 2,
  DATE = 3,
  DYNAMIC_DATE = 4
}

export enum DateOption {
  APPLICATION_APPROVAL_DATE = 1,
  AWARD_DATE = 2,
  APPLICATION_SUBMITTED_DATE = 3,
  WORKFLOW_LEVEL_ENTRY_DATE = 4
}

export enum DueDateOptions {
  NONE = 1,
  SPECIFIC = 2,
  CUSTOM = 3
}

export enum RelativeFormDueDateFields {
  SUBMITTED_DATE = 1,
  ENTERED_WFL_DATE = 2,
  FORM_SENT_DATE = 3,
  CYCLE_OPEN_DATE = 4,
  CYCLE_CLOSE_DATE = 5,
  APPROVAL_DATE = 6,
  AWARD_DATE = 7,
  LAST_PAYMENT_DATE = 8,
  STATUS_CHANGE_DATE = 9
}

export interface FormFromApi {
  title: string;
  name: string;
  path: string;
  id: number;
  description: string;
  createdDate: string;
  updatedDate: string;
   // older definitions stored as single object,
   // but switching to tabs will make this an array.
   // will need adapting on the way in.
  formDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[];
  formType: FormTypes;
  version: number;
  formName?: string;
  formData?: FormData;
  canModify: boolean;
  availableForTranslation: boolean;
  defaultLanguageId: string;
  externalApiRequestIds: number[];
  referenceFieldIds: number[];
  requireSignature: boolean;
  signatureDescription: string;
}

export interface BaseForm {
  title: string;
  name: string;
  path: string;
  id: number;
  description: string;
  createdDate: string;
  updatedDate: string;
  formType: FormTypes;
  version: number;
  formName?: string;
  formData?: FormData;
  canModify: boolean;
  availableForTranslation: boolean;
  defaultLanguageId: string;
  externalApiRequestIds: number[];
  referenceFieldIds: number[];
  requireSignature: boolean;
  signatureDescription: string;
}

export interface Form extends BaseForm {
  formDefinition: FormDefinitionForUi[];
  customJavascriptComps?: string[];
}

export interface FormType {
  id: FormTypes;
  name: string;
  audience: FormAudience;
}

export interface ProgramFormBase {
  applicationFormId: number;
  formId: number;
  formRevisionId: number;
  name: string;
  description: string;
  formData: FormData;
  isDraft: boolean;
  canEdit: boolean;
  revisionNotes: string;
  formType: FormTypes;
  applicationFormStatusId: number;
  defaultLanguageId: string;
  requireSignature: boolean;
  signatureDescription: string;
}

export interface ProgramFormFromApi extends ProgramFormBase {
  formDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[];
}

export interface ProgramFormForUi extends ProgramFormBase {
  dueDate: string;
  isOverdue: boolean;
  formDefinition: FormDefinitionForUi[];
  customJavascriptComps: string[];
}

export interface BaseFormForMigration {
  formDefinition: FormDefinitionWithLogic | FormDefinitionWithLogic[];
}

export type AdaptedForm<T extends BaseFormForMigration> = T & {
  formDefinition: FormDefinitionForUi[];
  customJavascriptComps: string[];
};

export interface FormForApplicantFromApi {
  formId: number;
  formRevisionId: number;
  formData: FormData;
  isDraft: boolean;
  applicationFormId: number;
  revisionNotes: string;
  formName: string;
  submittedDate: string;
  createdBy: string;
  updatedBy: string;
  submittedBy: string;
  statusUpdatedDate: string;
  updatedDate: string;
  createdDate: string;
  applicationFormStatusId: FormStatuses;
  dueDate: string;
}

export interface FormForApplicantForUI extends FormForApplicantFromApi {
  isOverdue: boolean;
}

export interface ApplicationViewFormBase {
  audience: FormAudience;
  formId: number;
  formRevisionId: number;
  grantManagerActionType: ResponseVisibilityOptions;
  name: string;
  formType: FormTypes;
  comments: FormComment[];
  actionType: ResponseVisibilityOptions;
  completionRequirementType: CompletionRequirementType;
  specificNumberForCompletion?: number;
  managersCount: number;
  portalAvailabilityDetails: PortalFormAvailabilityInfo;
  dueDate: string;
  sortOrder: number;
  requireSignature: boolean;
  signatureDescription: string;
}

export interface ApplicationViewFormFromApi extends ApplicationViewFormBase {
  responses: FormResponseFromApi[];
  otherLevelResponses: FormResponseFromApi[];
  nominationResponses: FormResponseFromApi[];
}

export interface ApplicationViewFormForUI extends ApplicationViewFormBase {
  isOverdue: boolean;
  responses: FormResponse[];
  otherLevelResponses: FormResponse[];
  nominationResponses: FormResponse[];
}

export interface ApplicationViewFormForSubmissionMap {
  audience: FormAudience;
  formId: number;
  formRevisionId: number;
  grantManagerActionType: ResponseVisibilityOptions;
  responses: FormResponse[];
  otherLevelResponses: FormResponse[];
  nominationResponses: FormResponse[];
  name: string;
  formType: FormTypes;
  comments: FormComment[];
  actionType: ResponseVisibilityOptions;
  completionRequirementType: CompletionRequirementType;
  specificNumberForCompletion?: number;
  managersCount: number;
  portalAvailabilityDetails: PortalFormAvailabilityInfo;
  dueDate?: string;
  isOverdue?: boolean;
}

export interface FormComment {
  commentor: UserInfo;
  commentDate: string;
  comment: string;
}

export interface MyWorkspaceForm {
  formId: number;
  audience: FormAudience;
  grantManagerActionType: ResponseVisibilityOptions;
  name: string;
  formType: FormTypes;
  formRevisionId: number;
  comments: FormComment[];
  actionType: ResponseVisibilityOptions;
  portalAvailability: AvailabilityOptions;
  completionRequirementType: CompletionRequirementType;
  numberOfResponses: number;
  userHasSubmittedResponse: boolean;
  managersCount: number;
  isOnNominationProgram: boolean;
}

export interface PortalAvailabilityDetails {
  dateOption?: DateOption;
  dateOffset?: number;
  date?: string;
}


export interface FormDueDate {
  relativeDateField: RelativeFormDueDateFields;
  dateOffset: number;
  customDueDate: string;
}

export interface WorkflowLevelFormApi {
  id: number;
  formId: number;
  workflowLevelId: number;
  completionRequirementType?: CompletionRequirementType;
  specificNumberForCompletion: number;
  managerActionType?: ResponseVisibilityOptions;
  portalAvailability?: AvailabilityOptions;
  portalAvailabilityDetails?: PortalAvailabilityDetails;
  notifyApplicantOfFormAvailability?: boolean;
  dueDateDetails?: FormDueDate;
  grantProgramId?: number;
  clientEmailTemplateId: number;
  sortOrder: number;
  // helpers
  isDefaultForm?: boolean;
  formType?: number;
}

export interface WorkflowLevelFormForBulkDownload extends WorkflowLevelFormApi {
  formName: string;
  selected: boolean;
}

export interface SubmissionMap {
  parentWorkflowLevelName?: string;
  workflowLevelName: string;
  workflowLevelId: number;
  forms: WorkflowLevelSubmissionForm[];
}

export interface WorkflowLevelSubmissionForm {
  formId: number;
  formName: string;
  formAudience: FormAudience;
  submittedOn: string;
  submissions: FormResponse[];
  workflowLevelName?: string;
  parentWorkflowLevelName?: string;
  selected: boolean;
}

export interface BulkDownloadGroup {
  workflowLevelId: number;
  workflowLevelName: string;
  parentWorkflowLevelName: string;
  isDefaultFormGroup: boolean;
  forms: WorkflowLevelFormForBulkDownload[];
}

export interface WorkflowLevelFormChange {
  updatedForm: WorkflowLevelFormApi;
  row: WorkflowLevel;
  index: number;
  form: WorkflowLevelFormApi;
}

export interface FormData {
  [x: string]: any;
}

export interface BasicRevision {
  formRevisionId: number;
  version: number;
  createdDate: string;
  updatedDate: string;
  formId: number;
  revisionCanBeRemoved: boolean;
}

export interface BasicForm {
  revisions: BasicRevision[];
  name: string;
  description: string;
  createdDate: string;
  updatedDate: string;
  revisionId: number;
  revisionVersion: number;
  formId: number;
  formType: FormTypes;
  canBeRemoved: boolean;
  canModify: boolean;
  availableForTranslation: boolean;
  defaultLanguageId: string;
  isDraft: boolean;
  programIds: number[];
  standardComponentIsPublished: boolean;
}

export interface BasicFormForUi extends BasicForm {
  state: FormStates;
  isStandardFormTemplate: boolean;
  showRemoveForm: boolean;
  showRemoveRevision: boolean;
  canPublishFromRootZone: boolean;
  formAndRevisionId: string;
}

export interface MyApplicationForm {
  formId: number;
  id: number; // applicationFormId
  formRevisionId: number;
  name: string;
  isDraft: boolean;
  submissionDate: string;
  status: FormStatuses;
  statusUpdatedDate: string;
  updatedDate: string;
  dueDate: string;
}

export interface MyApplicationFormUI extends MyApplicationForm {
  applicationId: number;
  grantProgramName: string;
  grantProgramId: number;
}

export interface Revision {
  formRevisionId: number;
  version: number;
  createdDate: string;
  updatedDate: string;
  formId: number;
}

export interface SaveForm {
  revisionId?: number;
  id?: number;
  name: string;
  description?: string;
  formType: FormTypes;
  formDefinition: FormDefinitionForUi[];
  formSchema: any;
  availableForTranslation: boolean;
  defaultLanguageId: string;
  picklistGuids: string[];
  referenceFieldIds: FormRevisionRefFields[];
  externalApiRequestIds: number[];
  requireSignature: boolean;
  signatureDescription: string;
  nominationReferenceFieldIds: number[];
  employeeApplicantInfoKeys: string[];
  nominatorInfoKeys: string[];
}

export interface FormRevisionRefFields {
  referenceFieldId: number;
  pullFromBBGM: boolean;
}

export interface Forms {
  draft: BasicForm[];
  published: BasicForm[];
}

export interface SimpleDataHubForm {
  id: number;
  name: string;
  formType: FormTypes;
  revisions: SimpleRevision[];
}

export interface SimpleRevision {
  id: number;
  name: string;
}

export interface SaveFormResponse extends StandardGmRequiredFields {
  formId: number;
  formRevisionId: number;
  isDraft: boolean;
  applicationFormId: number;
  revisionNotes: string;
  amountRequested?: number;
  currencyRequested: string;
  saveAmountRequestedInDefaultCurrency: boolean;
  paymentDesignation?: string;
  specialHandlingName?: string;
  specialHandlingAddress1?: string;
  specialHandlingAddress2?: string;
  specialHandlingCountry?: string;
  specialHandlingCity?: string;
  specialHandlingStateProvinceRegion?: string;
  specialHandlingPostalCode?: string;
  specialHandlingNotes?: string;
  specialHandlingReason: string;
  specialHandlingFileUrl: string;
  decision?: FormDecisionTypes;
  reviewerRecommendedFundingAmount: number;
  inKindItems: InKindRequestedItem[];
  nominee?: Nominee;
  careOf: string;
  requiredReferenceFieldKeys: string[];
  editingApplicationView: boolean;
  submittingApplication: boolean;
  workflowLevelId: number; // only necessary when submittingApplication is true
  userSignatureId: number;
  userSignatureBypassed: boolean;
}

export interface AllFormsResponse {
  formDetails: ApplicationViewFormFromApi[];
  applicationReferenceFieldResponses: ReferenceFieldAPI.ApplicationRefFieldResponse[];
}

export interface FormResponseBase {
  applicationFormId: number;
  isDraft: boolean;
  createdBy?: UserInfo;
  updatedBy?: UserInfo;
  createdDate?: string;
  updatedDate?: string;
  revisionNotes: string;
  formComments: string;
  submittedDate: string;
  applicationFormStatusId: FormStatuses;
  formData: FormData;
  formRevisionId: number;
  workflowLevelName: string;
  revisionLastSentDate: string;
  decision: FormDecisionTypes;
  reviewerRecommendedFundingAmount: number;
  dueDate: string;
  isOverdue?: boolean;
  workflowLevelId?: number;
  submittedBy?: UserInfo;
  submittedByName?: string;
}

export interface FormResponseFromApi extends FormResponseBase {
  formDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[];
}

export interface FormResponse extends FormResponseBase {
  formDefinition: FormDefinitionForUi[];
}

export interface FormRequirements {
  countRequired: number;
  countOtherWorkflows: number;
  countComplete: number;
  metRequirement: boolean;
}

export interface PostFormComment {
  formRevisionId: number;
  notes: string;
}

export interface SelectedFormForPDF {
  isNomination?: boolean;
  nominee?: Nominee;
  formName: string;
  formDefinition: FormDefinition;
  formData: FormData;
}

export interface FormTranslation {
  formIds: number[];
  defaultTranslationText: string;
  translations: Translation[];
  defaultLanguage: string;
  languageKeyId: number;
}

export interface ProgramFormModalFormState {
  formId: number;
  managerActionType: ResponseVisibilityOptions;
  completionRequirementType: CompletionRequirementType;
  portalAvailability: AvailabilityOptions;
  date: string;
  dateOption: DateOption;
  dateOffset: number;
  specificNumberForCompletion: number;
  dueDateOption: DueDateOptions;
  customDueDate: string;
  relativeDateField: RelativeFormDueDateFields;
  dueDateOffset: number;
  clientEmailTemplateId: number;
}

export interface FormReminderForApi {
  clientEmailTemplateId: number;
  comments: string;
  applicationId: number;
  formId: number;
  emailOptionsModel: EmailOptionsModelForSave;
}

export interface ExportForm {
  formId: number;
  revisionId: number;
}

export interface FlatFieldValidationResponse {
  isValid: boolean;
  invalidFieldNames?: string[];
}

export enum ExportFormResult {
  Passed = 1,
  Failed = 2,
  HasCustomJs = 3
}

export interface ExportFormResponse {
  id: number;
  name: string;
  description: string;
  formType: FormTypes;
  formLanguageKeys: {
    formId: number;
    languageKeyId: number;
    defaultLanguageTranslationId: number;
    isRichText: boolean;
  }[];
  nameLanguageTranslations: {
    id: number;
    languageKeyName: string;
    isRichText: boolean;
  }[];
  descriptionLanguageTranslations: {
    id: number;
    languageKeyName: string;
    isRichText: boolean;
  }[];
  defaultLanguageId: string;
  formRevisionId: number;
  formRevisionFormDefinition: FormDefinitionWithLogic|FormDefinitionWithLogic[];
  formRevisionFormSchema: any;
  formRevisionVersion: number;
  availableForTranslation: boolean;
  picklists: {
    name: string;
    picklistGuid: string;
    defaultLanguageId: string;
    formRevisionIds: number[];
    picklistOptions: {
      key: string;
      inUse: boolean;
      valueLanguageKey: {
        id: number;
        languageKeyName: string;
        isRichText: boolean;
      };
    }[];
  }[];
  referenceFields: {
    id: number;
    name: string;
    description: string;
    type: string;
    key: string;
    formRevisionIds: number[];
    customDataTableGuid: string;
    supportsMultiple: boolean;
    formAudience: FormAudience;
    category: {
      id: number;
      name: string;
    };
  }[];
  languageTranslations: {
    id: number;
    languageId: string;
    translationText: string;
    languageKeyName: string;
    languageKeyId: number;
    languageKeyDefaultLanguage: string;
    isRichText: boolean;
  }[];
}

export enum ImportFileType {
  FORM = 1,
  REFERENCE_FIELD = 2
}

export interface FormManagerRecordFromApi {
  formName: string;
  formId: number;
  formVersion: number;
  formRevisionId: number;
  formAudience: FormAudience;
  rawFormData: string;
  rawFormDefinition: string;
  revisionLastSentDate: string;
  decision: FormDecisionTypes;
  applicationFormId: number;
  isDraft: boolean;
  grantManagerFormActionType: ResponseVisibilityOptions;
  completionRequirementType: CompletionRequirementType;
  specificNumberForCompletion: number;
  managersCount: number;
  portalAvailabilityDetails: PortalFormAvailabilityInfo;
  dueDate: string;
  isOverdue: boolean;
  submittedDate: string;
  createdBy: UserInfo;
  lastUpdatedBy: UserInfo;
  updatedDate: string;
  applicantId: number;
  applicantFullName: string;
  applicantEmail: string;
  applicantAddress: SimpleGrantsAddress;
  applicantPhoneNumber: string;
  isMasked: boolean;
  canViewMaskedApplicantInfo: boolean;
  organizationName: string;
  organizationId: number;
  organizationAddress: SimpleGrantsAddress;
  organizationIdentification: string;
  isPrivateOrg: boolean;
  organizationImageUrl: string;
  nonprofitGuid: string;
  applicationId: number;
  workflowId: number;
  workflowLevelId: number;
  workflowName: string;
  workflowLevelName: string;
  applicationStatus: ApplicationStatuses;
  grantProgramId: number;
  grantProgramName: string;
  grantProgramType: ProgramTypes;
  grantProgramCycleId: number;
  grantProgramCycleName: string;
  isApplicationArchived: boolean;
  applicationFormStatusId: FormStatuses;
  isApplicationInClientUserWorkflowLevel: boolean;
  notSubmitted: boolean;
  managerFullName: string;
  managerEmail: string;
  isDue: boolean;
  userId: number;
}

export interface FormManagerRecordForUi extends FormManagerRecordFromApi {
  hasSendReminderPermission?: boolean;
  applicantAddressString?: string;
  orgAddressString?: string;
  statusString?: string;
  notStarted?: boolean;
  canExtendDueDate?: boolean;
  canSendFormDueEmail?: boolean;
  canSendFormOverdueEmail?: boolean;
  canSendReminder?: boolean;
  canRequestRevision?: boolean;
  canSendRevisionReminder: boolean;
  applicationStatusMap: Status;
  formStatusMap: Status;
}

export interface ViewFormResponse {
  applicationFormId: number;
  formStatus: FormStatuses;
  formId: number;
  formName: string;
  formSubmittedOn: string;
  formSubmittedBy?: string;
  formDefinition: FormDefinitionForUi[];
  formData: FormData;
  formTypeId: FormTypes;
  formRevisionId: number;
  formComments: ApplicantFormComment[];
  decision: FormDecisionTypes;
  reviewerRecommendedFundingAmount: number;
}

export interface FormDueEmailModalResponse {
  clientEmailTemplateId: number;
  applicationFormId: number;
  applicationId: number;
  formId: number;
  workflowLevelId: number;
  userId: number;
  emailOptionsModel: EmailOptionsModelForSave;
}


export interface SaveNewFormResponse {
  id: number;
  name: string;
  description: string;
  active: boolean;
  createdDate: string;
  updatedDate: string;
  formRevision: {
    id: number;
    formId: number;
    formDefinition: FormDefinition;
    formSchema: any;
    version: number;
    active: boolean;
  };
  clientId: number;
  formType: FormType;
  canModify: boolean;
  availableForTranslation: boolean;
  defaultLanguageId: string;
  requireSignature: boolean;
  signatureDescription: string;
}

export interface FormComponentWithRefFieldData extends FormDefinitionComponent {
  referenceField: ReferenceFieldAPI.ReferenceFieldPdfData;
  inKindItems: TypeaheadSelectOption[];
  visibleColumns: ReferenceFieldsUI.TableFieldForUi[];
  hideLabel: boolean;
}

export interface FormComponentsByTab {
  tabName: string;
  components: FormComponentWithRefFieldData[];
}

export interface FormChanges {
  key: string;
  type: string;
  isReferenceField: boolean;
  value: FormAnswerValues;
}

export interface FormChangesWithCompKey extends FormChanges {
  componentKey: string;
  updateFormGroup: boolean;
}

export interface FormValueChange {
  value: FormAnswerValues;
  updateFormGroup: boolean;
}

export interface FormChangeTracker {
  appNeedsUpdated: boolean;
  refChangeTracker: ReferenceFieldsUI.RefResponseMap;
}

export interface FormWithFieldsDetail {
  formId: number;
  referenceFieldIds: number[];
}

export type ValueLogicResult<T> = FormAnswerValues|LogicColumn<T>;

export type LogicGroupForForm = readonly [
  LogicColumn<BaseApplicationForLogic>,
  {
    validityGroup: GlobalLogicGroup<BaseApplicationForLogic>;
    visibilityGroup: GlobalLogicGroup<BaseApplicationForLogic>;
    conditionalValueGroups: GlobalValueLogicGroup<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>[];
  }
];

export interface BaseApplicationForLogic {
  tabs: null[];
  layoutComponents: Record<string, null>;
  referenceFields: Record<string, any>;
  application: BaseApplication;
  employeeSso: EmployeeSSOFieldsData;
  reportFieldResponse: AdHocReportingUI.ReportFieldResponseRow;
}

export interface FormTab extends Tab<FormDefinitionForUi> {
  valid: boolean;
  logic: GlobalLogicGroup<BaseApplicationForLogic>;
  touched: boolean;
  showErrorSummary: boolean;
}

export enum BuilderConfigGroupType {
  Layout = 1,
  Capture = 2,
  Display = 3,
  OnForm = 4
}

export type BuilderConfig = Record<BuilderConfigGroupType, string>;

export interface FormDefinitionComponentForExport {
  'Form name': string;
  'Component label': string;
  'Field name': string;
  'Component description': string;
  'Field category': string;
  'Component key': string;
  'Field key': string;
  'Field type': string;
  'Field ID': number;
}

export type ConditionalValueResult = FormAnswerValues|LogicColumn<BaseApplicationForLogic>;

export interface FormDefinitionComponent {
  hideLabel?: boolean;
  decimalLimit?: number;
  requireDecimal?: boolean;
  label?: string;
  isHidden?: boolean;
  hiddenFromParent?: boolean;
  key: string;
  hidden?: boolean;
  value?: FormAnswerValues;
  currencyDataKey?: string;
  placeholder?: string;
  description?: string;
  autoAdjust?: boolean;
  collapsible?: boolean;
  collapsed?: boolean;
  rowsPerPage?: number;
  width?: number;
  offset?: number;
  push?: number;
  pull?: number;
  defaultValue?: string;
  clearOnHide?: boolean;
  inputMask?: any;
  allowCalculateOverride?: boolean;
  validate?: {
    required: boolean;
    customMessage?: string;
    custom?: string;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
    minWords?: number;
    maxWords?: number;
    pattern?: string;
    validationResult?: string;
  };
  errorLabel?: string;
  tooltipText?: string;
  tooltip?: string;
  title?: string;
  legend?: string;
  components?: FormDefinitionComponent[]; // Panels, Wells, Fieldsets
  rows?: FormDefinitionComponent[][];
  columns?: FormDefinitionComponent[]; // Columns
  type?: string;
  pullFromBBGM?: boolean;
  conditional?: {
    show: string|boolean;
    when: string;
    json?: string;
    eq: string;
  };
  // stores the old conditional logic in case we need to access it
  oldConditonal?: {
    show: string|boolean;
    when: string;
    json?: string;
    eq: string;
  };
  disabled?: boolean;
  selectedCustomDataTable?: string;
  specialHandlingInstructions?: string;
  customCurrency?: string;
  useCustomCurrency?: CurrencyRadioOptions;
  html?: string;
  content?: string;
  referenceFieldId?: number;
  formAudience?: FormAudience;
  validationErrorMessage?: string;
  apiConfig?: ExternalAPISelection;
  reportFieldDataOptions?: ReportFieldDataOptions;
  relatedComponent?: string;
  dataUpdates?: number;
  truthyValue?: string;
  falsyValue?: string;
  recuseValue?: string;
  calculateValue?: string;
  items?: string[];
  allowMultiple?: boolean;
  inKindItemsForPdf?: {
    label: string;
    value: any;
  }[];
  customConditional?: string;
  conditionalLogic?: GlobalLogicGroup<BaseApplicationForLogic>;
  customValidation?: GlobalValueLogicGroup<BaseApplicationForLogic, string>;
  conditionalValue?: GlobalValueLogicGroup<BaseApplicationForLogic, ConditionalValueResult>[];
  formula?: RootFormula<BaseApplicationForLogic>;
  visibleToApplicants?: boolean;
  visibleToManagers?: boolean;
  required?: boolean;
  validationTotal?: number;
  allOptionsMustHaveResponse?: boolean;
  itemsShownBeforeScroll?: number;
  inline?: boolean;
  input?: boolean;
  dataSrc?: string;
  defaultVal?: string;
  appliedDefaultVal?: boolean;
  prefix?: string;
  suffix?: string;
  showWordCount?: boolean;
  showCharCount?: boolean;
  tabIndex?: number;
  hideWithoutParentVal?: boolean;
  allowRecused?: boolean;
  requireFileUpload?: boolean;
  requireReason?: boolean;
  displayInKindValues?: boolean;
  maxItems?: number;
  validationType?: ValidationTypes;
  willBeValid?: IsValidTypes;
  validationAmount?: number;
  validationItem?: string;
  showCategory?: boolean;
  displayType?: AdHocReportingUI.DisplayTypes;
  hiddenCdtKeys?: string[];
  maxFileSizeInMb?: number;
  acceptedFileTypes?: string[];

  // Table Column Configs
  hiddenTableColumnIds?: number[]; // table columns IDs to hide (deprecate for preferred hiddenTableColumnKeys model)
  hiddenTableColumnKeys?: string[]; 
  labelOverrideMap?: Record<string, string>; // table column label overrides (key -> label)
  requiredOverrideKeys?: string[]; // table column required setting overrides
}

export interface FormDefinition {
  tabName?: string;
  components: FormDefinitionComponent[];
  uniqueId?: string;
}

export interface FormDefinitionWithLogic extends FormDefinition {
  logic: GlobalLogicGroup<BaseApplicationForLogic>;
  components: FormDefinitionComponent[];
  tabName: string;
}

export interface FormDefinitionForUi extends FormDefinitionWithLogic {
  index: number;
}

export interface SaveFormResponseObj {
  success: boolean;
  id: number;
  hasPendingFormError: boolean;
}

export interface StandardGmRequiredFields {
  reviewerRecommendedFundingAmountRequired: boolean;
  decisionRequired: boolean;
  amountRequestedRequired: boolean;
  careOfRequired: boolean;
  paymentDesignationRequired: boolean;
}

export interface StandardApplicantRequiredFields {
  amountRequestedRequired: boolean;
  careOfRequired: boolean;
  paymentDesignationRequired: boolean;
}

export type FormComponentType = StandardComponentType|
  'referenceField'|
  'reportField'|
  'employeeSSO'|
  'content'|
  'columns'|
  'fieldset'|
  'panel'|
  'table'|
  'well';

export type StandardComponentType = 'amountRequested'|
  'careOf'|
  'decision'|
  'designation'|
  'inKindItems'|
  'specialHandling'|
  'reviewerRecommendedFundingAmount';

export type FormAnswerValues = ReferenceFieldsUI.RefResponseType
  |InKindRequestedItem[]
  |SpecialHandling;

export interface ComponentWithAnswer extends FormDefinitionComponent {
  answer: FormAnswerValues;
}
export interface ReportFieldDataOptions {
  reportFieldObject: string;
  reportFieldDisplay: string;
  nominationFormId: number;
}

export interface FormTranslations {
  translations: SimpleStringMap<string>;
  richTextTranslations: SimpleStringMap<string>;
}

export interface ApplicationFormSignature {
  userSignatureUserId: number;
  applicationFormId: number;
  userSignatureBypassed: boolean;
  userSignatureId: number;
  signedDate: string;
  userSignatureUrl: string;
  signedByName: string;
  signedByEmail: string;
  ipAddress: string;
  signedInWorkflowLevelId: string;
  signedInWorkflowLevelName: string;
}

export interface UserSignatureForBulk extends ApplicationFormSignature {
  formId: number;
  applicationId: number;
}



export type ComponentTabIndexMap = Record<string, {
  tabIndex: number;
  refKey: string;
}>;

export interface AdaptedFormDefResponse {
  formDefinition: FormDefinitionForUi[];
  customJavascriptComps: string[];
}

export interface FormResponseInfo {
  formDefinition: FormDefinitionForUi[];
  applicationFormId: number;
  form: WorkflowLevelSubmissionForm;
  response: FormResponse;
}

export enum FormFieldPasteLocation {
  ABOVE = 0,
  BELOW = 1,
  INSIDE_CONTAINER = 2
}

export const FormFieldChangeIndicatorIcon = 'sync';
export const FormFieldChangeIndicatorClass = 'text-success';

export interface FormLogicStates {
  conditionalVisibilityState: LogicState<BaseApplicationForLogic, boolean>;
  validityState: LogicState<BaseApplicationForLogic, boolean>;
  setValueState: ListLogicState<BaseApplicationForLogic, ValueLogicResult<BaseApplicationForLogic>>;
  formulaState: FormulaState<BaseApplicationForLogic>;
}


export interface FormResponseForPdf {
  applicationFormId: number;
  applicationFormStatusId: FormStatuses;
  formRevisionId: number;
  formDefinition: FormDefinitionForUi[];
  formData: FormData;
  submittedBy?: UserInfo;
  createdBy?: UserInfo;
  submittedDate: string;
  decision: FormDecisionTypes;
  reviewerRecommendedFundingAmount: number;
}

export interface FormFlyoutDetails {
  form: BasicFormForUi;
  programNames: string[];
  formDetail: Form;
}

export interface ComponentOnForm {
  name: string;
  label: string;
  typeName: string;
  audienceName: string;
  key: string;
  categoryName: string;
}

export type FormComponentGroup = UntypedFormGroup;

export interface FieldNeedingUpdates {
  referenceFieldId: number;
  oldFieldKey: string;
  updatedFieldKey: string;
  oldType: string;
  newType: string;
  component: FormDefinitionComponent;
  tabIndex: number;
}
