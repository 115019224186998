<!-- Edit Component -->
<yc-button 
  [icon]="true"
  [fontAwesomeIcon]="'pencil'"
  [tooltipText]="'CONFIG:btnEditComponent' | ycI18n: 'Edit component'"
  (onClick)="actionClick.emit(FormBuilderActions.Edit_Component)">
</yc-button>

<!-- Edit Form Field -->
<yc-button *ngIf="isTypeFormField && allowEditFormField"
  [icon]="true"
  [fontAwesomeIcon]="'wrench'"
  [tooltipText]="'FORMS:textEditFormField' | ycI18n: 'Edit form field'"
  (onClick)="actionClick.emit(FormBuilderActions.Edit_Field)">
</yc-button>

<!-- Cut -->
<yc-button *ngIf="!clipboardFull"
  [icon]="true"
  [fontAwesomeIcon]="'cut'"
  [tooltipText]="'CONFIG:btnCutComponent' | ycI18n: 'Cut component'"
  (onClick)="actionClick.emit(FormBuilderActions.Cut)">
</yc-button>

<!-- Copy -->
<yc-button *ngIf="allowCopy && !clipboardFull && !isInvalidType"
  [icon]="true"
  [fontAwesomeIcon]="'copy'"
  [tooltipText]="'CONFIG:btnCopyComponent' | ycI18n: 'Copy component'"
  (onClick)="actionClick.emit(FormBuilderActions.Copy)">
</yc-button>

<!-- Paste Above-->
<yc-button *ngIf="clipboardFull"
  [icon]="true"
  [fontAwesomeIcon]="'arrow-from-bottom'"
  [tooltipText]="'CONFIG:btnPasteAbove' | ycI18n: 'Paste above'"
  [value]="'CONFIG:btnPasteAboveComponentKey' | ycI18n: 'Paste above component __key__': { key: componentKey }"
  (onClick)="actionClick.emit(FormBuilderActions.Paste_Above)">
</yc-button>

<!-- Paste Below -->
<yc-button *ngIf="clipboardFull"
  [icon]="true"
  [fontAwesomeIcon]="'arrow-from-top'"
  [tooltipText]="'CONFIG:btnPasteBelow' | ycI18n: 'Paste below'"
  (onClick)="actionClick.emit(FormBuilderActions.Paste_Below)">
</yc-button>

<!-- Paste Into Container -->
<yc-button *ngIf="clipboardFull && allowPasteInto"
  [icon]="true"
  [fontAwesomeIcon]="'paste'"
  [tooltipText]="'CONFIG:btnPasteIntoContainer' | ycI18n: 'Paste into container'"
  (onClick)="actionClick.emit(FormBuilderActions.Paste_Into_Container)">
</yc-button>

<!-- Remove -->
<yc-button 
  [icon]="true"
  [fontAwesomeIcon]="'trash'"
  [tooltipText]="'CONFIG:btnDeleteComponent' | ycI18n: 'Delete component'"
  (onClick)="actionClick.emit(FormBuilderActions.Remove)">
</yc-button>
